import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '../core/core.module';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { LabourImportHrComponent } from './labour-import-hr/labour-import-hr.component';
import { AgInputEditCellRendererComponent } from './ag-input-edit-cell-renderer/ag-input-edit-cell-renderer.component';
import { AgSelectEditCellRendererComponent } from './ag-select-edit-cell-renderer/ag-select-edit-cell-renderer.component';
import { AgDatePickerEditCellRendererComponent } from './ag-datepicker-edit-cell-renderer/ag-datepicker-edit-cell-renderer.component';
import { AgIconActionCellRendererComponent } from './ag-icon-action-cell-renderer/ag-icon-action-cell-renderer.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ImportExcelRoutingModule } from './import-excel-routing.module';
import { ImportExcelService } from './import-excel.service';
import { ImportExcelComponent } from './import-excel.component';
import { ImportManualPunchComponent } from './import-manual-punch/import-manual-punch.component';
import { AgTimePickerEditCellRendererComponent } from './ag-timepicker-edit-cell-renderer/ag-timepicker-edit-cell-renderer.component';
import { SubcontractorLabourImportHrComponent } from './subcont-labour-import-hr/subcont-labour-import-hr.component';
import { VerifyAdharCardCellRendererComponent } from './verify-adhar-card-cell-renderer/verify-adhar-card-cell-renderer.component';
import { VerifyULCDetailComponent } from './subcont-labour-import-hr/verify-ulc-detail/verify-ulc-detail.component';
import { UploadBulkEnrollmentDeleteComponent } from './upload-bulk-enrollment-delete/upload-bulk-enrollment-delete.component';
import { UploadExcelComponent } from './upload-excel/upload-excel.component';
import { CommonImportExcelComponent } from './shared/common-import-excel/common-import-excel.component';
import { PayrollHandUploadComponent } from './payroll-excel-upload/payroll-excel-upload.component';
import { PayrollExcelFileSampleComponent } from './payroll-excel-upload/payroll-excel-file-sample/payroll-excel-file-sample.component';

@NgModule({
  declarations: [
    ImportExcelComponent,
    ImportManualPunchComponent,
    LabourImportHrComponent,
    SubcontractorLabourImportHrComponent,
    AgInputEditCellRendererComponent,
    AgSelectEditCellRendererComponent,
    AgDatePickerEditCellRendererComponent,
    AgIconActionCellRendererComponent,
    AgTimePickerEditCellRendererComponent,
    VerifyAdharCardCellRendererComponent,
    VerifyULCDetailComponent,
    UploadBulkEnrollmentDeleteComponent,
    UploadExcelComponent,
    PayrollHandUploadComponent,
    CommonImportExcelComponent,
    PayrollExcelFileSampleComponent
  ],
  entryComponents: [
    LabourImportHrComponent,
    SubcontractorLabourImportHrComponent,
    ImportManualPunchComponent,
    AgInputEditCellRendererComponent,
    AgSelectEditCellRendererComponent,
    AgDatePickerEditCellRendererComponent,
    AgIconActionCellRendererComponent,
    AgTimePickerEditCellRendererComponent,
    VerifyAdharCardCellRendererComponent,
    VerifyULCDetailComponent,
    UploadBulkEnrollmentDeleteComponent,
    CommonImportExcelComponent,
    PayrollHandUploadComponent,
    PayrollExcelFileSampleComponent
  ],
  imports: [
    CommonModule,
    ImportExcelRoutingModule,
    CoreModule,
    PopoverModule,
    SweetAlert2Module.forRoot()
  ],
  providers: [
    ImportExcelService
  ]
})
export class ImportExcelModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ImportExcelModule,
      providers: [
        ImportExcelService
      ]
    };
  }
}
