import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GLOBAL } from '../app.globals';

@Injectable({
  providedIn: 'root'
})
export class YearlyBudgetService {

  constructor(private http: HttpClient) { }

  public getCompanies(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_COMPANY_DATA}?Search=&StatusOfIndustry=&ISActive=&SDate=&EDate=&EntityType=&Legalstatus=
    &State=&City=&Country=`;
    return this.http.get<any[]>(url).pipe(map((response: any) => response.Data.Table));
  }

  public getPlants(companyCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_PLANTS}?Search=&Company=${companyCode}&State=&City=&Country=&SDate=&EDate=
    &UnitType=&Ownership=&LocatedAt=&ISESICApplicable=`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => response));
  }

  public getUnits(plantCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_UNITS}?Search=&PlantCode=${plantCode}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }

  public getMasterItemByCode(code: string): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_MASTERITEMLIST}?Search=&ShortCode=${code}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }

  public getPlantBySite(code, Fcode): Observable<any> {
    const model = {
      Site_Code: code,
      Function_Code: Fcode
    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Listing_Plant_Site`;
    return this.http.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public getFunctionData(): Observable<any[]> {
    const model = {
      Search: ''
    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_COM_GetAllFunction`;
    return this.http.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public getYearData(): Observable<any[]> {
    const model = {
      UserId: null,
      PlantCode: null,
      FinancialYearCode: null,
      isActive: null,
    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Get_FinacialYear`;
    return this.http.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public getBudgetListing(): Observable<any[]> {
    const model = {
      UserId:  localStorage.getItem('UserID'),
    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Listing_BudgetData`;
    return this.http.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public saveData(modelData) {
    const url = `${GLOBAL.APIS.YEARLY_BUDGET.SAVE_DATA}`;
    return this.http.post(url, modelData).
      pipe(
        map((response: any) => response));
  }


}
