import { AgActionCellRendererComponent } from "../../../../../core/components/grid-wrapper/helper/ag-action-cell-renderer/ag-action-cell-renderer.component";
import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { GatePassRequestsService } from "../../../gate-pass-requests.service";
import { AssociateEffectiveDateCellRendererComponent } from "../../../ag-helper/associate-resign-date-cell-renderer/associate-resign-date-cell-renderer.component";
import { LogHelperService } from "src/app/core/services/log-helper.service";
import { editButtonRenderer } from "../../../ag-helper/htmlElement/buttonElement.component";
import { inputValueRenderer } from "../../../ag-helper/htmlElement/inputElement.component";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SharedService } from "src/app/core/services";
import { GLOBAL } from "src/app/app.globals";

@Component({
  selector: "app-popup-workman-salary-master",
  templateUrl: "./popup-workman-salary-master.component.html",
  styleUrls: ["./popup-workman-salary-master.component.css"],
  host: {
    class: 'h-100 overflow-hidden d-flex flex-column'
  },
})
export class PopupWorkmanSalaryMasterComponent {
  private pRowData: any;
  @Input() public set rowData(value: any[]) {
    if (value) {
      this.pRowData = value;
      this.setControlValues(value);
    }

  }
  @ViewChild(editButtonRenderer) editButtonRenderer;

  public get rowData(): any[] {
    return this.pRowData;
  }

  public formGroup: FormGroup;

  public get salaryDetails(): FormArray {
    return this.formGroup.get('salaryDetails') as FormArray;
  }
  constructor(
    public sharedService: SharedService,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private _gatePassRequestsService: GatePassRequestsService,
    private logHelperService: LogHelperService
  ) {
    this.formGroup = this.fb.group({
      salaryDetails: this.fb.array([])
    })
  }

  public onCheckboxChange(form: FormGroup): void {
    const isSelected = form.get('isSelected').value;
    if (isSelected) {
      form.get('Amount').enable();
      form.get('EffectiveDate').enable();
      form.get('Amount').setValidators([Validators.required]);
      form.get('EffectiveDate').setValidators([Validators.required]);
    } else {
      form.get('Amount').disable();
      form.get('EffectiveDate').disable();
      form.get('Amount').clearValidators();
      form.get('EffectiveDate').clearValidators();
    }
  }

  public checkValidation(form: FormGroup, controlName: string): boolean {
    if (form.valid || form.get(controlName).valid) return false;
    if (form.get(controlName).dirty && form.get(controlName).touched) return true;
    return false;
  }


  saveAllDataButton() {
    if (!this.formGroup.valid) {
      for (const formGroup of this.salaryDetails.controls) {
        formGroup.get('Amount').markAsDirty();
        formGroup.get('Amount').markAsTouched();
        formGroup.get('Amount').updateValueAndValidity();
        formGroup.get('EffectiveDate').markAsDirty();
        formGroup.get('EffectiveDate').markAsTouched();
        formGroup.get('EffectiveDate').updateValueAndValidity();
      }
      return;
    }
    const controlValues = this.salaryDetails.getRawValue();
    const selectedValues = controlValues.filter(item => item.isSelected);
    if (!selectedValues.length) {
      this.logHelperService.logError({
        message: 'Please select at least one item.'
      });

      return;
    }

    const finalList = controlValues.map(res => ({
      UEC: res.UEC,
      Component_Code: res.Component_Code,
      EffectiveDate: res.EffectiveDate,
      Amount: res.Amount,
      UserId: GLOBAL.USER_ID,
      HandEntryValueId: res.HandEntryValueId || null
    }));

    this._gatePassRequestsService.insertUpdatePayrollHandentry(finalList).subscribe((res: any) => {
      // if (res) {
        this.logHelperService.logSuccess({
          message: 'Data saved successfully.'
        });
        this.activeModal.dismiss();
      // }
    });
  }

  private setControlValues(list: any[]): void {
    const control = this.formGroup.get('salaryDetails') as FormArray;
    control.controls = [];
    for (const task of list) {
      const formGroup: any = this.buildForm(task);
      control.push(formGroup);
    }
  }

  private buildForm(data: any): FormGroup {
    const isExist = !!data.HandEntryValueId;
    return this.fb.group({
      isSelected: [{ value: !isExist, disabled: !isExist }],
      ShortName: [{ value: data.ShortName, disabled: isExist }, !isExist ? [Validators.required] : undefined],
      Amount: [{ value: data.Amount, disabled: isExist }, !isExist ? [Validators.required] : undefined],
      EffectiveDate: [{ value: data.EffectiveDate, disabled: isExist } || null, !isExist ? [Validators.required] : undefined],
      UEC: [data.GatePassNo],
      Component_Code: [data.Component_Code],
      HandEntryValueId: [data.HandEntryValueId || null]
    })
  }
}
