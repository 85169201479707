import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../../app.globals';
import { Observable } from 'rxjs';
import { ContractorAdapter } from '../contractor-adapter/contractor.adpater';
import { map } from 'rxjs/operators';

declare var $: any;
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class WorkOrdersService {

  constructor(
    private http: HttpClient,
    private httpClient: HttpClient,
    private workOrderAdapter: ContractorAdapter) { }

  /**
   * @description used to get all the work order.
   * @author Sonal Prajapati
   * @param contractorType a number type of the contractor.
   */
  getAllWorkOrders(contractorType: number) {
    // const queryString = $.param({
    //   userid: GLOBAL.USER.LOGGED_IN_USER,
    //   drpfilterwork: contractorType
    // });
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_WORK_ORDERS}?sDate=&eDate=&search=&selectedField=&filterType=&condition=&isActive=
    &workOrderType=&workOrderOwner_Code=&workOrderHOD_Code=&department_Code=&isContractorType=${contractorType}&Plant_Code=&
    NatureOfWork_Code=&Contractor_Code=&SubContractor_Code=&WorkOrder_Code=&SubWorkOrder_Code=&WorkSiteArea_Code=&IsHRCApplicable=
    &Status_Code=&UserId=${localStorage.getItem('UserID')}`;
    return this.http.request(GLOBAL.HTTP_GET, url, httpOptions).pipe(
      map((response: any) => this.workOrderAdapter.convertResponse<any>(response)));
    // return this.http.request(GLOBAL.HTTP_GET, url, httpOptions);
    // return this.http.get(url);
  }
  getPrintprintWorkOrderdata(WorkOrder_Code: number){
    let payload = {
      WorkOrder_Code: WorkOrder_Code,
      RequireType: 0,
      ActionType: 0,
      UserId: localStorage.getItem('UserID'),
    }
    httpOptions['body'] = payload;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.WORK_ORDER.RPT_WorkOrderFORM3}`,
      httpOptions
    );
  }
  // Get Contractor code
  getContractorCode() {
    return this.http.get(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_WORK_ORDER_CODE);
  }
  getECPolicies() {
    return this.http.get(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_EC_POLICY);
  }
  getMasterList() {
    const model = {
      Search: '',
      Plant_Code: '',
      ShiftTypeGroup: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_MASTER_DATA}`, model);
  }

  //    return this.http.get(GLOBAL.APIS.USER_MANAGEMENT.GET_MASTER_DATA);
  // }
  getCountries() {
    const model = {
      Search: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_COUNTRY_LIST}`, model).
      pipe(
        map((response: any) => response));
  }

  // return this.http.get(GLOBAL.APIS.USER_MANAGEMENT.GET_COUNTRY_LIST);
  // }

  // get Complience code
  // getComplienceInfoCode() {
  //   return this.http.get(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_COMPLIENCE_INFO_CODE);
  // }
  getStatesByCountryId(country) {
    const model = {
      Search: '',
      Country_Code: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_STATES_OF_COUNTRY}`, model).
      pipe(
        map((response: any) => response));
  }
  //    const queryString = $.param({
  //    ID: JSON.stringify(country)
  // });
  // return this.http.request(
  // GLOBAL.HTTP_GET,
  // `${GLOBAL.APIS.USER_MANAGEMENT.GET_STATES_OF_COUNTRY}?${queryString}`,
  //  httpOptions
  // );
  // }

  getDistrictsByState(state) {
    const model = {
      Search: '',
      StateCode: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_DISTRICTS_OF_STATE}`, model).
      pipe(
        map((response: any) => response));
  }
  //   const queryString = $.param({
  //     ID: JSON.stringify(state)
  //   });
  //   return this.http.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.USER_MANAGEMENT.GET_DISTRICTS_OF_STATE}?${queryString}`,
  //     httpOptions
  //   );
  // }

  getCitiesByDistrict(district) {
    const model = {
      Search: '',
      District_Code: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_CITY_OF_DISTRICT}`, model).
      pipe(
        map((response: any) => response));

  }
  //   const queryString = $.param({
  //     ID: JSON.stringify(district)
  //   });
  //   return this.http.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.USER_MANAGEMENT.GET_CITY_OF_DISTRICT}?${queryString}`,
  //     httpOptions
  //   );
  // }

  getPOsByCity(city) {
    const model = {
      Search: '',
      City_Code: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_PO_BY_CITY}`, model);
  }
  //   const queryString = $.param({
  //     ID: JSON.stringify(city)
  //   });
  //   return this.http.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.USER_MANAGEMENT.GET_PO_BY_CITY}?${queryString}`,
  //     httpOptions
  //   );
  // }

  // 10-10-2017
  // get district by state
  getDistrict(state) {
    // const response = this.httpClient({
    //   method: 'get',
    //   url: globalapipath + 'UserManagement/GetDistrictDataBYState',
    //   params: {
    //     ID: JSON.stringify(state)
    //   }
    // });
    // return response;
  }

  // 10-10-2017
  // get city by district
  getCity(district) {
    // const response = this.httpClient({
    //   method: 'get',
    //   url: globalapipath + 'UserManagement/GetCityDataBYDistrict',
    //   params: {
    //     ID: JSON.stringify(district)
    //   }
    // });
    // return response;
  }

  // 10-10-2017
  // get post office by city
  getPostOffice(city) {
    // const response = this.httpClient({
    //   method: 'get',
    //   url: globalapipath + 'UserManagement/GetPostOfficeDataBYCity',
    //   params: {
    //     ID: JSON.stringify(city)
    //   }
    // });
    // return response;
  }

  public getCompanies(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_COMPANY_DATA}?Search=&StatusOfIndustry=&ISActive=&SDate=&EDate=&EntityType=&Legalstatus=
    &State=&City=&Country=`;
    return this.http.get<any[]>(url).pipe(map((response: any) => this.workOrderAdapter.convertResponse<any>(response)));
  }

  public getContractors(excludeContractorCode: string = ''): Observable<any[]> {
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_CONTRACTORS}?
    search=&sDate=&eDate=&selectedField=&isFilter=&isMain=&ExcludeContractor_Code=${excludeContractorCode}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.workOrderAdapter.convertResponse<any>(response)));
  }

  public getContractorsbydefault(userId: any, roleCode: any): Observable<any[]> {
    // tslint:disable-next-line: max-line-length
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_CONTRACTORSBY_DEFAULT}?UserId=${userId}&RoleCode=${roleCode}`;
    // console.log(url);
    return this.http.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }

  public getWorkOrderByContractorCode(contractorCode: string): Observable<any> {
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_WORK_ORDER_BY_CONTRACTOR}?contractorcode=${contractorCode}`;
    return this.http.get<any>(url);
  }

  // public getWorkOrderFilterData(contractorCode: any): Observable<any> {
  //   const url = GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_WORKORDER_FILTER + '&Contractor_Code=' + contractorCode;
  //   return this.http.get<any[]>(url).pipe(
  //     map((response: any) => this.workOrderAdapter.convertResponse<any>(response)));
  // }

  public getWorkOrderFilterData(contractorCode: any, orderType: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    // return this.http.get(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_WORKORDER_FILTER
    //   + '?sDate='
    //   + '&eDate='
    //   + '&search='
    //   + '&selectedField='
    //   + '&filterType='
    //   + '&condition='
    //   + '&isActive='
    //   + '&workOrderType=' + 228
    //   + '&workOrderOwner_Code='
    //   + '&workOrderHOD_Code='
    //   + '&department_Code='
    //   + '&isContractorType='
    //   + '&Plant_Code='
    //   + '&NatureOfWork_Code='
    //   + '&NatureOfWork_Code='
    //   + '&Contractor_Code=' + contractorCode
    //   + '&SubContractor_Code='
    //   + '&WorkOrder_Code='
    //   + '&SubWorkOrder_Code='
    //   + '&WorkSiteArea_Code='
    //   + '&IsHRCApplicable='
    //   + '&Status_Code='
    //   + '&UserId=').pipe(
    //     map((response: any) => this.workOrderAdapter.convertResponse<any>(response)));
    const payload = {
      sDate: null,
      eDate: null,
      selectedField: null,
      filterType: null,
      condition: null,
      isActive: null,
      workOrderType: null, //228,
      workOrderOwner_Code: null,
      workOrderHOD_Code: null,
      department_Code: null,
      isContractorType: null,
      Plant_Code: null,
      NatureOfWork_Code: null,
      Contractor_Code: contractorCode,
      SubContractor_Code: null,
      WorkOrder_Code: null,
      SubWorkOrder_Code: null,
      WorkSiteArea_Code: null,
      IsHRCApplicable: null,
      Status_Code: null,
      UserId: localStorage.getItem('UserID')
    };
    return this.http.post<any[]>(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_WORKORDER_FILTER, payload);
  }

  public getPlants(companyCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_PLANTS}?Search=&Company=${companyCode}&State=&City=&Country=&SDate=&EDate=
    &UnitType=&Ownership=&LocatedAt=&ISESICApplicable=`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.workOrderAdapter.convertResponse<any>(response)));
  }

  // public getDepartmentHeadList(departmentcode: any): Observable<any> {
  //   const url = `${GLOBAL.APIS.USER_MANAGEMENT.GET_DEPARTMENTHOD_LIST}?departmentCode=${departmentcode}`;
  //   return this.http.get<any[]>(url).pipe(
  //     map((response: any) => this.workOrderAdapter.convertResponse<any>(response)));
  // }

  getDepartmentHeadList(departmentcode) {

    const queryString = $.param({
      departmentCode: departmentcode == undefined ? "null" : JSON.stringify(departmentcode)
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENTHOD_LIST}?${queryString}`,
      httpOptions
    );
  }

  public getOrderTypes(code: string): Observable<any[]> {
    console.log('ot', code);
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_MASTERITEMLIST}?Search=&ShortCode=${code}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.workOrderAdapter.convertResponse<any>(response)));
  }

  public getOrderCategories(code: string): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_MASTERITEMLIST}?Search=&ShortCode=${code}`;
    console.log('oc', code);
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.workOrderAdapter.convertResponse<any>(response)));
  }

  public getNatureOfWorks(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_NATUREOFWORKS}?Search=`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.workOrderAdapter.convertResponse<any>(response)));
  }

  public getWorkSiteAreas(code: string): Observable<any[]> {
    console.log('wsa', code);

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_MASTERITEMLIST}?Search=&ShortCode=${code}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.workOrderAdapter.convertResponse<any>(response)));
  }

  public getExecutingDepartments() {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENTMASTERS}`;
    return this.http.get<any[]>(url);
  }

  public getDepartmentHeads(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENTS}?Search=`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.workOrderAdapter.convertResponse<any>(response)));
  }

  public getWorkTypes(code: string) {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_MASTERITEMLIST}?Search=&ShortCode=${code}`;
    console.log('wt', code);
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.workOrderAdapter.convertResponse<any>(response)));

    //
    // const queryString = $.param({
    //   Search:'',
    //   ShortCode: JSON.stringify(code)
    // });
    // return this.httpClient.request(
    //   GLOBAL.HTTP_GET,
    //   `${GLOBAL.APIS.MASTER_DATA.GET_MASTERITEMLIST}?${queryString}`,
    //   httpOptions
    // );
  }

  // SP_GetMasterItemListData_V2 Nesting call -- instead of GetMasterItemListData;
  public getMasterItemListData_v2(codes:any) {

    // console.log('wt', code);
    // return this.http.post<any[]>(url).pipe(
    //   map((response: any) => this.workOrderAdapter.convertResponse<any>(response)));

      const payload = {
        Filter: {
          ShortCode: codes,
          Parent_Code: null,
          Search: null, //"Document",
          Page_Code: 191, // null,
          RequireType: 1,   //0: Full master datawith parent info (Only @search applicable) |
          ActionType: 0,
          UserId: 142// localStorage.getItem('UserID'),
        },
        Fields:[
          {
              keyName: "Parent_Code",
              DisplayName: "ParentName"
          },
          {
              keyName: "MasterItem_Code",
              DisplayName: "Name",
              ExtraFields: [
                  "Pincode_Code",
                  "ZipCode",
                  "ShortName",
                  "ShortCode",
                  "Page_Code",
                  "Module_Code",
                  "MouleName",
                  "AppsGroups_Code",
                  "AppGroupName",
                  "IsDelete",
                  "IsActive"
                ]
              }
        ]
      };

      return this.http.post<any[]>(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GETMASTERITEMLISTDATA_V2, payload);
        // this.workOrderAdapter.convertResponse<any>(response)
  }

  public getEICUsers(userEmail: string) {

    const queryString = $.param({
      UserEmail: JSON.stringify(userEmail)
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.USER_MANAGEMENT.GET_EIC_USER_LIST}?${queryString}`,
      httpOptions
    );
  }

  public getWorkOrderDetails(workOrderCode: any): Observable<any> {
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_WORK_ORDERS}?sDate=&eDate=&search=&selectedField=&filterType=&condition=&isActive=
    &workOrderType=&workOrderOwner_Code=&workOrderHOD_Code=&department_Code=&isContractorType=&Plant_Code=&NatureOfWork_Code=
    &Contractor_Code=&SubContractor_Code=&WorkOrder_Code=${workOrderCode}&SubWorkOrder_Code=&WorkSiteArea_Code=&IsHRCApplicable=
    &Status_Code=&UserId=`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.workOrderAdapter.convertResponse<any>(response)));
  }

  public getWorkOrderByWorkOrderCode(workOrderCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_WORK_ORDERS_BY_WORK_ORDER_CODE}?workorderCode=${workOrderCode}&Drpfilterwork=1`;
    return this.http.get<any[]>(url).pipe(map((response: any) => response.Data));
  }

  public generateWONo(contractorVendorCode: any): Observable<any> {
    const url = `${GLOBAL.APIS.WORK_ORDER.GENERATE_WO_NO}?contractor_code=${contractorVendorCode}`;
    return this.http.get<any>(url);
  }

  public checkWONoAvailability(workOrderCode: string, workOrderNo: any = ''): Observable<any> {
    const url = `${GLOBAL.APIS.WORK_ORDER.CHECKWONOAVAILABILITY}?workOrderCode=${workOrderCode}&WorkOrderNo=${workOrderNo}`;
    return this.http.get<any[]>(url);
  }

  public addWorkOrder(value: any) {
    const url = `${GLOBAL.APIS.WORK_ORDER.ADD_WORKORDER}`;
    return this.http.post(url, value).pipe(
      map((response: any) => response.Data)
    );
  }

  public updateWorkOrder(value: any) {
    const url = `${GLOBAL.APIS.WORK_ORDER.UPDATE_WORKORDER}`;
    return this.http.post(url, value).pipe(
      map((response: any) => response.Data)
    );
  }

  deleteWorkOrder(code: any) {
    const url = `${GLOBAL.APIS.WORK_ORDER.DELETE_WORKORDER}?workorderCode=${code}`;
    return this.http.get(url);
  }

  public getDocumentTypes() {
    const url = `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_DOC_TYPES}`;
    return this.http.get<any[]>(url);
  }
  public getDocumentTypesPageModuleWise(data) {
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_DOCUMENTS_TYPES_PAGE_MODULE_WISE}`, data);
  }

  public getDocumentTypesPageModuleWiseV1(data) {
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_DOCUMENTS_TYPES_PAGE_MODULE_WISE_V1}`, data);
  }

  public getUserList(): Observable<any> {
    const url = `${GLOBAL.APIS.USER_MANAGEMENT.GET_USER_LIST}?search=&Company=&Plant=&Designation=&State=&City=&Country=
    &Gender=&isLock=&isActive=&isDelete=&isSA=&Role=&TimeZone=&Language=&IsOrganizationUser=`;
    return this.http.get(url).pipe(
      map((response: any) => this.workOrderAdapter.convertResponse<any>(response)));
  }


  public uploadDocuments(files: any[], params) {
    const url = `${GLOBAL.APIS.WORK_ORDER.ADD_DOCUMENT}`;
    const fd = new FormData();
    let docType = '';
    const filesToUpload = this.getNotUploadedItems(files);
    filesToUpload.forEach((fileDoc, index) => {
      fd.append(`file${String(index)}`, fileDoc['file'], fileDoc['file']['name']);
      docType += filesToUpload.length - 1 === index ? `${fileDoc.documentTypeCode}` : `${fileDoc.documentTypeCode},`;
    });
    fd.append('Doc_Type', docType);
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const param = params[key];
        fd.append(key, String(param));
      }
    }

    const headers = new HttpHeaders({
      // 'Content-Type': 'multipart/form-data',
      // 'Accept': 'application/json'
    });
    // headers = headers.delete('Content-Type');
    const requestOptions = {
      headers,
      body: fd
    };

    return this.http.request(GLOBAL.HTTP_POST, url, requestOptions);
  }

  private setFullName(item: any) {
    item.FullName = `${item.FirstName} ${item.MiddleName} ${item.LastName}`;
    return item;
  }

  private getNotUploadedItems(files: any[]) {
    return files.filter((item: any) => !item.isUploaded);
  }
}
