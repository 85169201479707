import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EntryfordayAdapter } from '../adapter/entryforday.adapter';
import { map } from 'rxjs/operators';
import { Contractor } from './rpt-punch-from-reader.module';
import { GLOBAL } from 'src/app/app.globals';

@Injectable({
  providedIn: 'root'
})
export class RptPunchFromReaderService {
  httpClient: any;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private http: HttpClient,
    private adapter: EntryfordayAdapter
  ) { } public getContractors(excludeContractorCode: string = '', workOrderCode: any = ''): Observable<any[]> {
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_CONTRACTORS}?
  search=&sDate=&eDate=&selectedField=&isFilter=&isMain=&ExcludeContractor_Code=${excludeContractorCode}&workOrderCode=${workOrderCode}`;
    return this.http.get < any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }

  // public getWorkOrdersByContractorCode(id: number): Observable<WorkOrder[]> {
  //   const url = `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_WO_CONTRACTOR_DATA}?ID=${id}`;
  //   return this.http.request<WorkOrder[]>(GLOBAL.HTTP_GET, url).pipe(
  //     map((items: any[]) => items.map((item: any) => this.adapter.toWorkOrderResponse(item)))
  //   );
  // }

  // Khushbu Add Start 12-11-2019
  public getPunchFromReader(): Observable<any> {
    const model = {
      date: null,
      UserId: '',
      company_Code: '',
      plant_Code: '',
      unit_Code: '',
      Department_Code: '',
      Section_Code: '',
      natureOfWork_Code: '',
      includingSubContractor: '',
      contractors: '',
      subContractors: '',
      workOrder_Code: '',
      subWorkOrder_Code: '',
      license_Code: '',
      subLicense_Code: '',
      ESIC: '',
      subESIC: '',
      WCPolicy: '',
      subWCPolicy: '',
      gender: '',
      skill_Code: '',
      trade_Code: '',
      Qulification_Code: '',
      techQualification_Code: '',
      streamQualification_Code: '',
      religion_Code: '',
      cast_Code: '',
      jobType: '',
      FirstAIDTraning: '',
      IsDomicial: '',
      FireFighter: '',
      CurrentStatus: '',
      SAge: '',
      EAge: '',
      Wage: '',
      ContactortMenType: '',
      ExpiredMedicalPeriod: '',
      ExpiredFireTraining: '',
      PoliceVerifed: '',
      ExcludeContractor: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.REPORTS.GET_PUNCH_FROM_READER}`, model);
  }
  //  return this.httpClient.get(GLOBAL.APIS.REPORTS.GET_PUNCH_FROM_READER);
  // }
  public getMonthlyOTReport(): Observable<any> {
    const model = {
      date: null,
      UserId: '',
      company_Code: '',
      plant_Code: '',
      unit_Code: '',
      Department_Code: '',
      Section_Code: '',
      natureOfWork_Code: '',
      includingSubContractor: '',
      contractors: '',
      subContractors: '',
      workOrder_Code: '',
      subWorkOrder_Code: '',
      license_Code: '',
      subLicense_Code: '',
      ESIC: '',
      subESIC: '',
      WCPolicy: '',
      subWCPolicy: '',
      gender: '',
      skill_Code: '',
      trade_Code: '',
      Qulification_Code: '',
      techQualification_Code: '',
      streamQualification_Code: '',
      religion_Code: '',
      cast_Code: '',
      jobType: '',
      FirstAIDTraning: '',
      IsDomicial: '',
      FireFighter: '',
      CurrentStatus: '',
      SAge: '',
      EAge: '',
      Wage: '',
      ContactortMenType: '',
      ExpiredMedicalPeriod: '',
      ExpiredFireTraining: '',
      PoliceVerifed: '',
      ExcludeContractor: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.REPORTS.GET_PUNCH_FROM_READER}`, model);
  }
  // return this.httpClient.get(GLOBAL.APIS.REPORTS.GET_PUNCH_FROM_READER);
  // }
  //   public getDailyPresentDetails(data: any): Observable<any> {
  //     const url = `${GLOBAL.APIS.REPORTS.GET_PUNCH_FROM_READER}`;
  //     return this.http.post<any>(url, data).pipe(
  //       map((response: any) => response.Data.Table));
  //   }
  public getPunchFromReaderDetails(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.REPORTS.GET_PUNCH_FROM_READER}`;
    return this.http.post < any>(url, data).pipe(
      map((response: any) => response));
  }
  // Khushbu Add END 12-11-2019

  public getWorkOrdersByContractorCode(contractorCode: string): Observable<any> {
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_WORK_ORDER_BY_CONTRACTOR}?contractorcode=${contractorCode}`;
    return this.http.get < any>(url).pipe(
      map((response: any) => response.Data));
  }

  public getSubContractorByWODataforDA(contractorCode: number, workOrderNo: number): Observable<Contractor[]> {
    const url = `http://cmmsborlapi.spikyarc.com/api/UserManagement/GetSubContractorByWODataforDA?contractorCode=
  ${contractorCode}&workOrder=${workOrderNo}`;
    return this.http.request < Contractor[] >(GLOBAL.HTTP_GET, url).pipe(
      map((items: any[]) => items.map((item: any) => this.adapter.toContractorResponse(item)))
    );
  }

  public getCompanies(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_COMPANY_DATA}?Search=&StatusOfIndustry=&ISActive=&SDate=&EDate=&EntityType=&Legalstatus=
  &State=&City=&Country=`;
    return this.http.get < any[]>(url).pipe(map((response: any) => response.Data.Table));
  }

  getLicenses(contractorCode: any) {
    const url = `${GLOBAL.APIS.LICENSE.GET_LICENSE}?search=&sDate=&eDate=&selectedField=&filterType=&condition=&State_Code=
              &Status_Code=&Plant_Code=&IsActive=&NatureOfLicense_Code=&NatureOfWork_Code=&TotalAccupied=
              &Contractor_Code=${contractorCode}&SubContractor_Code=&WorkOrder_Code=&SubWorkOrder_Code=&isContractorType=&UserId=`;
    return this.http.get(url).pipe(map((response: any) => response.Data.Table));
  }

  getWCPolicy(contractorCode: any) {
    const url = `${GLOBAL.APIS.REPORTS.GET_WC_POLICY}?search`;
    return this.http.get(url).pipe(map((response: any) => response.Data.Table));
  }

  public getPlants(companyCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_PLANTS}?Search=&Company=${companyCode}&State=&City=&Country=&SDate=&EDate=
  &UnitType=&Ownership=&LocatedAt=&ISESICApplicable=`;
    return this.http.get < any[]>(url).pipe(
      map((response: any) => response));
  }

  public getUnits(plantCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_UNITS}?Search=&PlantCode=${plantCode}`;
    return this.http.get < any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }

  public getDesignations(companyCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DESIGNATION}?Search=&companyCode=${companyCode}&GradeCode=&LevelCode=`;
    return this.http.get < any[]>(url).pipe(
      map((response: any) => response));
  }

  public getMasterItemByCode(code: string): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_MASTERITEMLIST}?Search=&ShortCode=${code}`;
    return this.http.get < any[]>(url).pipe(
      map((response: any) => response));
  }
  public getDepartments(plantCode: any = '', unitCode: any = ''): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENTS}?Search=&PlantCode=${plantCode}&UnitCode=${unitCode}`;
    return this.http.get < any[]>(url).pipe(
      map((response: any) => response));
  }

  public getSections(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_SECTION}?Search=`;
    return this.http.get < any[]>(url).pipe(
      map((response: any) => response));
  }

  public getNatureOfWorks(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_NATUREOFWORKS}?Search=`;
    return this.http.get < any[]>(url).pipe(
      map((response: any) => response));
  }

  public getShiftTypes(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_SHIFTTYPES}?Search=`;
    return this.http.get < any[]>(url).pipe(
      map((response: any) => response));
  }


  public getShifts(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_SHIFTS}?Search=&PlantCode=&ShiftTypeGroupCode=`;
    return this.http.get < any[]>(url).pipe(
      map((response: any) => response));
  }

  public getSkills(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_SKILLS}?Search=&PlantCode=&ParentSkillCode=&TradeCode=`;
    return this.http.get < any[]>(url).pipe(
      map((response: any) => response));
  }

  public addFilter(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.REPORTS.ADD_FILTER}?User_Code=${localStorage.getItem('UserID')}
  &Page_Code=${data.Page_Code}&FilterName=${data.FilterName}&JSONTextDetails=${data.JSONTextDetails}`;
    return this.http.post(url, {}).pipe(
      map((response: any) => response));
  }

  public updateFilter(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.REPORTS.UPDATE_FILTER}?Report_Code=${data.Report_Code}&User_Code=${localStorage.getItem('UserID')}
  &Page_Code=${data.Page_Code}&FilterName=${data.FilterName}&JSONTextDetails=${data.JSONTextDetails}`;
    return this.http.post(url, {}).pipe(
      map((response: any) => response));
  }

  public deleteFilter(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.REPORTS.DELETE_FILTER}?Report_Code=${data.Report_Code}&User_Code=${localStorage.getItem('UserID')}`;
    return this.http.post(url, {}).pipe(
      map((response: any) => response));
  }

  public getALLSavedFilterDescripationUserWise(): Observable<any[]> {
    const url = `${GLOBAL.APIS.REPORTS.GET_FILTER}?User_Code=${localStorage.getItem('UserID')}`;
    return this.http.get(url).pipe(map((response: any) => response
      .map((item: any) => this.adapter.parseJsonString(item))));
  }

}
