import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
declare var $: any;

@Component({
  selector: 'app-ag-select-edit-cell-renderer',
  templateUrl: './ag-select-edit-cell-renderer.component.html',
  styleUrls: ['./ag-select-edit-cell-renderer.component.css']
})
export class AgSelectEditCellRendererComponent implements OnInit, ICellRendererAngularComp {
  params;
  isValid = true;
  invalidMsg = '';
  inputClass = 'cellInput';
  width:number = 90;
  isShowSingleBtn:boolean = false;
  isShowAllBtn:boolean = false;

  itemList = [];
  defaultSelectedfieldList = ['PermanentVillageCode', 'VillageCode', 'EVillageCode', 'RVillageCode'];

  constructor() { }

  ngOnInit() {
  }

  agInit(params) {

      this.params = params;
      this.isShowSingleBtn = this.params.colDef.isShowSingleButton;
      this.isShowAllBtn = this.params.colDef.isShowAllButton;
      // if(this.params.colDef.field == 'WorkOrder' || this.params.colDef.field == 'LicenseNo' || this.params.colDef.field == 'ECPolicyNo'){
      //   this.width = 280;
      // }
      // console.log('param value');
      if (typeof params.dataList === 'function') {
        this.itemList = this.params.dataList();
      } else {
        this.itemList = this.params.dataListByIndex(this.params.rowIndex, this.params.column.colId);
      //  console.log('bbb', this.params.rowIndex+'---'+this.params.column.colId);

      }

      this.width = this.params.width ? this.params.width : 150;
      this.validateColumn();

  }

  refresh() {
    return true;
  }

  onValueChange($event) {
    if (this.params.data[this.params.column.colId] === $event.target.value) {
      return;
    }

    this.params.data[this.params.column.colId] = $event.target.value;
    if (this.params.forceSelection) {
      const forceSelection = this.params.forceSelection($event.target.value);
      if (forceSelection) {
        this.params.data[forceSelection.field] = forceSelection.value;
      }
    }

    this.params.onInputTextChange(this.params.rowIndex, this.params.column.colId);
    if(this.params.column.colId == "Qualification"){
      this.setQualificationVal($event.target.value, this.params.rowIndex);
    }
  }

  setQualificationVal(val, index){
    let optionTitle = $(".field-Qualification-"+index+" option[value='"+val+"']").text();
    if(this.params.column.colId == "Qualification" && optionTitle.toLowerCase() == "literate"){
      let QaulStreamVal = $(".field-QulificationStream-"+index+" option[data-id='NA']").val();
      if(QaulStreamVal != undefined && QaulStreamVal != null && QaulStreamVal != ''){
        $(".field-QulificationStream-"+index).val(QaulStreamVal);
        this.onValueChangeByClickCustom(QaulStreamVal, index, 'QulificationStream');
      }
      let techQaulStreamVal = $(".field-TechnicalQulification-"+index+" option[data-id='NA']").val();
      if(techQaulStreamVal != undefined && techQaulStreamVal != null && techQaulStreamVal != ''){
        $(".field-TechnicalQulification-"+index).val(techQaulStreamVal);
        this.onValueChangeByClickCustom(techQaulStreamVal, index, 'TechnicalQulification');
      }
    }
  }
  onValueChangeByClick(val, indxno) {
    this.params.data[this.params.column.colId] = val;
    if (this.params.forceSelection) {
      const forceSelection = this.params.forceSelection(val);
      if (forceSelection) {
        this.params.data[forceSelection.field] = forceSelection.value;
      }
    }
    this.params.onInputTextChange(indxno, this.params.column.colId, val);
  }

  onValueChangeByClickCustom(val, indxno, colName='') {
    this.params.data[colName] = val;
    if (this.params.forceSelection) {
      const forceSelection = this.params.forceSelection(val);
      if (forceSelection) {
        this.params.data[forceSelection.field] = forceSelection.value;
      }
    }
    this.params.onInputTextChange(indxno, colName, val);
  }

  validateColumn() {
    const obj = this.params.isColValid(this.params.rowIndex, this.params.column.colId);
    this.isValid = obj.isValid;
    this.inputClass = 'cellInput';
    this.invalidMsg = '';
    if (obj.requireValidation) {
      this.inputClass += ' require-validation-cell-input';
      this.invalidMsg = obj.msg;
    } else if (!obj.isValid) {
      this.inputClass += ' invalid-cell-input';
      this.invalidMsg = obj.msg;
    }
    this.inputClass += ' field-'+this.params.colDef.field+'-'+this.params.rowIndex+' custom-drop-down-box field'+this.params.colDef.field;
  }

  isDisable() {
    if (this.params.isDisable) {
      return this.params.isDisable(this.params);
    }
    return false;
  }

  setDropdownValUsingTitle(field, val, indexNo, selVal){
    if(val != null && val != undefined && val != ''){
      for (let index = indexNo + 1; index < 10000; index++) {
          if($(".custom-drop-down-box").hasClass("field-"+field+"-"+index)){
            let selectedval = $(".field-"+field+"-"+index+" option:selected").val();
            if(selectedval != null && selectedval != undefined && selectedval != ''){
              if(selVal == "specific"){
                break;
              }
            }else{
              let title = $(".field-"+field+"-"+indexNo+" option[value='"+val+"']").text();
              let optionVal = $('.field-'+field+'-'+index+' option[data-id="'+title+'"]').val();
              $(".field-"+field+"-"+index).val(optionVal);
              this.onValueChangeByClick(optionVal, index);

              if(field == "Qualification"){
                this.setQualificationVal(val, index);
              }
            }
          }else{
            break;
          }
      }
    }
  }

  checkButtonExistFieldForTitle(field){
    let fieldList = ['Plant_Code', 'Unit_Code', 'Department_Code', 'SectionPlantRelationId1', 'SectionPlantRelationId2', 'SectionPlantRelationId3', 'Relation', 'MaritalStatus', 'Qualification', 'QulificationStream', 'TechnicalQulification', 'Cast', 'Religion', 'WageCriteria', 'LabourDemandFor', 'Nationality', 'WorkSite', 'WorkCategory', 'ValidityPeriod', 'SectionPlantRelationId3', 'WorkmanCategory','SiteShiftGroup', 'WorkOrder', 'LicenseNo', 'ECPolicyNo', 'Trade'];
    return fieldList.includes(field) ? true : false;
  }

  checkButtonExistFieldAllForTitle(field){
    let fieldList = ['Unit_Code', 'Department_Code', 'SectionPlantRelationId1', 'SectionPlantRelationId2', 'Relation', 'MaritalStatus', 'Qualification', 'QulificationStream', 'TechnicalQulification', 'Cast', 'Religion', 'WageCriteria', 'LabourDemandFor', 'Nationality', 'WorkSite', 'WorkCategory', 'ValidityPeriod', 'SectionPlantRelationId3', 'WorkmanCategory','SiteShiftGroup', 'Trade'];
    return fieldList.includes(field) ? true : false;
  }

  getFieldWidth(field){
    if(field == 'WorkOrder' || field == 'LicenseNo' || field == 'ECPolicyNo'){
      return 250;
    }else if(field == 'Plant_Code'){
      return 224;
    }else if(this.defaultSelectedfieldList.includes(field)){
      return 140;
    }
    else{
      return this.width;
    }
  }

  defaultSelectVal(val, field, index){
    if(this.defaultSelectedfieldList.includes(field)){
      if(this.itemList && this.itemList.length > 0){
        if(val == "" || val == null){
          //this.onValueChangeByClick(this.itemList[0]['id'], index);
          //return this.itemList[0]['id']
        }else{
          return val;
        }
      }else{
        return val;
      }
    }else{
    return val;
    }

  }
}
