import { Injectable, ViewContainerRef, Type, ComponentRef, ɵComponentFactory, ComponentFactoryResolver } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ManPowerAdapter } from './adapter/man-power.adapter';
import { of, Subject } from 'rxjs';
import {
  SectionList, PlantMaster, GetRequestionList, SaveLabourMovement, AttendanceApprove,
  ManpowerType, RoleCode, ManpowerRequestStatus
} from './man-power.modal';
import * as $ from 'jquery';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
import * as moment from 'moment';
import { Contractor } from './model/man-power.model';
import { BASE_URL, GLOBAL } from '../app.globals';

@Injectable({
  providedIn: 'root'
})
export class ManPowerService {
  public subject: Subject<any>;
  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private adapter: ManPowerAdapter, private httpClient: HttpClient) {
    this.subject = new Subject();
  }

  // tslint:disable-next-line: max-line-length
  public getDailyManPowerAssignLocation(dataItem: {
    date: string, shiftTypeGroupCode: number, companyCode: number, plantCode: number, contractorCode: number,
    workOrderCode: number, licenseCode: number, WCPolicy: number, subContractorCode: number, subWorkOrderCode: number,
    subLicenseCode: number, subWCPolicyCode: number
  }): Observable<any> {

    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.MAN_POWER.GET_MAN_POWER_ALLOCATION

      // + '?date=' + dataItem.date + '&company_Code=' + dataItem.companyCode + '&plant_Code=' + dataItem.plantCode +
      // '&contractor_Code=' + dataItem.contractorCode + 	'&shiftGroupType_Code=' + dataItem.shiftTypeGroupCode +
      // '&workOrder_Code=' + dataItem.workOrderCode + '&license_Code=' + dataItem.licenseCode +
      // '&WCPolicy=' + dataItem.WCPolicy + '&subContractors=' + dataItem.subContractorCode +
      // '&subWorkOrder_Code=' + dataItem.subWorkOrderCode + '&subLicense_Code=' + dataItem.subLicenseCode

      + '?date=' + dataItem.date + '&shiftGroupType_Code=' + dataItem.shiftTypeGroupCode + '&company_Code=' + dataItem.companyCode
      + '&plant_Code=' + dataItem.plantCode + '&contractor_Code=' + dataItem.contractorCode + '&workOrder_Code=' + dataItem.workOrderCode +
      '&license_Code=' + dataItem.licenseCode + '&WCPolicy=' + dataItem.WCPolicy + '&subContractors=' +
      dataItem.subContractorCode + '&subWorkOrder_Code=' + dataItem.subWorkOrderCode + '&subLicense_Code=' + dataItem.subLicenseCode
      + '&subWCPolicy=' + dataItem.subWCPolicyCode)
      .pipe(map((response: any) => response.Data));
    // return of(this.getTestData()).pipe(map((response: any) => response.Data));
    // tslint:disable-next-line: prefer-template
    // return this.httpClient.get(GLOBAL.APIS.MAN_POWER.GET_MAN_POWER_ALLOCATION
    //  + '?date=' + '2019-03-28' + '&contrcator_Code=' + '301' + '&workOrder_Code=' + '416');
  }

  // tslint:disable-next-line: max-line-length
  public getDailyShiftRequistionData(dataItem: {
    date: string, shiftTypeGroupCode: number, companyCode: number, plantCode: number, contractorCode: number,
    workOrderCode: number, licenseCode: number, WCPolicy: number, subContractorCode: number, subWorkOrderCode: number,
    subLicenseCode: number, subWCPolicyCode: number
  }): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.MAN_POWER.GET_MAN_POWER_DAILY_SHIFT_REQUISTION

      // + '?date=' + dataItem.date + '&company_Code=' + dataItem.companyCode + '&plant_Code=' + dataItem.plantCode +
      // '&contractor_Code=' + dataItem.contractorCode + 	'&shiftGroupType_Code=' + dataItem.shiftTypeGroupCode +
      // '&workOrder_Code=' + dataItem.workOrderCode + '&license_Code=' + dataItem.licenseCode +
      // '&WCPolicy=' + dataItem.WCPolicy + '&subContractors=' + dataItem.subContractorCode +
      // '&subWorkOrder_Code=' + dataItem.subWorkOrderCode + '&subLicense_Code=' + dataItem.subLicenseCode

      + '?date=' + dataItem.date + '&shiftGroupType_Code=' + dataItem.shiftTypeGroupCode + '&company_Code=' + dataItem.companyCode
      + '&plant_Code=' + dataItem.plantCode + '&contractor_Code=' + dataItem.contractorCode + '&workOrder_Code=' + dataItem.workOrderCode +
      '&license_Code=' + dataItem.licenseCode + '&WCPolicy=' + dataItem.WCPolicy + '&subContractors=' +
      dataItem.subContractorCode + '&subWorkOrder_Code=' + dataItem.subWorkOrderCode + '&subLicense_Code=' + dataItem.subLicenseCode
      + '&subWCPolicy=' + dataItem.subWCPolicyCode)
      .pipe(map((response: any) => response.Data.Table));
    // tslint:disable-next-line: prefer-template
    // return this.httpClient.get(GLOBAL.APIS.MAN_POWER.GET_MAN_POWER_ALLOCATION
    //  + '?date=' + '2019-03-28' + '&contrcator_Code=' + '301' + '&workOrder_Code=' + '416');
  }

  /** Invoke this method and it is return the data of requisition list as observable */
  public getRequisitionList(dataItem: GetRequestionList): Observable<any> {
    return this.httpClient.get(`${GLOBAL.APIS.MAN_POWER.GET_MAN_POWER_ALLOCATION_REQUISITION_REQUESTLIST}?Search=${dataItem.search}
    &CompanyCodes=${dataItem.companyCodes}&StateCodes=${dataItem.stateCodes}&PlantCodes=${dataItem.plantCodes}
    &UnitCodes=${dataItem.unitCodes}&DepartmentCodes=${dataItem.departmentCodes}&SectionCodes=${dataItem.sectionCodes}
    &ContractorCodes=${dataItem.contractorCodes}&sDate=${dataItem.sDate}&eDate=${dataItem.eDate}&UserId=${dataItem.userId}`);
  }

  /** Invoke this method and it is return the data of requisition list as observable */
  public getRequisitionListNew(dataItem: GetRequestionList): Observable<any> {
    return this.httpClient.get(`${GLOBAL.APIS.MAN_POWER.GET_MAN_POWER_ALLOCATION_REQUISITION_REQUESTLIST}?Search=${dataItem.search}
    &CompanyCodes=${dataItem.companyCodes.toString()}&StateCodes=${dataItem.stateCodes.toString()}&PlantCodes=${dataItem.plantCodes.toString()}
    &UnitCodes=${dataItem.unitCodes.toString()}&DepartmentCodes=${dataItem.departmentCodes.toString()}&SectionCodes=${dataItem.sectionCodes.toString()}
    &ContractorCodes=${dataItem.contractorCodes.toString()}&sDate=${dataItem.sDate}&eDate=${dataItem.eDate}&UserId=${dataItem.userId}
    &ShiftCode=${dataItem.shiftCode}`);
  }

  public plantMaster(dataItem: PlantMaster): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.MAN_POWER.GET_PLANT_MASTER_DATA
      + '?Search=' + dataItem.search + '&Company=' + dataItem.company + '&StateCode='
      + dataItem.stateCode + '&CityCode=' + dataItem.citycode + '&CountryCode='
      + dataItem.countryCode + '&UnitType' + dataItem.unitType + '&Ownership'
      + dataItem.ownership + '&LocatedAt' + dataItem.locatedAt +
      '&ISESICApplicable' + dataItem.iSESICApplicable);
  }

  public unitMaster(dataItem: { search: string, plantCode: number }): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.MAN_POWER.GET_UNIT_MASTER_DATA
      + '?Search=' + dataItem.search + '&PlantCode=' + dataItem.plantCode).pipe(map((response: any) => response.Data.Table));
  }

  public shiftMaster(dataItem: { search: string, plantCode: number, shiftTypeGroupCode: number }): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.MAN_POWER.GET_SHIFT_MASTER_DATA
      + '?Search=' + dataItem.search + '&PlantCode=' + dataItem.plantCode + '&ShiftTypeGroupCode=' + dataItem.shiftTypeGroupCode)
      .pipe(map((response: any) => response.Data.Table));
  }

  // tslint:disable-next-line: max-line-length
  public shiftList(dataItem: { sDate: string, eDate: string, plantCode: number, shiftCode: number, shiftTypeGroupCode: number, shiftGroupCode: number }): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.MAN_POWER.GET_SHIFT_LIST_DATA
      + '?StartDate=' + dataItem.sDate + '&EndDate=' + dataItem.eDate + '&Plant_Code='
      // tslint:disable-next-line: max-line-length
      + dataItem.plantCode + '&Shift_Code=' + dataItem.shiftCode + '&ShiftTypeGroup_Code=' + dataItem.shiftTypeGroupCode + '&ShiftGroup_Code=' + dataItem.shiftGroupCode)
      .pipe(map((response: any) => response.Data.Table));
  }

  public departMentMaster(dataItem: { search: string, plantCode: number, unitCode: number }): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.MAN_POWER.GET_DEPARTMENT_MASTER_DATA
      + '?Search=' + dataItem.search + '&PlantCode=' + dataItem.plantCode + '&UnitCode' + dataItem.unitCode);
  }

  public natureOfWork(dataItem: { search: string }): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.MAN_POWER.GET_NATURE_OF_WORK_MASTER_DATA
      + '?Search=' + dataItem.search);
  }

  public skillmaster(dataItem: { search: string, plantCode: number, parentSkillCode: number, tradeCode: number }): Observable<any> {
    const model = dataItem;
    // tslint:disable-next-line: prefer-template
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_AppliedLeaveType}`, dataItem);
  }

  // return this.httpClient.get(GLOBAL.APIS.MAN_POWER.GET_SKILLS
  // + '?Search=' + dataItem.search + '?plantCode' + dataItem.plantCode + '?parentSkillCode'
  // + dataItem.parentSkillCode + '?tradeCode' + dataItem.tradeCode);
  // }

  public skillmasterByNatureofWork(natureOfWorkCode: number): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.MAN_POWER.GET_SKILLS_BY_NATUREOFWORK
      + '?NatureOfWork_Code=' + natureOfWorkCode);
  }
  public section(dataItem: SectionList): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.MAN_POWER.GET_SECTION_MASTER_DATA
      + '?Search=' + dataItem.search + '&ParentSectionCode' + dataItem.parentSectionCode + '&PlantCode' + dataItem.plantCode
      + '&DepartmentCode' + dataItem.departmentCode + '&UnitCode' + dataItem.unitCode + '&CompanyCode' + dataItem.companyCode);
  }

  getDropDownList(url: string, params): Observable<any> {
    const queryString = $.param(params);
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${BASE_URL}/${url}?${queryString}`,
      httpOptions
    );
  }

  public getAllManPowerData(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MAN_POWER.GET_MAN_POWER_ALLDATA}`;
    const data = {
      Plant_Code: null,
      Unit_Code: null,
      Department_Code: null,
      Section_Code: null,
      ContractorCodes: null,
      // UserId: GLOBAL.USER.LOGGED_IN_USER,
      UserId: localStorage.getItem('UserID'),
    };
    return this.httpClient.post<any[]>(url, data).pipe(map((response: any) => response));
  }

  public getManPowerDatail(code: number): Observable<any[]> {
    const url = `${GLOBAL.APIS.MAN_POWER.GET_MAN_POWER_DETAIL}`;
    const data = {
      ManpowerRequisition_Code: code,
      UserId: localStorage.getItem('UserID'),
    };
    return this.httpClient.post<any[]>(url, data).pipe(map((response: any) => response.Data[0]));
  }

  public getManPowerReduceDatail(code: number): Observable<any[]> {
    const url = `${GLOBAL.APIS.MAN_POWER.GET_MAN_POWER_REDUCE_DETAIL}`;
    // const url = `${GLOBAL.APIS.MAN_POWER.MANPOWER_REDUCE}`;
    const data = {
      ManpowerRequisition_Code: code,
      UserId: localStorage.getItem('UserID'),
    };
    // return this.httpClient.get<any[]>(url).pipe(map((response: any) => response.Data[0]));
    return this.httpClient.post<any[]>(url, data).pipe(map((response: any) => response.Data[0]));
  }

  public getAllManPowerData1(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MAN_POWER.GET_MAN_POWER_ALLDATA_OLD}`;
    const data = {
      UserId: GLOBAL.USER.LOGGED_IN_USER,
    };
    return this.httpClient.post<any[]>(url, data).pipe(map((response: any) => response.Data));
  }

  public getAllManPowerDataOld(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MAN_POWER.GET_MAN_POWER_ALLDATA}?Search=&CompanyCodes=&StateCodes=&PlantCodes=&UnitCodes=
    &DepartmentCodes=&SectionCodes=&ContractorCodes=&sDate=&eDate=&UserId=${GLOBAL.USER.LOGGED_IN_USER}`;
    // &DepartmentCodes=&SectionCodes=&ContractorCodes=&sDate=&eDate=&UserId=${GLOBAL.USER.LOGGED_IN_USER}`;
    return this.httpClient.get<any[]>(url).pipe(map((response: any) => response.Data.Table));
  }

  public saveLabourMovementData(saveLabourMovementsArray: SaveLabourMovement[]): Observable<any> {
    const url = `${GLOBAL.APIS.MAN_POWER.GET_MAN_POWER_ALLOCATION_SAVE_POWER_MOVEMENT_DATA}?ManpowerRequisiton_Code=
    &EntryDateTime=&ContractorVendor_Code=
    &WorkOrder_Code=&NewWorkOrder_Code=&License_Code=&ECPolicy_Code=&GatePassRequest_Code=&ULC=&GatePassNo=&Plant_Code=
    &Unit_Code=&Department_Code=&section_Code=&InPunchDateTime=&OutPunchDateTime=&IsSubmited=`;
    return this.httpClient.post(url, saveLabourMovementsArray);
  }

  // public postAttendenceApprove(attendanceApproveData: AttendanceApprove[]): Observable<any> {
  //   const url = `${GLOBAL.APIS.MAN_POWER.GET_MAN_POWER_ALLOCATION_ATTENDENCE_APPROVE}?ManpowerRequisiton_Code=
  //   &EntryDateTime=&ContractorVendor_Code=
  //   &WorkOrder_Code=&NewWorkOrder_Code=&License_Code=&ECPolicy_Code=&GatePassRequest_Code=&ULC=&GatePassNo=&Plant_Code=
  //   &Unit_Code=&Department_Code=&section_Code=&InPunchDateTime=&OutPunchDateTime=&IsSubmited=&LabourMovement_Code=
  //   &UserId=&RoleCode=&Action=&Comment=&ApprovedOT=&ApprovedWH=`;
  //   return this.httpClient.post(url, attendanceApproveData);
  // }
  public postAttendenceApprove(attendanceApproveData: AttendanceApprove[]): Observable<any> {
    const url = `${GLOBAL.APIS.MAN_POWER.GET_MAN_POWER_ALLOCATION_ATTENDENCE_APPROVE}`;
    return this.httpClient.post<any[]>(url, attendanceApproveData).pipe(
      map((response: any) => response.Data));
  }

  public getDepartmentPlantUnitSectionTree1(plantCode: any, unitCode: any, departmentCode: any): Observable<any> {
    //
    // currently there is not data for current user, so set the user id = 1
    // const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENT_PLANT_UNIT_SECTION_TREE}?userId=${GLOBAL.USER.LOGGED_IN_USER}`;
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENT_PLANT_UNIT_SECTION_TREE_New}?userId=${localStorage.getItem('UserID')}`;

    //  const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENT_PLANT_UNIT_SECTION_TREE_New}?userId=${GLOBAL.USER.LOGGED_IN_USER}
    //     &plantCode=${plantCode}&unitCode=${unitCode}&departmentCode=${departmentCode}`;
    return this.httpClient.get(url).pipe(map((response: any) => response.Data));
  }

  public getDepartmentPlantUnitSectionTree(plantCode: any, unitCode: any, departmentCode: any, data: any): Observable<any> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENT_PLANT_UNIT_SECTION_DATA}`;
    console.log('API eMerge', data);
    return this.httpClient.post(url, data);
  }



  public getWeekOffDateList(startDate: any, endDate: any, plantCode: string): Observable<any[]> {
    const url = `${GLOBAL.APIS.MAN_POWER.GET_WEEK_OFF_DATELIST}?plantCode=${plantCode}&sDate=${startDate}&eDate=${endDate}`;
    return this.httpClient.get<any[]>(url).pipe(map((response: any) => response.Data));
  }

  public getHolidayDateList(startDate: any, endDate: any, companyCode: any, plantCode: string): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_HOLIDAY_LIST}?Search=&sDate=${startDate}&eDate=${endDate}
    &Company_Code=${companyCode}&Plant_Code=${plantCode}`;
    return this.httpClient.get<any[]>(url).pipe(map((response: any) => response.Data.Table));
  }

  public getManpowerBudget(companyCode: string, stateCode: string, plantCode: string, departmentCode: string,
    monthName: string): Observable<any[]> {
    const url = `${GLOBAL.APIS.MAN_POWER.GET_MAN_POWER_BUDGET_FOR_MONTH}?companyCode=${companyCode}
    &stateCode=${stateCode}&plantCode=${plantCode}&departmentCode=${departmentCode}&monthName=${monthName}`;
    return this.httpClient.get<any[]>(url).pipe(map((response: any) => response.Data));
  }

  public getState(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_STATE}?Search=`;
    return this.httpClient.get<any[]>(url).pipe(
      map((response: any) => response));
  }

  public getAllSectionShiftList(companyCode: any, stateCode: any, plantCode: any, unitCode: any[], departmentCode: any,
    sectionCodes: any[], workmencategoryCodes: any[]): Observable<any> {
    // currently there is not data for current user, so set the user id = 1
    // const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENT_PLANT_UNIT_SECTION_TREE}?userId=${GLOBAL.USER.LOGGED_IN_USER}`;
    let sectionCodesParams = '';
    sectionCodes.forEach((item: any) => {
      sectionCodesParams = sectionCodesParams === '' ? item : `${sectionCodesParams},${item}`;
    });
    let workmencategoryCodesParams = '';
    workmencategoryCodes.forEach((item: any) => {
      workmencategoryCodesParams = workmencategoryCodesParams === '' ? item : `${workmencategoryCodesParams},${item}`;
    });
    const url = `${GLOBAL.APIS.MAN_POWER.GET_ALL_MPRSHIFT_SECTION_LIST}?CompanyCode=${companyCode}
    &StateCode=${stateCode}&UnitCode=${unitCode}&PlantCode=${plantCode}&DepartmentCode=${departmentCode}
    &SectionCodes=${sectionCodesParams}&WorkMenCategoryCodes=${workmencategoryCodesParams}`;
    // return this.httpClient.get(url).pipe(map((response: any) => this.adapter.convertResponse(response))); //kh
    return this.httpClient.get(url).pipe(map((response: any) => response.Data.Table1));
  }

  public approveAndSavePowerRequisition(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MAN_POWER.APPROVE_AND_SAVE_POWER_REQUISITION}`;
    return this.httpClient.post<any[]>(url, data).pipe(
      map((response: any) => response.Data));
  }

  public savePowerRequisitionHRAssign(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MAN_POWER.SAVE_MANPOWER_REQUISITION_HRASSIGN}`;
    return this.httpClient.post<any[]>(url, data).pipe(
      map((response: any) => response.Data));
  }


  public getPowerRequistionForApprove(manpowerRequisitonCode: any): Observable<any> {
    const url = `${GLOBAL.APIS.MAN_POWER.GET_POWER_REQUISITION_FOR_APPROVE}?manpowerRequisiton_Code=${manpowerRequisitonCode}`;
    return this.httpClient.get(url).
      pipe(map((response: any) => this.convertResponseManPowerObject(response.Data)));
  }

  public canUserApprovedManPowerRequest_old(value: any): boolean {
    const userRoles: any[] = JSON.parse(localStorage.getItem('Role'));
    if (value.Status !== ManpowerRequestStatus.SUBMITED) {
      return false;
    }
    if (userRoles.length === 0) {
      return false;
    }
    const unitOrPlantHead = userRoles.find((item: any) => item.Role_Code === RoleCode.UNITHEAD || item.Role_Code === RoleCode.PLANTHEAD);
    const hrHead = userRoles.find((item: any) => item.Role_Code === RoleCode.HRHEAD);
    if (value.ManPowerType === ManpowerType.BUDGETED && unitOrPlantHead) {
      return true;
    }
    if (value.ManPowerType === ManpowerType.NONBUDGETED && hrHead) {
      return true;
    }
    return false;
  }

  public canUserEdit(value: any): boolean {
    // const userRoles: any[] = JSON.parse(localStorage.getItem('Role'));

    if (value.Status === ManpowerRequestStatus.SUBMITED
      // && value.RequestedBy === `${GLOBAL.USER.LOGGED_IN_USER}`
      && value.RequestBy === `${GLOBAL.USER.LOGGED_IN_USER}`
    ) {
      return true;
    }
    return false;
  }

  public canUserAddManPower(): boolean {

    // const userRoles: any[] = JSON.parse(localStorage.getItem('Role'));
    const userRoles: any[] = JSON.parse(localStorage.getItem('Role'));
    if (userRoles.length === 0) {
      return false;
    }
    return userRoles.find((item: any) => item.Role_Code === RoleCode.SECTIONHEAD);
  }

  public canUserDelete(value: any): boolean {
    const userRoles: any[] = JSON.parse(localStorage.getItem('Role'));
    if (userRoles.length === 0) {
      return false;
    }
    if (value.level === 1 && value.RequestedBy === localStorage.getItem('RoleCode')) {
      return true;
    }
    if (value.RequestedBy === localStorage.getItem('RoleCode') && value.LastApprovedBy === null) {
      return true;
    }
    if (value.LastApprovedBy != null) {
      const secHead = userRoles.find((item: any) => item.Role_Code === RoleCode.SECTIONHEAD);
      const unitDeptHead = userRoles.find((item: any) => item.Role_Code === RoleCode.DEPTHEAD || item.Role_Code === RoleCode.UNITHEAD);
      const hrHead = userRoles.find((item: any) => item.Role_Code === RoleCode.HRHEAD);
      if ((value.level === 2 && secHead != null) ||
        (value.level > 1 && value.level <= 3 && unitDeptHead != null) ||
        (value.level > 1 && hrHead != null)) {
        return true;
      }
    }

    return false;
  }

  public canUserApprovedManPowerRequest(value: any): boolean {
    const userRoles: any[] = JSON.parse(localStorage.getItem('Role'));
    if (userRoles.length === 0) {
      return false;
    }

    const unitOrDeptHead = userRoles.find((item: any) => item.Role_Code === RoleCode.UNITHEAD);
    if (value.ManPowerType === ManpowerType.BUDGETED && unitOrDeptHead) {
      return true;
    }
    if (value.ManPowerType === ManpowerType.NONBUDGETED && unitOrDeptHead) {
      return true;
    }
    const deptHead = userRoles.find((item: any) => item.Role_Code === RoleCode.DEPTHEAD);
    if (value.ManPowerType === ManpowerType.BUDGETED && deptHead) {
      return true;
    }

    const siteHrHead = userRoles.find((item: any) => item.Role_Code === RoleCode.SITEHRHEAD);
    if (siteHrHead) {
      return true;
    }
    if (value.Status === ManpowerRequestStatus.APPROVED) {
      const plantHrHead = userRoles.find((item: any) => item.Role_Code === RoleCode.HRHEAD);
      if (plantHrHead) {
        return true;
      }
    }
    if (value.Status === ManpowerRequestStatus.APPROVED) {
      const plantHrbpHead = userRoles.find((item: any) => item.Role_Code === RoleCode.HRBP);
      if (plantHrbpHead) {
        return true;
      }
    }
    if (value.Status === ManpowerRequestStatus.APPROVED) {
      const securityHead = userRoles.find((item: any) => item.Role_Code === RoleCode.SECURITY);
      if (securityHead) {
        return true;
      }
    }
    return false;
  }

  public isEditAllow(): boolean {

    const userRoles: any[] = JSON.parse(localStorage.getItem('Role'));
    if (userRoles.length === 0) {
      return false;
    }
    const plantHrHead = userRoles.find((item: any) => item.Role_Code === RoleCode.HRHEAD);
    if (plantHrHead) {
      return true;
    }
    // Kh start 19-05-2020
    const contractor = userRoles.find((item: any) => item.Role_Code === RoleCode.CONTRACTOR);
    if (contractor) {
      return true;
    }
    // Kh End 19-05-2020
    return false;
  }
  public isAllowForCreateBackDateReq(): boolean {
    const gSettingForBackDateReqCreationEnable: any[] = JSON.parse(localStorage.getItem('GSettingForBackDateReqCreationEnable'));
    if (gSettingForBackDateReqCreationEnable) {
      return true;
    }

  }

  // public getContractors(excludeContractorCode: string = ''): Observable<any[]> {
  //   const url = `${GLOBAL.APIS.WORK_ORDER.GET_CONTRACTORS}?
  //   search=&sDate=&eDate=&selectedField=&isFilter=&isMain=&ExcludeContractor_Code=${excludeContractorCode}`;
  //   return this.httpClient.get<any[]>(url).pipe(
  //     map((response: any) => response.Data.Table));
  // }

  private convertResponseManPowerObject(list: any): any {
    const manPowerObj = list;
    if (list.ManpowerShiftRelations && list.ManpowerShiftRelations.length > 0) {
      manPowerObj.SectionCodes = [
        {
          id: list.ManpowerShiftRelations[0].SectionPlantRelationId,
          itemName: list.ManpowerShiftRelations[0].SectionName
        }
      ];
      manPowerObj.SectionName = list.ManpowerShiftRelations[0].SectionName;
    } else {
      manPowerObj.SectionCodes = [];
      manPowerObj.SectionName = '';
    }

    const shiftSectionList = manPowerObj.ManpowerShiftRelations.map((item: any) => item.CategoryWorkmenMIL_Code);
    const distinctShiftSections = new Set(shiftSectionList);
    manPowerObj.CategoryWorkmenMIL_Code = [];
    let workManCategoryNames = '';
    distinctShiftSections.forEach((shiftSectionCode: any) => {
      manPowerObj.CategoryWorkmenMIL_Code.push(shiftSectionCode);
      const shiftSection = manPowerObj.ManpowerShiftRelations.find((item: any) => item.CategoryWorkmenMIL_Code === shiftSectionCode);
      if (shiftSection) {
        workManCategoryNames = workManCategoryNames !== '' ? `${workManCategoryNames},${shiftSection.CategoryWorkmen}`
          : shiftSection.CategoryWorkmen;
      }
    });
    manPowerObj.workManCategoryNames = workManCategoryNames;
    const shiftSections = manPowerObj.ManpowerShiftRelations.map((item: any) => {
      return {
        CategoryWorkmenMIL_Code: item.CategoryWorkmenMIL_Code,
        SectionPlantRelationId: item.SectionPlantRelationId,
        Section_Code: item.Section_Code,
        Total: item.Total,
        Date: {
          date: moment(item.Date),
          displayDate: moment(item.Date).format('DD-MM-YYYY')
        },
        Shift_Code: item.Shift_Code
      };
    });
    manPowerObj.ManpowerShiftRelations = shiftSections;
    console.log('manPowerObj', manPowerObj);
    return manPowerObj;
  }

  public createComponent<T>(viewContainerRef: ViewContainerRef, type: Type<T>): ComponentRef<T> {
    const componentFactory: ɵComponentFactory<T> = this.componentFactoryResolver.resolveComponentFactory(type);
    return viewContainerRef.createComponent(componentFactory);
  }

  public getContractors(excludeContractorCode: string = '', workOrderCode: any = ''): Observable<any[]> {
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_CONTRACTORS}?search=&sDate=&eDate=&selectedField=&isMain=&
    ExcludeContractor_Code=${excludeContractorCode}&workOrderCode=${workOrderCode}&contractor_Code=`;
    // console.log(url);
    return this.httpClient.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }
  public getContractorsByPlant(plantCode: string = ''): Observable<any[]> {
    // tslint:disable-next-line: max-line-length
    const url =
      // tslint:disable-next-line: max-line-length
      `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTRACTOR_PLANT}?UserId=${GLOBAL.USER.LOGGED_IN_USER}&Plant_Code=${plantCode}`;
    console.log('getContractors', url);
    return this.httpClient.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }

  public getContractorsbydefault(userId: any, roleCode: any): Observable<any[]> {
    // const url = `${GLOBAL.APIS.WORK_ORDER.GET_CONTRACTORSBYDEFAULT}?UserId=${userId}&RoleCode=${roleCode}`;
    // // console.log(url);
    // return this.http.get<any[]>(url).pipe(
    //   map((response: any) => response.Data.Table));

    const url = `${GLOBAL.APIS.WORK_ORDER.GET_BYDEFAULTCONTRACTORS}?UserId=${GLOBAL.USER.LOGGED_IN_USER}&RoleCode=${GLOBAL.USER.ROLE_CODE}`;
    return this.httpClient.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }

  public getPunchFromReaderDetails(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.REPORTS.GET_PUNCH_FROM_READER}`;
    console.log('URL', url);
    console.log('DATA', data);
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response));
  }
  public getFormDDetails(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.REPORTS.GET_FORM_D_REPORT}`;
    console.log('URL', url);
    console.log('DATA', data);
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response));
  }
  public getDailyPresentDetails(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.REPORTS.GET_DAILY_PRESENT}`;
    //    console.log('URL getDailyPresentDetails', url);
    //   console.log('DATA getDailyPresentDetails', data);
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response));
  }

  // public getWorkOrdersByContractorCode(contractorCode: string): Observable<any> {
  //   const url = `${GLOBAL.APIS.WORK_ORDER.GET_WORK_ORDER_BY_CONTRACTOR}?contractorcode=${contractorCode}`;
  //   return this.httpClient.get<any>(url).pipe(
  //     map((response: any) => response.Data));
  // }

  // public getWorkOrdersByContractorCode(contractorCode: number, plantcode: number): Observable<any> {
  //   const url = `${GLOBAL.APIS.WORK_ORDER.GET_WORK_ORDER_BY_CONTRACTOR}?contractorcode=${contractorCode}&plantcode=${plantcode}`;
  //   return this.httpClient.get<any>(url).pipe(
  //     map((response: any) => response.Data));
  // }

  public getWorkOrdersByContractorCode(contractorCode: number, plantcode: number): Observable<any> {
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_WORK_ORDER_BY_CONTRACTOR}?contractorcode=${contractorCode}&plantcode=${plantcode}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => response.Data));
  }

  public getSubContractorByWODataforDA(contractorCode: number, workOrderNo: number): Observable<Contractor[]> {
    const url = `httpClient://cmmsborlapi.spikyarc.com/api/UserManagement/GetSubContractorByWODataforDA?contractorCode=
    ${contractorCode}&workOrder=${workOrderNo}`;
    return this.httpClient.request<Contractor[]>(GLOBAL.HTTP_GET, url).pipe(
      map((items: any[]) => items.map((item: any) => this.adapter.toContractorResponse(item)))
    );
  }

  public getCompanies(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_COMPANY_DATA}?Search=&StatusOfIndustry=&ISActive=&SDate=&EDate=&EntityType=&Legalstatus=
    &State=&City=&Country=`;
    return this.httpClient.get<any[]>(url).pipe(map((response: any) => response.Data.Table));
  }

  public getShiftTypeGroups(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_SHIFTTYPEGROUP_DATA}?Search=&PlantCode=`;
    return this.httpClient.get<any[]>(url).pipe(map((response: any) => response.Data.Table));
  }

  getLicenses(contractorCode: any) {
    const url = `${GLOBAL.APIS.LICENSE.GET_LICENSE}?search=&sDate=&eDate=&selectedField=&filterType=&condition=&State_Code=
                &Status_Code=&Plant_Code=&IsActive=&NatureOfLicense_Code=&NatureOfWork_Code=&TotalAccupied=
                &Contractor_Code=${contractorCode}&SubContractor_Code=&WorkOrder_Code=&SubWorkOrder_Code=&isContractorType=&UserId=`;
    return this.httpClient.get(url).pipe(map((response: any) => response.Data.Table));
  }

  getWCPolicy(contractorCode: any) {
    const url = `${GLOBAL.APIS.REPORTS.GET_WC_POLICY}?search=&sDate=&eDate=&selectedField=&filterType=&condition=&State_Code=
                &Status_Code=&Plant_Code=&IsActive=&NatureOfWork_Code=&TotalAccupied=
                &Contractor_Code=${contractorCode}&SubContractor_Code=&WorkOrder_Code=&SubWorkOrder_Code=&isContractorType=&UserId=`;
    return this.httpClient.get(url).pipe(map((response: any) => response.Data.Table));
  }

  // public getPlants(companyCode: any, stateCode: any): Observable<any[]> {
  //   const url = `${GLOBAL.APIS.MASTER_DATA.GET_PLANTS}?Search=&Company=${companyCode}&StateCode=${stateCode}&City=&Country=&SDate=&EDate=
  //   &UnitType=&Ownership=&LocatedAt=&ISESICApplicable=`;
  //   return this.httpClient.get<any[]>(url).pipe(map((response: any) => response));
  // }

  public getPlants(companyCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_PLANTS}?Search=&Company=${companyCode}&State=&City=&Country=&SDate=&EDate=
    &UnitType=&Ownership=&LocatedAt=&ISESICApplicable=`;
    return this.httpClient.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }

  public getPlantsByCompany(companyCode: any, stateCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_PLANTS_BY_COMPANY}?Search=&Company=${companyCode}
    &UserId=${GLOBAL.USER.LOGGED_IN_USER}&StateCode=${stateCode}&City=&Country=&SDate=&EDate=
    &UnitType=&Ownership=&LocatedAt=&ISESICApplicable=`;
    return this.httpClient.get<any[]>(url).pipe(map((response: any) => response.Data.Table));
  }

  public getPlantsDataUserWise(companyCode: any, stateCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_PLANTS_BY_USERWISE}?Search=&Company=${companyCode}
    &UserId=${GLOBAL.USER.LOGGED_IN_USER}&StateCode=${stateCode}&City=&Country=&SDate=&EDate=
    &UnitType=&Ownership=&LocatedAt=&ISESICApplicable=`;
    return this.httpClient.get<any[]>(url).pipe(map((response: any) => response.Data.Table));
  }


  public getUnits(plantCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_UNITS}?Search=&PlantCode=${plantCode}`;
    return this.httpClient.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }

  public getDesignations(companyCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DESIGNATION}?Search=&companyCode=${companyCode}&GradeCode=&LevelCode=`;
    return this.httpClient.get<any[]>(url).pipe(
      map((response: any) => response));
  }

  public getMasterItemByCode(code: string): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_MASTERITEMLIST}?Search=&ShortCode=${code}`;
    return this.httpClient.get<any[]>(url).pipe(
      map((response: any) => this.adapter.convertResponse(response)));
  }
  public getDepartments(plantCode: any = '', unitCode: any = ''): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENTS}?Search=&PlantCode=${plantCode}&UnitCode=${unitCode}`;
    return this.httpClient.get<any[]>(url).pipe(
      map((response: any) => response));
  }

  public getSections(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_SECTION}?Search=`;
    return this.httpClient.get<any[]>(url).pipe(
      map((response: any) => response));
  }

  public getNatureOfWorks(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_NATUREOFWORKS}?Search=`;
    return this.httpClient.get<any[]>(url).pipe(
      map((response: any) => response));
  }

  public getShiftTypes(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_SHIFTTYPES}?Search=`;
    return this.httpClient.get<any[]>(url).pipe(
      map((response: any) => response));
  }


  public getShifts(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_SHIFTS}?Search=&PlantCode=&ShiftTypeGroupCode=`;
    return this.httpClient.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }

  public getSkills(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_SKILLS}?Search=&PlantCode=&ParentSkillCode=&TradeCode=`;
    return this.httpClient.get<any[]>(url).pipe(
      map((response: any) => response));
  }

  public addFilter(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.REPORTS.ADD_FILTER}?User_Code=${GLOBAL.USER.LOGGED_IN_USER}
    &Page_Code=${data.Page_Code}&FilterName=${data.FilterName}&JSONTextDetails=${data.JSONTextDetails}`;
    return this.httpClient.post(url, {}).pipe(
      map((response: any) => response));
  }

  public updateFilter(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.REPORTS.UPDATE_FILTER}?Report_Code=${data.Report_Code}&User_Code=${GLOBAL.USER.LOGGED_IN_USER}
    &Page_Code=${data.Page_Code}&FilterName=${data.FilterName}&JSONTextDetails=${data.JSONTextDetails}`;
    return this.httpClient.post(url, {}).pipe(
      map((response: any) => response));
  }

  public deleteFilter(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.REPORTS.DELETE_FILTER}?Report_Code=${data.Report_Code}&User_Code=${GLOBAL.USER.LOGGED_IN_USER}`;
    return this.httpClient.post(url, {}).pipe(
      map((response: any) => response));
  }

  public getALLSavedFilterDescripationUserWise(pagecode: number): Observable<any[]> {
    const url = `${GLOBAL.APIS.REPORTS.GET_FILTER_PAGEWISE}?User_Code=${localStorage.getItem('UserID')}&Page_Code=${pagecode}`;
    return this.httpClient.get(url).pipe(map((response: any) => this.adapter.convertResponse(response)
      .map((item: any) => this.adapter.parseJsonString(item))));
  }
  public getShiftGroupby(search: string): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA}?Search=${search}`;
    return this.httpClient.get(url).pipe(map(
      (response: any) => response.Data.Table.map((item: any) => this.adapter.parseJsonString(item))));
  }

  public natureOfWorkAndSkill(dataItem: { search: string }): Observable<any> {
    const model = dataItem;
    // tslint:disable-next-line: prefer-template
    //   return this.httpClient.post(`${GLOBAL.APIS.MAN_POWER.GET_NATURE_OF_WORK_AND_SKILL}` , model);
    // }
    return this.httpClient.get(GLOBAL.APIS.MAN_POWER.GET_NATURE_OF_WORK_AND_SKILL);

  }

  public deleteManPowerRequest(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MAN_POWER.DELETE_MAN_POWER_REQUEST}`;
    return this.httpClient.post(url, { UserId: localStorage.getItem('UserID'), ManpowerRequisiton_Code: data.ManpowerRequisiton_Code }).
      pipe(
        map((response: any) => response.Data));
  }

  public saveOTApprovalData(data: any[]): Observable<any> {
    const url = `${GLOBAL.APIS.MAN_POWER.SUBMIT_OT_APPROVAL}`;
    return this.httpClient.post<any>(url, data);
    // return of(this.getOTApprovalDataTestData());
  }
  public getOTApprovalData(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.MAN_POWER.GET_CLPMS_LIST_OT_APPROVAL_MM}`;
    return this.httpClient.post<any>(url, data);

    // return of(this.getOTApprovalDataTestData());
  }
  public getShortCodeList(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.MAN_POWER.SP_PLANTTOSECTION}`;
    return this.httpClient.post<any>(url, data);

    // return of(this.getOTApprovalDataTestData());
  }
  public getRequestLevels(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.MAN_POWER.SP_GET_REQUESTLEVEL_CODE}`;
    return this.httpClient.post<any>(url, data);
  }

  private getOTApprovalDataTestData(): any[] {
    return [
      {
        date: '14/5/2022',
        sections: [
          {
            name: 'Section 1',
            shifts: [
              {
                name: 'Shift 11'
              },
              {
                name: 'Shift 12'
              }
            ]
          },
          {
            name: 'Section 2',
            shifts: [
              {
                name: 'Shift 21'
              },
              {
                name: 'Shift 22'
              }
            ]
          }
        ]
      },
      {
        date: '15/5/2022',
        sections: [
          {
            name: 'Section 2',
            shifts: [
              {
                name: 'Shift 11'
              },
              {
                name: 'Shift 12'
              }
            ]
          },
          {
            name: 'Section 2',
            shifts: [
              {
                name: 'Shift 21'
              },
              {
                name: 'Shift 22'
              }
            ]
          }
        ]
      },
      {
        date: '15/5/2022',
        sections: [
          {
            name: 'Section 2',
            shifts: [
              {
                name: 'Shift 11'
              },
              {
                name: 'Shift 12'
              }
            ]
          },
          {
            name: 'Section 2',
            shifts: [
              {
                name: 'Shift 21'
              },
              {
                name: 'Shift 22'
              }
            ]
          }
        ]
      },
      {
        date: '15/5/2022',
        sections: [
          {
            name: 'Section 2',
            shifts: [
              {
                name: 'Shift 11'
              },
              {
                name: 'Shift 12'
              }
            ]
          },
          {
            name: 'Section 2',
            shifts: [
              {
                name: 'Shift 21'
              },
              {
                name: 'Shift 22'
              }
            ]
          }
        ]
      },
    ]
  }
}
