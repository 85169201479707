import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { GLOBAL } from '../app.globals';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class CompanyCommonsMasterService {
  baseApiUrl = 'http://localhost:59557/';

  constructor(private httpClient: HttpClient, private cookieService: CookieService) {

  }
  public getCompanyMasterData(): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_COMPANY_MASTER_DATA
      + '?search=&StatusOfIndustry=&EntityTypeCode=&LegalStatusCode=&StateCode=&CityCode=&Co');
  }
  // public getWorkOrdersByContractorCode(contractorCode: number, plantcode: number): Observable<any> {
  //   const url = `${GLOBAL.APIS.WORK_ORDER.GET_WORK_ORDER_BY_CONTRACTOR}?contractorcode=${contractorCode}&plantcode=${plantcode}`;
  //   return this.httpClient.get<any>(url).pipe(
  //     map((response: any) => response.Data));
  // }
  // getLicenses(contractorCode: any) {
  //   const url = `${GLOBAL.APIS.LICENSE.GET_LICENSE}?search=&sDate=&eDate=&selectedField=&filterType=&condition=&State_Code=
  //               &Status_Code=&Plant_Code=&IsActive=&NatureOfLicense_Code=&NatureOfWork_Code=&TotalAccupied=
  //               &Contractor_Code=${contractorCode}&SubContractor_Code=&WorkOrder_Code=&SubWorkOrder_Code=&isContractorType=&UserId=`;
  //   return this.httpClient.get(url).pipe(map((response: any) => response.Data.Table));
  // }

  getWCPolicy(contractorCode: any) {
    const url = `${GLOBAL.APIS.REPORTS.GET_WC_POLICY}?search=&sDate=&eDate=&selectedField=&filterType=&condition=&State_Code=
                &Status_Code=&Plant_Code=&IsActive=&NatureOfWork_Code=&TotalAccupied=
                &Contractor_Code=${contractorCode}&SubContractor_Code=&WorkOrder_Code=&SubWorkOrder_Code=&isContractorType=&UserId=`;
    return this.httpClient.get(url).pipe(map((response: any) => response.Data.Table));
  }
  public getContractors(excludeContractorCode: string = '', workOrderCode: any = ''): Observable<any[]> {
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_CONTRACTORS}?search=&sDate=&eDate=&selectedField=&isMain=&
    ExcludeContractor_Code=${excludeContractorCode}&workOrderCode=${workOrderCode}&contractor_Code=`;
    // console.log(url);
    return this.httpClient.get<any[]>(url).pipe(
      map((response: any) => response));
  }
  // public updateLabourwiseDeviationAllow(data: any): Observable<any> {
  //   const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_LabourwiseDeviationAllow`;
  //   return this.httpClient.post<any>(url, data).pipe(
  //     map((response: any) => response)
  //   );
  //   //  return of(this.totalPlantWiseData());
  // }
  // public addLabourwiseDeviationAllow(data: any): Observable<any> {
  //   const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_LabourwiseDeviationAllow`;
  //   return this.httpClient.post<any>(url, data).pipe(
  //     map((response: any) => response)
  //   );
  //   //  return of(this.totalPlantWiseData());
  // }
  // public getWorkOrdersByContractorCode(contractorCode: number, plantcode: number): Observable<any> {
  //   const url = `${GLOBAL.APIS.WORK_ORDER.GET_WORK_ORDER_BY_CONTRACTOR}?contractorcode=${contractorCode}&plantcode=${plantcode}`;
  //   return this.httpClient.get<any>(url).pipe(
  //     map((response: any) => response.Data));
  // }

  getLicenses(contractorCode: any) {
    const url = `${GLOBAL.APIS.LICENSE.GET_LICENSE}?search=&sDate=&eDate=&selectedField=&filterType=&condition=&State_Code=
                &Status_Code=&Plant_Code=&IsActive=&NatureOfLicense_Code=&NatureOfWork_Code=&TotalAccupied=
                &Contractor_Code=${contractorCode}&SubContractor_Code=&WorkOrder_Code=&SubWorkOrder_Code=&isContractorType=&UserId=`;
    return this.httpClient.get(url).pipe(map((response: any) => response.Data.Table));
  }



  public getPlantMasterData(): Observable<any> {

    const model = {
      Search: '',
      Company: '',
      State: '',
      City: '',
      Country: '',
      UnitType: '',
      OwnerShip: '',
      LocatedAt: '',
      IsESICApplicable: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_PLANT_MASTER_DATA}`, model).pipe(
      map((response: any) => response));
  }

  public getWorkOrdersByContractorCode(contractorCode: number, plantcode: number): Observable<any> {
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_WORK_ORDER_BY_CONTRACTOR}?contractorcode=${contractorCode}&plantcode=${plantcode}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => response.Data));
  }
  // tslint:disable-next-line: prefer-template
  //  return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_PLANT_MASTER_DATA
  // + '?search=&CompanyCode=&StateCode=&CityCode=&CountryCode=&UnitType=&Ownership=&LocatedAt=&ISESICApplicable=');
  // }

  public getShiftGroupMasterData(): Observable<any> {
    const model = {
      Search: '',
      Plant_Code: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_Shift_Group_MASTER_DATA}`, model).pipe(
      map((response: any) => response));
  }

  public getShiftGroupType(): Observable<any> {
    const model = {
      Search: '',
      Plant_Code: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.Shift_Group_Type}`, model).pipe(
      map((response: any) => response));
  }

  public getLabourDetails(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_EMP}`;
    return this.httpClient.post(url, data).pipe(
      map((response: any) => response));
  }

  public getEmployeewiseDetails(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_EMPLOYEEWISE_DETAILS}`;
    return this.httpClient.post(url, data).pipe(
      map((response: any) => response));
  }



  public getContractorwiseLabours(data: any): Observable<any[]> {
    /// use for lbaour filter bind
    //  @contractors VARCHAR(MAX) = NULL ,
    //     @subContractors VARCHAR(MAX) = NULL ,
    //     @plant_Code VARCHAR(MAX) = NULL ,
    //     @unit_Code VARCHAR(MAX) = NULL ,
    //     @UserId BIGINT
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_CONTRACTORWISE_LABOURS_DETAILS}`;
    return this.httpClient.post(url, data).pipe(
      map((response: any) => response));
  }


  public getContractorsList(): Observable<any> {
    const model = {
      search: '',
     
    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_CONTRACTORS_LIST}`, model).pipe(
      map((response: any) => response));
  }


  public getsiteshiftMasterData(): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SITE_SHIFT_GROUP_DATA + '?Search=');
  }

  public getUnitMasterData(plant): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_ALL_UNIT + '?search=&PlantCode=plant');
  }
  public getFinacialYearData(): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_FINACIAL_YEAR).pipe(
      map((response: any) => response));
  }
  public getAllFunctionBudgetData(): Observable<any> {
    // tslint:disable-next-line: ter-indent
    return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_FUNCTION_MASTER_DATA_FOR_GRID).pipe(
      map((response: any) => response));
    // const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}Get_FunctionBudgetMaster_Listing`;
    // this.http.get<any>(url).pipe(map((response: any) => response));
    // return this.httpClient.post(url, '');
  }
  // public getFunctionMasterData(): Observable<any> {
  //   // tslint:disable-next-line: prefer-template
  //   return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_FUNCTION_MASTER_DATA_FOR_GRID);
  // }






  getdeptMaster() {
    const queryString = $.param({
      Department_Code: '',
      Name: '',
    });


    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENTMASTERS}?${queryString}`,
      httpOptions
    );

  }

  getAllDeptData() {
    const queryString = $.param({
      Department_Code: '',
      Name: '',

    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENTMASTERS}?${queryString}`,
      httpOptions
    );

  }


  getAllHolidayMasterData() {
    const model = {
      Search: '',
      // sDate: '',
      // eDate: '',
      // Company_Code: '',
      // Plant_Code: ''
    };
    return this.httpClient.post(`${GLOBAL.APIS.MASTER_DATA.GET_HOLIDAY_DATA}`, model).pipe(
      map((response: any) => response));
  }


  getHolidayMaster() {
    const model = {
      Search: '',
      // sDate: '',
      // eDate: '',
      // Company_Code: '',
      // Plant_Code: ''
    };
    return this.httpClient.post(`${GLOBAL.APIS.MASTER_DATA.GET_HOLIDAY_DATA}`, model).pipe(
      map((response: any) => response));
  }

  public getShiftGroupTypes(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_SHIFTGROUPTYPES}?Search=`;
    return this.httpClient.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }
  getActiveLabourDetails(filterParm) {
    //   const model = {
    //     Company_Code: '',
    //     Plant_Code: '',
    //     Unit_Code: '',
    //     Department_Code: '',
    //     Section_Code: '',
    //     NatureOfWork_Code: '',
    //     // ShiftGroupType_Code: '',
    //     Shift_Code: '',
    //     IncludingSubContractor: '',
    //     Contractors: '',
    //     SubContractors: '',
    //     WorkOrder_Code: '',
    //     SubWorkOrder_Code: '',
    //     License_Code: '',
    //     SubLicense_Code: '',
    //     ESIC: '',
    //     SubESIC: '',
    //     WCPolicy: '',
    //     SubWCPolicy: '',
    //     Gender: '',
    //     Skill_Code: '',
    //     Trade_Code: '',
    //     Qulification_Code: '',
    //     TechQualification_Code: '',
    //     StreamQualification_Code: '',
    //     Religion_Code: '',
    //     Cast_Code: '',
    //   };
    //   return this.httpClient.post(`${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_All_ACTIVELABOUR_DATA}`, model).pipe(
    //     map((response: any) => response));
    // }

    const body = JSON.stringify(filterParm);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_All_ACTIVELABOUR_DATA}`,
      httpOptions
    );
  }
  public getContractorsbydefault(userId: any, roleCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_CONTRACTORSBYDEFAULT}?UserId=${userId}&RoleCode=${roleCode}`;
    // console.log(url);
    return this.httpClient.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }
  getDepartmentData(unit) {
    const model = {
      Unit_Code: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_DEPARTMENT_BY_UNIT}`, model).pipe(
      map((response: any) => response));
  }
  //  const queryString = $.param({
  //     UnitCode: JSON.stringify(unit)
  //   });
  //  return this.httpClient.request(
  //     GLOBAL.HTTP_GET,
  //    `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_DEPARTMENT_BY_UNIT}?${queryString}`,
  //     httpOptions
  //   );
  // }
  getSectionData() {
    const model = {
      Search: '',
      // ParentSectionCode: '',
      // PlantCode: '',
      DepartmentCode: '',
      // UnitCode: '',
      // CompanyCode: ''
    };

    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_SECTION_DATA}`, model).pipe(
      map((response: any) => response));

  }
  // tslint:disable-next-line: function-name
  CheckSectionRelationData(sectionCode, departmentcode, unitCode) {
    const queryString = $.param({
      UnitCode: JSON.stringify(unitCode),
      DepartmentCode: JSON.stringify(departmentcode),
      SectionCode: JSON.stringify(sectionCode),
      SubSectionLevelCode1: '',
      SubSectionLevelCode2: '',
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SECTION_RELATION_DATA}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  CheckSubSectionRelationData(sectionCode, subsecetionlevelcode1, departmentcode, unitCode) {
    const queryString = $.param({
      UnitCode: JSON.stringify(unitCode),
      DepartmentCode: JSON.stringify(departmentcode),
      SectionCode: JSON.stringify(sectionCode),
      SubSectionLevelCode1: JSON.stringify(subsecetionlevelcode1),
      SubSectionLevelCode2: '',
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SECTION_RELATION_DATA}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  CheckSubSection2RelationData(sectionCode, subsecetionlevelcode1, subsectionlevelcode2, departmentcode, unitCode) {
    const queryString = $.param({
      UnitCode: JSON.stringify(unitCode),
      DepartmentCode: JSON.stringify(departmentcode),
      SectionCode: JSON.stringify(sectionCode),
      SubSectionLevelCode1: JSON.stringify(subsecetionlevelcode1),
      SubSectionLevelCode2: JSON.stringify(subsectionlevelcode2),
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SECTION_RELATION_DATA}?${queryString}`,
      httpOptions
    );
  }
  /**
   * getDepartmentData
   * @author Sonal Prajapati
   */

  getSectionData1(parentCode, parentSectionCode, plant, department, unit, company) {
    const model = {
      Search: '',
      ParentSection_Code: '',
      Plant_Code: '',
      Department_Code: '',
      Unit_Code: '',
      Company_Code: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SECTION_DATA1}`, model).pipe(
      map((response: any) => response));
  }
  // const queryString = $.param({
  //    Search: '',
  //    ParentCode: JSON.stringify(parentCode),
  //    ParentSectionCode: JSON.stringify(parentSectionCode),
  //    PlantCode: JSON.stringify(plant),
  //    DepartmentCode: JSON.stringify(department),
  //    UnitCode: JSON.stringify(unit),
  //    CompanyCode: JSON.stringify(company)
  //  });
  //  return this.httpClient.request(
  //    GLOBAL.HTTP_GET,
  //    `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SECTION_DATA1}?${queryString}`,
  //    httpOptions
  //  );
  // }
  // tslint:disable-next-line: function-name
  GetShiftByPlant(parentCode) {
    const queryString = $.param({
      Search: '',
      PlantCode: JSON.stringify(parentCode),
      ShiftTypeGroupCode: ''
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.MAN_POWER.GET_SHIFT_MASTER_DATA}?${queryString}`,
      httpOptions
    );
  }
  public getShiftGroup(): Observable<any> {
    const model = {
      Search: '',
      Plant_Code: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_SHIFT_GROUP_LIST}`, model).pipe(
      map((response: any) => response));
  }
  // tslint:disable-next-line: prefer-template
  // return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_SHIFT_GROUP_LIST + '?search=&CompanyCode=&PlantCode=');
  // }

  // public getCountryMasterData(): Observable<any> {
  //   // tslint:disable-next-line: prefer-template
  //   const model = {
  //     Search: '',
  //   };
  //   return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_Country_Master_LIST}`, model).pipe(
  //     map((response: any) => response));
  // }
  // return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_Country_Master_LIST + '?Search=');
  // }
  // public getStateMasterData(): Observable<any> {
  //   const model = {
  //     Search: '',
  //     Country_Code: '',
  //   };
  //   return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_State_Master_LIST}`, model).pipe(
  //     map((response: any) => response));
  // }
  // tslint:disable-next-line: prefer-template
  // return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_State_Master_LIST + '?Search=&CountryCode=');
  // }

  public getStateMasterData(): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_State_Master_LIST + '?Search=&CountryCode=');
  }
  public getDistrictMasterData(): Observable<any> {
    const model = {
      Search: '',
      StateCode: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_District_Master_LIST}`, model).pipe(
      map((response: any) => response));
  }
  // tslint:disable-next-line: prefer-template
  // return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_District_Master_LIST + '?Search=&StateCode=');
  // }
  public getCityMasterData(): Observable<any> {
    const model = {
      Search: '',
      District_Code: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_City_Master_LIST}`, model).pipe(
      map((response: any) => response));
  }
  // tslint:disable-next-line: prefer-template
  //   return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_City_Master_LIST + '?Search=&DistrictCode=');
  // }

  public getPlant(): Observable<any> {


    const model = {
      Search: '',
      Company: '',
      State: '',
      City: '',
      Country: '',
      UnitType: '',
      OwnerShip: '',
      LocatedAt: '',
      IsESICApplicable: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_PLANT_LIST}`, model).pipe(
      map((response: any) => response));
  }

  // tslint:disable-next-line: prefer-template
  // return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_PLANT_LIST + '?Search=&PlantCode=&ParentSkillCode=&TradeCode=');
  // }
  getact() {
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_ACT}`,
      httpOptions
    );
  }


  getAllShift() {
    const model = {

    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_ShiftMaster}`, model).pipe(
      map((response: any) => response));
  }
  //   const queryString = $.param({
  //   });
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_ShiftMaster}?${queryString}`,
  //     httpOptions
  //   );
  // }
  getUnit() {
    const queryString = $.param({});
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_UNIT_LIST}?${queryString}`,
      httpOptions
    );
  }

  getAllShiftGroup() {
    const model = {
    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_ShiftGroupMaster}`, model).pipe(
      map((response: any) => response));
  }
  //  const queryString = $.param({
  //   });
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_ShiftGroupMaster}?${queryString}`,
  //     httpOptions
  //   );
  // }
  getAllSiteShiftGroup() {
    const queryString = $.param({
      Search: ''
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_SiteShiftGroupMaster}?${queryString}`,
      httpOptions
    );
  }
  addShift(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.SAVE_Shift}`,
      httpOptions
    );
  }
  addShiftGroup(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.SAVE_Shift_Group}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  InsertSiteShiftGroupData(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.SAVE_Site_Shift_Group}`,
      httpOptions
    );
  }
  addSectionPlantMasterData(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.INSERT_SECTION_PLANT_MASTER_DATA}`,
      httpOptions
    );
  }
  addHolidayMasterData(contractorParams): Observable<Object> {
    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.ADD_HOLIDAY_MASTER}`,
      httpOptions
    );
  }
  addPlantMasterData(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.ADD_PLANT_MASTER}`,
      httpOptions
    );
  }

  public addlegislationMasterData(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.ADD_LEGISLATION_MASTER}`;
    return this.httpClient.post(url, data);
  }
  addFunctionBudget(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.ADD_FUNCTION_BUDGET_MASTER}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  UpdateShift(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.Update_Shift}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  UpdatePlantMaster(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.UPDATE_PLANT_MASTER}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  UpdateShiftGroup(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.Update_Shift_GRoup}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  UpdateSiteShiftGroup(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.Update_Site_Shift_GRoup}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  GetShiftDataForEdit(shiftCode) {

    const queryString = $.param({
      shiftCode
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_Shift_ID}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  DeleteSiteShiftGroupData(siteshiftgroupCode) {

    const queryString = $.param({
      siteshiftgroupCode
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.DELETE_SITE_SHIFT_GROUP}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  GetSiteShiftGroupDataForEdit(siteShiftGroupCode) {

    const queryString = $.param({
      siteShiftGroupCode
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_Site_Shift_GRoupDAta_FOR_EDIT}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  GetShiftGroupProfile(shiftgroupCode) {

    const queryString = $.param({
      shiftgroupCode
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_Shift_GRoup_ID}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  GetPlantProfile(plantcode) {

    const queryString = $.param({
      plantcode
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_PLANT_MASTER_DATAFOR_EDIT}?${queryString}`,
      httpOptions
    );
  }


  // tslint:disable-next-line: function-name
  // getCompanyProfile(companycode) {

  //   const queryString = $.param({
  //     companycode
  //   });
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_COMPANY_MASTER_DATAFOR_EDIT}?${queryString}`,
  //     httpOptions
  //   );
  // }

  public getCompanyProfile(companycode): Observable<any> {
  
    const model = {
      UserID: localStorage.getItem('UserID'),
      Company_Code: companycode,
      Sys_Code: null,
      Name: null,
      ShortCode: null,
      ShortName: null,
      Organization_Code: null,
      EntityTypeMasterList_Code: null,
      Email: null,
      Address1: null,
      Address2: null,
      Address3: null,
      ZipCode: null,
      Country_Code: null,
      State_Code: null,
      District_Code: null,
      City_Code: null,
      PostOffice_Code: null,
      StatementType: 'Edit'

    };

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_CompanyMaster`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }


  public companyCheckShortCode(shortcode, companycode): Observable<any> {
    const model = {

      UserID: localStorage.getItem('UserID'),
      Company_Code: companycode,
      Sys_Code: null,
      Name: null,
      ShortCode: shortcode,
      ShortName: null,
      Organization_Code: null,
      EntityTypeMasterList_Code: null,
      Email: null,
      Address1: null,
      Address2: null,
      Address3: null,
      ZipCode: null,
      Country_Code: null,
      State_Code: null,
      District_Code: null,
      City_Code: null,
      PostOffice_Code: null,
      StatementType: 'checkShortCode'
    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_CompanyMaster`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public companyCheckShortName(shortname, companycode): Observable<any> {
    const model = {

      UserID: localStorage.getItem('UserID'),
      Company_Code: companycode,
      Sys_Code: null,
      Name: null,
      ShortCode: null,
      ShortName: shortname,
      Organization_Code: null,
      EntityTypeMasterList_Code: null,
      Email: null,
      Address1: null,
      Address2: null,
      Address3: null,
      ZipCode: null,
      Country_Code: null,
      State_Code: null,
      District_Code: null,
      City_Code: null,
      PostOffice_Code: null,
      StatementType: 'checkShortName'
    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_CompanyMaster`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  // GetUnitProfile(Unit_Code, StatementType) {
  //
  //   const queryString = $.param({
  //     Unit_Code,
  //     StatementType
  //   });
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}?${queryString}`,
  //     httpOptions
  //   );
  // }
  public getUnitProfile(unit): Observable<any> {
    const model = {
      UserID: localStorage.getItem('UserID'),
      Unit_Code: unit,
      Plant_Code: null,
      Name: null,
      ShortCode: null,
      ShortName: null,
      StatementType: 'Edit'

    };

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_InsertUnitMaster`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public getHalfdayPolicy(halfdaycode): Observable<any> {
    const model = {
      UserID: localStorage.getItem('UserID'),
      // HalfDayPolicy_Code: halfdaycode,
      // Sys_Code: null,
      Company_Code: null,
      Plant_Code: null,
      Name: null,
      ShortCode: null,
      Version: null,
      IsLabour: null,
      ApplicableDate: null,
      ValiduptoDate: null,
      IsDefault: null,
      IsCompany: null,
      MinHalfDayDuration: null,
      MinTimeForAbsent: null,
      WeekOffSufixPrefixAbsent: null,
      HolidaySufixPrefixAbsent: null,
      StatementType: 'Edit'
    };

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_HalfDayPolicy`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public getHalfdayPolicyProfile(halfdaycode): Observable<any> {
    const model = {
      UserID: localStorage.getItem('UserID'),
      HalfDayPolicy_Code: halfdaycode,
      Sys_Code: null,
      Company_Code: null,
      Plant_Code: null,
      Name: null,
      ShortCode: null,
      Version: null,
      IsLabour: null,
      ApplicableDate: null,
      ValiduptoDate: null,
      IsDefault: null,
      IsCompany: null,
      MinHalfDayDuration: null,
      MinTimeForAbsent: null,
      WeekOffSufixPrefixAbsent: null,
      HolidaySufixPrefixAbsent: null,
      StatementType: 'Edit'
    };

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_HalfDayPolicy`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }
  public getAbsentPolicyProfile(absentcode): Observable<any> {
    const model = {
      UserID: localStorage.getItem('UserID'),
      AbsentPolicy_Code: absentcode,
      Company_Code: null,
      Plant_Code: null,
      Name: null,
      ShortCode: null,
      Version: null,
      IsLabour: null,
      ApplicableDate: null,
      ValiduptoDate: null,
      IsDefault: null,
      IsCompany: null,
      MinTimeForAbsent: null,
      WeekOffSingleSideAbsent: null,
      WeekOffBothSideAbsent: null,
      HolidaySingleSideAbsent: null,
      HolidayBothSideAbsent: null,
      CheckLateIn: null,
      MaxAllowPerMonthLateIn: null,
      IsLateInContinues: null,
      LateInMarkAbsent: null,
      CheckEarlyOut: null,
      MaxAllowPerMonthEarlyOut: null,
      IsEarlyOutContinued: null,
      EarlyOutMarkAbsent: null,

      StatementType: 'Edit'

    };

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_AbsentPolicy
    `;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public getEarlyGoingPolicyProfile(earlycode): Observable<any> {
    const model = {
      UserID: localStorage.getItem('UserID'),
      EarlyOutPolicy_Code: earlycode,
      Company_Code: null,
      Plant_Code: null,
      Name: null,
      ShortCode: null,
      Version: null,
      IsLabour: null,
      ApplicableDate: null,
      ValiduptoDate: null,
      IsDefault: null,
      IsCompany: null,
      MaxEarlyOut: null,
      MaxEarlyOutAllow: null,
      StatementType: 'Edit',
      IsActive: false
    };

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_EarlyOutPolicy`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public getLateInPolicyProfile(latecode): Observable<any> {
    const model = {
      UserID: localStorage.getItem('UserID'),
      LateInPolicy_Code: latecode,
      Sys_Code: null,
      Company_Code: null,
      Plant_Code: null,
      Name: null,
      ShortCode: null,
      Version: null,
      IsLabour: null,
      ApplicableDate: null,
      ValiduptoDate: null,
      IsDefault: null,
      IsCompany: null,
      MaxLateOut: null,
      IsApplicableLateOut: null,
      MaxLateIn: null,
      MaxLateInAllow: null,
      MaxLateOutAllow: null,
      StatementType: 'Edit'

    };

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_LateInPolicy`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public getOTPolicyProfile(otcode): Observable<any> {
    const model = {
      UserID: localStorage.getItem('UserID'),
      OTPolicy_Code: otcode,
      Company_Code: null,
      Plant_Code: null,
      Name: null,
      ShortCode: null,
      Version: null,
      IsLabour: null,
      ApplicableDate: null,
      ValiduptoDate: null,
      IsDefault: null,
      IsCompany: null,
      IsApplicable: null,
      MinTimeForOT: null,
      MaxTimeForOT: null,
      EarlyCommingAdd: null,
      StatementType: 'Edit'

    };

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_OTPolicy`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public getWeekoffPolicyProfile(weekoffcode): Observable<any> {
    const model = {
      UserID: localStorage.getItem('UserID'),
      WeekOFFPolicy_Code: weekoffcode,
      Company_Code: null,
      Plant_Code: null,
      Name: null,
      ShortCode: null,
      Version: null,
      IsLabour: null,
      ApplicableDate: null,
      ValiduptoDate: null,
      IsDefault: null,
      IsCompany: null,
      FirstWeek1: null,
      FirstWeek2: null,
      IsPresentOTWeek1: null,
      SecoundWeek1: null,
      SecoundWeek2: null,
      IsPresentOTSWeek2: null,
      ThirdWeek1: null,
      ThirdWeek2: null,
      IsPresentOTTWeek3: null,
      FourthWeek1: null,
      FourthWeek2: null,
      IsPresentOTFWeek4: null,
      FifthWeek1: null,
      FifthWeek2: null,
      IsPresentOTFTWeek5: null,
      StatementType: 'Edit'

    };

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_WeekOFFPolicy`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public getCOFFPolicyProfile(coffcode): Observable<any> {
    const model = {
      UserID: localStorage.getItem('UserID'),
      COFFPolicy_Code: coffcode,
      Sys_Code: null,
      Company_Code: null,
      Plant_Code: null,
      Name: null,
      ShortCode: null,
      Version: null,
      IsLabour: null,
      ApplicableDate: null,
      ValiduptoDate: null,
      IsDefault: null,
      IsCompany: null,
      MinOTRequired: null,
      ValidityType: null,
      COFFMinForHalfDay: null,
      COFFMinForFullDay: null,
      IsAutoAuthorize: null,
      StatementType: 'Edit'

    };

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_COFFPolicy`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public getLunchPolicyProfile(lunchcode): Observable<any> {
    const model = {
      UserID: localStorage.getItem('UserID'),
      LunchPolicy_Code: lunchcode,
      Sys_Code: null,
      Company_Code: null,
      Plant_Code: null,
      Name: null,
      ShortCode: null,
      Version: null,
      IsLabour: null,
      ApplicableDate: null,
      ValiduptoDate: null,
      IsDefault: null,
      IsCompany: null,
      IsLunchTimeApplicable: null,
      ConsiderLunchTime: null,
      AllowBeforeLunchIn: null,
      AllowAfterLunchIn: null,
      AllowBeforeLunchOut: null,
      AllowAfterLunchOut: null,
      AddBLateInTotalEOut: null,
      AddBEOutInTotalLateIn: null,
      StatementType: 'Edit'

    };

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_LunchPolicy`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  getSectionPlantMasterLsiting(): Observable<any> {
    const model = {
      UserId: localStorage.getItem('UserID'),
      PlantCode: null,
      UnitCode: null,
      DepartmentCode: null
    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENT_PLANT_UNIT_SECTION_TABLE}`;
    return this.httpClient.post(url, model).pipe(
      map((response: any) => response));
  }
  getCountry() {
    const model = {
      Search: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_COUNTRY_LIST}`, model).pipe(
      map((response: any) => response));
  }

  getOrganization() {
    const model = {
      Search: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.MASTER_DATA.GET_ORGANIZATION_DETAILS}`, model).pipe(
      map((response: any) => response));
  }
  // return this.httpClient
  //  .request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.USER_MANAGEMENT.GET_COUNTRY_DATA}?Search=`,
  //    httpOptions
  //  );
  // }
  getStatesByCountryId(country) {
    let cc: number;
    if (country === '') {
      cc = 100;
    } else {
      cc = country;
    }
    const model = {
      Search: '',
      Country_Code: cc,
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_STATES_OF_COUNTRY}`, model).pipe(
      map((response: any) => response));
  }

  getDistrictsByState(state) {
    const model = {
      // Search: '',
      State_Code: JSON.stringify(state),
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_DISTRICTS_OF_STATES}`, model).pipe(
      map((response: any) => response));
  }

  getCitiesByDistrict(district) {
    const model = {
      // Search: '',
      District_Code: JSON.stringify(district),
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_CITYS_OF_DISTRICT}`, model).pipe(
      map((response: any) => response));
  }

  getPOsByCity(city) {
    const model = {
      Search: '',
      City_Code: JSON.stringify(city),
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_PO_BY_CITY}`, model).pipe(
      map((response: any) => response));
  }


  // tslint:disable-next-line: function-name
  CheckPlant(name, plantcode) {

    const queryString = $.param({
      Name: name,
      PlantCode: plantcode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_PLANT_NAME}?${queryString}`,
      httpOptions
    );
  }

  public checkUnit(plantcode, name, unitcode): Observable<any> {
    const model = {
      UserID: localStorage.getItem('UserID'),
      Unit_Code: unitcode,
      Plant_Code: plantcode,
      Name: name,
      ShortCode: null,
      ShortName: null,
      StatementType: 'ChkUnit'

    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_InsertUnitMaster`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }
  public getCheckShortCode3(shortcode, halfdaycode): Observable<any> {
    const model = {

      UserID: localStorage.getItem('UserID'),
      HalfDayPolicy_Code: halfdaycode,
      Company_Code: null,
      Plant_Code: null,
      Name: null,
      ShortCode: shortcode,
      Version: null,
      IsLabour: null,
      ApplicableDate: null,
      ValiduptoDate: null,
      IsDefault: null,
      IsCompany: null,
      MinHalfDayDuration: null,
      MinTimeForAbsent: null,
      WeekOffSufixPrefixAbsent: null,
      HolidaySufixPrefixAbsent: null,
      StatementType: 'checkShortCode'
    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_HalfDayPolicy`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public getCheckShortCode5(shortcode, coffcode): Observable<any> {
    const model = {

      UserID: localStorage.getItem('UserID'),
      COFFPolicy_Code: coffcode,
      Sys_Code: null,
      Company_Code: null,
      Plant_Code: null,
      Name: null,
      ShortCode: shortcode,
      Version: null,
      IsLabour: null,
      ApplicableDate: null,
      ValiduptoDate: null,
      IsDefault: null,
      IsCompany: null,
      MinOTRequired: null,
      ValidityType: null,
      COFFMinForHalfDay: null,
      COFFMinForFullDay: null,
      IsAutoAuthorize: null,
      StatementType: 'checkShortCode'
    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_COFFPolicy`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public getCheckShortCode6(shortcode, lunchcode): Observable<any> {
    const model = {

      UserID: localStorage.getItem('UserID'),
      LunchPolicy_Code: lunchcode,
      Sys_Code: null,
      Company_Code: null,
      Plant_Code: null,
      Name: null,
      ShortCode: shortcode,
      Version: null,
      IsLabour: null,
      ApplicableDate: null,
      ValiduptoDate: null,
      IsDefault: null,
      IsCompany: null,
      IsLunchTimeApplicable: null,
      ConsiderLunchTime: null,
      AllowBeforeLunchIn: null,
      AllowAfterLunchIn: null,
      AllowBeforeLunchOut: null,
      AllowAfterLunchOut: null,
      AddBLateInTotalEOut: null,
      AddBEOutInTotalLateIn: null,
      StatementType: 'checkShortCode'
    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_LunchPolicy`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public getCheckShortCode7(shortcode, weekoffcode): Observable<any> {
    const model = {

      UserID: localStorage.getItem('UserID'),
      WeekOFFPolicy_Code: weekoffcode,
      Sys_Code: null,
      Company_Code: null,
      Plant_Code: null,
      Name: null,
      ShortCode: shortcode,
      Version: null,
      IsLabour: null,
      ApplicableDate: null,
      ValiduptoDate: null,
      IsDefault: null,
      IsCompany: null,
      FirstWeek1: null,
      FirstWeek2: null,
      IsPresentOTWeek1: null,
      SecoundWeek1: null,
      SecoundWeek2: null,
      IsPresentOTSWeek2: null,
      ThirdWeek1: null,
      ThirdWeek2: null,
      IsPresentOTTWeek3: null,
      FourthWeek1: null,
      FourthWeek2: null,
      IsPresentOTFWeek4: null,
      FifthWeek1: null,
      FifthWeek2: null,
      IsPresentOTFTWeek5: null,
      SixthWeek1: null,
      SixthWeek2: null,
      IsPresentOTSIWeek6: null,
      StatementType: 'checkShortCode'
    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_WeekOFFPolicy`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public checkHalfdayPolicy(plantcode, halfdaycode): Observable<any> {
    const model = {
      UserID: localStorage.getItem('UserID'),
      HalfDayPolicy_Code: halfdaycode,
      Company_Code: null,
      Plant_Code: plantcode,
      Name: null,
      ShortCode: null,
      Version: null,
      IsLabour: null,
      ApplicableDate: null,
      ValiduptoDate: null,
      IsDefault: null,
      IsCompany: null,
      MinHalfDayDuration: null,
      MinTimeForAbsent: null,
      WeekOffSufixPrefixAbsent: null,
      HolidaySufixPrefixAbsent: null,
      StatementType: 'ChkPolicy'

    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_HalfDayPolicy`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public checkMachineIP(machineip, machinecode): Observable<any> {
    const model = {

      UserID: localStorage.getItem('UserID'),
      // Company_Code: null,
      Machine_Code: machinecode,
      Plant_Code: null,
      Unit_Code: null,
      State_Code: null,
      Department_Code: null,
      Parent_Code: null,
      Location: null,
      ShortCode: null,
      ShortName: null,
      MachineType: null,
      SerialNo: null,
      ControllerNo: null,
      ControllerName: null,
      MachineIP: machineip,
      DefaultGateway: null,
      IsInOut: null,
      DuplicatePunchPeriod: null,
      Password: null,
      DoorLockDelay: null,
      VerificationMode: null,
      Port: null,
      MachineUseFor: null,
      MachineAttachedWith: null,
      IsMaster: null,
      IsSecondary: null,
      StatementType: 'checkMachineIP'
    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_MachineMaster`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }
  public chkMachineName(name, machinecode): Observable<any> {
    const model = {

      UserID: localStorage.getItem('UserID'),
      // Company_Code: null,
      Machine_Code: machinecode,
      Plant_Code: null,
      Unit_Code: null,
      State_Code: null,
      Department_Code: null,
      Parent_Code: null,
      Location: null,
      ShortCode: null,
      ShortName: null,
      MachineType: null,
      SerialNo: null,
      ControllerNo: null,
      ControllerName: name,
      MachineIP: null,
      DefaultGateway: null,
      IsInOut: null,
      DuplicatePunchPeriod: null,
      Password: null,
      DoorLockDelay: null,
      VerificationMode: null,
      Port: null,
      MachineUseFor: null,
      MachineAttachedWith: null,
      IsMaster: null,
      IsSecondary: null,
      StatementType: 'chkMachineName'
    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_MachineMaster`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public checkLatePolicy(plantcode, latecode): Observable<any> {
    const model = {
      UserID: localStorage.getItem('UserID'),
      LateInPolicy_Code: latecode,
      Company_Code: null,
      Plant_Code: plantcode,
      Name: null,
      ShortCode: null,
      Version: null,
      IsLabour: null,
      ApplicableDate: null,
      ValiduptoDate: null,
      IsDefault: null,
      IsCompany: null,
      MaxLateOut: null,
      IsApplicableLateOut: null,
      MaxLateIn: null,
      MaxLateInAllow: null,
      MaxLateOutAllow: null,
      StatementType: 'ChkPolicy'

    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_LateInPolicy`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public addAssociateAccidentData(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_AccidentDetails`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
  }

  public getAccidentProfile(accidentcode): Observable<any> {
    const model = {
      UserID: localStorage.getItem('UserID'),
      Accident_Code: accidentcode,
      DateOfNotice: null,
      TimeOfNotice: null,
      Contractor_Code: null,
      ULC: null,
      //GatePassNo: null,
      AccidentPlace: null,
      AccidentDateTime: null,
      CauseOfInjury: null,
      NatureOfInjury: null,
      InjuryPersonName: null,
      PersonName: null,
      Address: null,
      Occupation: null,
      EntryPersonName: null,
      EntryPersonAddress: null,
      Designation: null,
      WitnessName1: null,
      WitnessAddress1: null,
      WitnessOccupation1: null,
      WitnessName2: null,
      WitnessAddress2: null,
      WitnessOccupation2: null,
      Remarks: null,
      StatementType: 'Edit'

    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_AccidentDetails`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public UpdateAssociateAccidentData(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_AccidentDetails`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
  }




  public addCompanyMasterData(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_CompanyMaster`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public UpdateCompanyMaster(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_CompanyMaster`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }


  // public UpdateCompanyMaster(data: any): Observable<any> {

  //   const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_CompanyMaster`;
  //   return this.httpClient.post<any>(url, data).pipe(
  //     map((response: any) => response)
  //   );
  //   //  return of(this.totalPlantWiseData());
  // }

  public getCompanyData(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_CompanyMaster`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public associateGetInsertUpdate(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_AccidentDetails`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }
  // tslint:disable-next-line: function-name
  CheckShortCode(shortcode, plantcode) {

    const queryString = $.param({
      ShortCode: shortcode,
      PlantCode: plantcode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_PLANT_SHORT_CODE}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  CheckShortName(shortname, plantcode) {

    const queryString = $.param({
      Name: shortname,
      PlantCode: plantcode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_PLANT_SHORT_NAME}?${queryString}`,
      httpOptions
    );
  }

  // tslint:disable-next-line: function-name
  CheckSiteShiftGroupName(name, siteshiftcodecode) {

    const queryString = $.param({
      Name: name,
      SiteShiftGroupCode: siteshiftcodecode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SITESHIFTNAME_NAME}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  CheckSiteshiftShortCode(shortcode, siteshiftcodecode) {

    const queryString = $.param({
      ShortCode: shortcode,
      SiteShiftGroupCode: siteshiftcodecode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SITESHIFTNAME_SHORT_CODE}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  ChecksiteshiftShortName(shortname, siteshiftcodecode) {

    const queryString = $.param({
      ShortName: shortname,
      SiteShiftGroupCode: siteshiftcodecode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SITESHIFTNAME_SHORT_NAME}?${queryString}`,
      httpOptions
    );
  }


  // tslint:disable-next-line: function-name
  CheckShiftGroupName(name, shiftcodecode) {

    const queryString = $.param({
      Name: name,
      ShiftGroupCode: shiftcodecode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SHIFGROUPNAME_NAME}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  CheckshiftgroupShortCode(shortcode, shiftcodecode) {

    const queryString = $.param({
      ShortCode: shortcode,
      ShiftGroupCode: shiftcodecode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SHIFGROUPNAME_SHORT_CODE}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  CheckshiftgroupShortName(shortname, shiftcodecode) {

    const queryString = $.param({
      ShortName: shortname,
      ShiftGroupCode: shiftcodecode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SHIFGROUPNAME_SHORT_NAME}?${queryString}`,
      httpOptions
    );
  }

  // tslint:disable-next-line: function-name
  CheckShiftName(name, shiftcode) {

    const queryString = $.param({
      Name: name,
      ShiftCode: shiftcode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SHIFT_NAME}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  CheckshiftShortCode(shortcode, shiftcode) {

    const queryString = $.param({
      ShortCode: shortcode,
      ShiftCode: shiftcode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SHIFT_SHORT_CODE}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  CheckshiftShortName(shortname, shiftcode) {

    const queryString = $.param({
      ShortName: shortname,
      ShiftCode: shiftcode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SHIFT_SHORT_NAME}?${queryString}`,
      httpOptions
    );
  }
  public getsubdatafromparent(parentcode): Observable<any> {
    const model = {
      // Search: '',
      Parent_Code: parentcode,
    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_SUBPARENT_DATA}`, model).pipe(
      map((response: any) => response));
  }
  // tslint:disable-next-line: function-name
  getMasterParentData(): Observable<any[]> {
    const model = {
    };
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_MASTER_PARENT_DATA}`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }
  // tslint:disable-next-line: function-name
  getlegislationProfile(legislationCode) {

    const model = {
      Legislation_Code: legislationCode
    };
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_LEGISLATION_MASTER_DATAFOR_EDIT}`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));

  }
  // Get Contractor code
  getDocumentTypes() {
    return this.httpClient.get(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_DOC_TYPES);
  }

  public getUnitwiseDetails(data: any): Observable<any> {


    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_InsertUnitMaster`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  // -----------------  Policy Add/Update Services -------------------- //
  public getListOfPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Listing_Attendance_Policies`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }
  public getLunchPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_LunchPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public getWeekoffPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_WeekOFFPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public getCOFFPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_COFFPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public getAbsentPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_AbsentPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public getEarlyGoingPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_EarlyOutPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public getLateInPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_LateInPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public getHalfDayPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_HalfDayPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public getOTPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_OTPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public getAddOTPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_OTPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public getUpdateOTPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_OTPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public getAddWeekoffPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_WeekOFFPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public getUpdateWeekoffPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_WeekOFFPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public getAddLunchPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_LunchPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public getUpdateLunchPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_LunchPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public getAddHalfDayPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_HalfDayPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public getUpdateHalfDayPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_HalfDayPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }


  public getAddAbsentPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_AbsentPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public getUpdateAbsentPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_AbsentPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public getAddEarlyGoingPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_EarlyOutPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }
  public getUpdateEarlyGoingPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_EarlyOutPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }
  // const queryString = $.param({
  // Search: '',
  //  });
  // return this.httpClient.request(
  // GLOBAL.HTTP_GET,
  //  `${GLOBAL.APIS.MAN_POWER.GET_NATURE_OF_WORK_MASTER_DATA}?${queryString}`,
  // httpOptions
  // );
  // }

  public getAddLateInPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_LateInPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public getUpdateLateInPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_LateInPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public getAddCOFFPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_COFFPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }
  public getUpdateCOFFPolicy(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_COFFPolicy`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  // public deleteEarlyOutProfile(EarlyOutPolicy_Code) {

  //   const queryString = $.param({
  //     EarlyOutPolicy_Code
  //   });
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}?${queryString}`,
  //     httpOptions
  //   );
  // }

  // public deleteEarlyOutProfile(earlycode): Observable<any> {
  //
  //   const model = {
  //     UserID: localStorage.getItem('UserID'),
  //     EarlyOutPolicy_Code: earlycode,
  //     Sys_Code: null,
  //     Company_Code: null,
  //     Plant_Code: null,
  //     Name: null,
  //     ShortCode: null,
  //     Version: null,
  //     IsLabour: null,
  //     ApplicableDate: null,
  //     ValiduptoDate: null,
  //     IsDefault: null,
  //     IsCompany: null,
  //     MaxEarlyOut: null,
  //     IsApplicableEarlyIn: null,
  //     MaxEarlyIn: null,
  //     MaxEarlyInAllow: null,
  //     MaxEarlyOutAllow: null,
  //     StatementType: 'Edit'

  //   };

  //   const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_EarlyOutPolicy`;
  //   return this.httpClient.post(url, model).
  //     pipe(
  //       map((response: any) => response));
  // }

  public addUnitMasterData(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_InsertUnitMaster`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }


  public updateUnitMaster(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_InsertUnitMaster`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }


  public updateMasterItemListData(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.INSERT_MASTER_ITEM_DATA}`;
    return this.httpClient.post(url, data).pipe(
      map((response: any) => response));
  }

  public addMasterItemListData(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.INSERT_MASTER_ITEM_DATA}`;
    return this.httpClient.post(url, data).pipe(
      map((response: any) => response));
  }
  getAllNatureOfWork() {
    const queryString = $.param({
      Search: '',
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.MAN_POWER.GET_NATURE_OF_WORK_MASTER_DATA}?${queryString}`,
      httpOptions
    );
  }

  public addNatureOfWorkMaster(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.INSERT_UPDATE_NATURE_OF_WORK_DATA}`;
    return this.httpClient.post(url, data).pipe(
      map((response: any) => response));
  }
  // tslint:disable-next-line: function-name
  GetNatureOfWorkEdit(natureofworkcode) {
    const model = {
      NatureOfWork_Code: natureofworkcode
    };
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_DATA_BY_NATUREOFWORK_CODE}`;
    return this.httpClient.post(url, model).pipe(
      map((response: any) => response));
  }

  // tslint:disable-next-line: function-name
  ChecknatureofworkName(name, natureofworkcode) {

    const queryString = $.param({
      natureofworkcode,
      Name: name
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_NATURE_OF_WORK_NAME}?${queryString}`,
      httpOptions
    );
  }
  getAllData(): Observable<any> {
    const model = {
    };
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_ALL_GAZZATE_DATA}`;
    return this.httpClient.post(url, model).pipe(
      map((response: any) => response));
  }
  getLegislationData(): Observable<any> {
    const model = {
    };
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_LEGISLATION_DATA}`;
    return this.httpClient.post(url, model).pipe(
      map((response: any) => response));
  }
  getLegislationforgrid(): Observable<any> {
    const model = {
    };
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_LEGISLATION_MASTER_DATA}`;
    return this.httpClient.post(url, model).pipe(
      map((response: any) => response));
  }
  getrulesByactData(code): Observable<any> {
    const model = {
      code
    };
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_RULES_BY_ACT_DATA}`;
    return this.httpClient.post(url, model).pipe(
      map((response: any) => response));
  }
  // public addgazzetenotification(data: any): Observable<any> {

  //   const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.INSERT_UPDATE_GAZZETE_NOTIFICATION}`;
  //   return this.httpClient.post(url, data);
  // }
  // // tslint:disable-next-line: function-name
  // GetholidayProfile(holidayCode) {

  //   const queryString = $.param({
  //     holidayCode
  //   });
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_HOLIDAY_BY_ID}?${queryString}`,
  //     httpOptions
  //   );
  // }

  // tslint:disable-next-line: function-name
  Updatholiday(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.UPDATE_HOLIDAY_MASTER}`,
      httpOptions
    );
  }
  updateAccessInformation(data): Observable<Object> {
    const userId = new URLSearchParams(this.cookieService.get('OceanSystem')).get('UserID');
    const body = new HttpParams().set('Plant_Code', data.plantId ? data.plantId : '')
      .set('ErrorID', data.errorId ? data.errorId : '')
      .set('Page_Code', data.pageId ? data.pageId : '')
      .set('Action_Code', data.actionId ? data.actionId : '')
      .set('ActionName', data.relation ? data.relation : '')
      .set('IsActive', data.value ? data.value : false)
      .set('UserId', userId);
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.UPDATE_USER_ACCESS_DATA}`, body, httpOptions);
  }
  getCompliancePlantList() {
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.USER_MANAGEMENT.GET_COMPLIANCE_PLANT}`,
      httpOptions
    );
  }
  public getMasterItemByCode(code: string): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_MASTERITEMLIST}?Search=&ShortCode=${code}`;
    return this.httpClient.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }
  public getExeMasterData(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_eComplainceErrorMsg`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }
  public updateComplainceMaster(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_eComplainceErrorMsg`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }
  public addComplainceMasterData(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_eComplainceErrorMsg`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }
  public updateLabourwiseDeviationAllow(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_LabourwiseDeviationAllow`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }
  public addLabourwiseDeviationAllow(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_Labour}`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public addEmployeeWiseHolidayRelation(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_EMPLOYEEWISE_HOLIDAY}`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }





  public getComplainceProfile(errorid): Observable<any> {
    const model = {
      UserID: localStorage.getItem('UserID'),
      ErrorID: errorid,
      Name: null,
      ErrorCode: null,
      Description: null,
      // Company_Code: null,
      DescriptionInLanguage: null,
      LocalLanguageDescription: null,
      SoundFile: null,
      MinValue: null,
      MaxValue: null,
      SpecificValue: null,
      MinDateTime: null,
      MaxDateTime: null,
      TextValue: null,
      StatementType: 'Edit'

    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_eComplainceErrorMsg`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  upload(file): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post(this.baseApiUrl, formData);
  }
  public updatePlantwiseErrorMaster(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_PlantwiseErrorMsg`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public addPlantwiseErrorData(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_PlantwiseErrorMsg`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public chkComplaince(name, errorid): Observable<any> {
    const model = {
      UserID: localStorage.getItem('UserID'),
      ErrorID: errorid,
      Name: name,
      ErrorCode: null,
      Description: null,
      // Company_Code: null,
      DescriptionInLanguage: null,
      LocalLanguageDescription: null,
      SoundFile: null,
      MinValue: null,
      MaxValue: null,
      SpecificValue: null,
      MinDateTime: null,
      MaxDateTime: null,
      TextValue: null,
      StatementType: 'chkComplaince'

    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_eComplainceErrorMsg`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public chkErrorCode(errorcode, errorid): Observable<any> {
    const model = {
      UserID: localStorage.getItem('UserID'),
      ErrorID: errorid,
      Name: null,
      ErrorCode: errorcode,
      Description: null,
      // Company_Code: null,
      DescriptionInLanguage: null,
      LocalLanguageDescription: null,
      SoundFile: null,
      MinValue: null,
      MaxValue: null,
      SpecificValue: null,
      MinDateTime: null,
      MaxDateTime: null,
      TextValue: null,
      StatementType: 'chkErrorCode'

    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_eComplainceErrorMsg`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }
  public updateMachineMaster(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_MachineMaster`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public addMachineMasterData(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_MachineMaster`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public checkDuplicateSerialNumber(serialNo: any): Observable<any> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Check_Machine_SerialNo`;
    return this.httpClient.post<any>(url, { SNR: serialNo }).pipe(
      map((response: any) => response)
    );
    // return of(false);
    //  return of(this.totalPlantWiseData());
  }

  public getUnits(plantCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_UNITS}?Search=&PlantCode=${plantCode}`;
    return this.httpClient.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }
  public getDepartments(unitCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENTS_FTR}?unitCode=${unitCode}`;
    return this.httpClient.get<any[]>(url).pipe(
      map((response: any) => response.Data));
  }

  public getCompanies(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_COMPANY_DATA}?Search=&StatusOfIndustry=&ISActive=&SDate=&EDate=&EntityType=&Legalstatus=
    &State=&City=&Country=`;
    return this.httpClient.get<any[]>(url).pipe(map((response: any) => response.Data.Table));
  }

  public getMachineMasterData(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_MachineMaster`;
    return this.httpClient.post<any>(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }
  public getPlants(companyCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_PLANTS}?Search=&Company=${companyCode}&State=&City=&Country=&SDate=&EDate=
    &UnitType=&Ownership=&LocatedAt=&ISESICApplicable=`;
    return this.httpClient.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }
  public getMachineMasterProfile(machine): Observable<any> {
    const model = {

      UserID: localStorage.getItem('UserID'),
      Company_Code: null,
      Machine_Code: machine,
      Plant_Code: null,
      Unit_Code: null,
      State_Code: null,
      Department_Code: null,
      Parent_Code: null,
      Location: null,
      ShortCode: null,
      ShortName: null,
      MachineType: null,
      SerialNo: null,
      ControllerNo: null,
      ControllerName: null,
      MachineIP: null,
      DefaultGateway: null,
      IsInOut: null,
      DuplicatePunchPeriod: null,
      Password: null,
      DoorLockDelay: null,
      VerificationMode: null,
      Port: null,
      MachineUseFor: null,
      MachineAttachedWith: null,
      IsMaster: null,
      IsSecondary: null,
      StatementType: 'Edit'
    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_MachineMaster`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }
  public getCheckShortCode(shortcode, machinecode): Observable<any> {
    const model = {

      UserID: localStorage.getItem('UserID'),
      Company_Code: null,
      Machine_Code: machinecode,
      Plant_Code: null,
      Unit_Code: null,
      State_Code: null,
      Department_Code: null,
      Parent_Code: null,
      Location: null,
      ShortCode: shortcode,
      ShortName: null,
      MachineType: null,
      SerialNo: null,
      ControllerNo: null,
      ControllerName: null,
      MachineIP: null,
      DefaultGateway: null,
      IsInOut: null,
      DuplicatePunchPeriod: null,
      Password: null,
      DoorLockDelay: null,
      VerificationMode: null,
      Port: null,
      MachineUseFor: null,
      MachineAttachedWith: null,
      IsMaster: null,
      IsSecondary: null,
      StatementType: 'checkShortCode'
    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_MachineMaster`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }
  public getCheckShortName(shortname, machinecode): Observable<any> {
    const model = {

      UserID: localStorage.getItem('UserID'),
      Company_Code: null,
      Machine_Code: machinecode,
      Plant_Code: null,
      Unit_Code: null,
      State_Code: null,
      Department_Code: null,
      Parent_Code: null,
      Location: null,
      ShortCode: null,
      ShortName: shortname,
      MachineType: null,
      SerialNo: null,
      ControllerNo: null,
      ControllerName: null,
      MachineIP: null,
      DefaultGateway: null,
      IsInOut: null,
      DuplicatePunchPeriod: null,
      Password: null,
      DoorLockDelay: null,
      VerificationMode: null,
      Port: null,
      MachineUseFor: null,
      MachineAttachedWith: null,
      IsMaster: null,
      IsSecondary: null,
      StatementType: 'checkShortName'
    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_MachineMaster`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public getcheckSerialNo(serialno, machinecode): Observable<any> {
    const model = {

      UserID: localStorage.getItem('UserID'),
      Company_Code: null,
      Machine_Code: machinecode,
      Plant_Code: null,
      Unit_Code: null,
      State_Code: null,
      Department_Code: null,
      Parent_Code: null,
      Location: null,
      ShortCode: null,
      ShortName: null,
      MachineType: null,
      SerialNo: serialno,
      ControllerNo: null,
      ControllerName: null,
      MachineIP: null,
      DefaultGateway: null,
      IsInOut: null,
      DuplicatePunchPeriod: null,
      Password: null,
      DoorLockDelay: null,
      VerificationMode: null,
      Port: null,
      MachineUseFor: null,
      MachineAttachedWith: null,
      IsMaster: null,
      IsSecondary: null,
      StatementType: 'chkSerialNo'
    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_MachineMaster`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  public getCheckShortName1(shortname, machinecode): Observable<any> {
    const model = {
      shortname,
      machinecode
    };
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_RULES_BY_ACT_DATA}`;
    return this.httpClient.post(url, model).pipe(
      map((response: any) => response));
  }
  public addgazzetenotification(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.INSERT_UPDATE_GAZZETE_NOTIFICATION}`;
    return this.httpClient.post(url, data);
  }
  // tslint:disable-next-line: function-name
  GetholidayProfile(holidaycode) {

    const queryString = $.param({
      holidaycode
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_HOLIDAY_BY_ID}?${queryString}`,
      httpOptions
    );
  }

  // tslint:disable-next-line: function-name
  // Updatholiday(contractorParams): Observable<Object> {

  //     UserID: localStorage.getItem('UserID'),
  //     // Company_Code: null,
  //     Machine_Code: machinecode,
  //     Plant_Code: null,
  //     Unit_Code: null,
  //     State_Code: null,
  //     Department_Code: null,
  //     Parent_Code: null,
  //     Location: null,
  //     ShortCode: null,
  //     ShortName: shortname,
  //     MachineType: null,
  //     SerialNo: null,
  //     ControllerNo: null,
  //     ControllerName: null,
  //     MachineIP: null,
  //     DefaultGateway: null,
  //     IsInOut: null,
  //     DuplicatePunchPeriod: null,
  //     Password: null,
  //     DoorLockDelay: null,
  //     VerificationMode: null,
  //     Port: null,
  //     MachineUseFor: null,
  //     MachineAttachedWith: null,
  //     IsMaster: null,
  //     IsSecondary: null,
  //     StatementType: 'checkShortName'
  //   };
  //   const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_MachineMaster`;
  //   return this.httpClient.post(url, model).
  //     pipe(
  //       map((response: any) => response));
  // }
  // // tslint:disable-next-line: function-name
  deleteholidayProfile(holidayCode) {

    const queryString = $.param({
      holidayCode
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.DELETE_HOLIDAY_BY_ID}?${queryString}`,
      httpOptions
    );
  }

}
