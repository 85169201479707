import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GridApi, GridOptions } from "ag-grid-community";
import * as moment from "moment";
import { AgNumberCountComponent, AgSelectActionCellRendererComponent } from "src/app/core/components";
import { LogHelperService } from "src/app/core/services/log-helper.service";
import { GatePassAccociateInformation, GatepassRequestProcess } from "../gatepass-request-process.model";
import { GatePassRequestsService } from "../../gate-pass-requests.service";

const browserWindow = window || {};
const browserWindowEnv = browserWindow['__env'] || {};

@Component({
  selector: 'app-workorder-selection',
  templateUrl: './workorder-selection.component.html',
  styleUrls: ['./workorder-selection.component.css']
})
export class WorkorderSelectionComponent implements OnInit {

  @Input() public workorderList: any[] = [];
  @Input() public stepData: GatepassRequestProcess;
  @Input() public disablePrevBtn: boolean;
  @Input() public globalSettings: any[];
  @Input() public alertMessages: any[];

  @Output() public handleSave: EventEmitter<GatePassAccociateInformation>;
  @Output() public handleClose: EventEmitter<void>;
  @Output() public handlePrevAction: EventEmitter<void>;

  public currentStep: number = 1;
  public allStepDataObj: any;
  public columnDefs: any[];
  public gridOptions: GridOptions;
  public gridAPI: GridApi;
  public checkCondition: string = '1';
  public workorderExpiredMessage: any;

  public remainingZeroMessage: any;

  private isHideRemainingPerson: boolean;
  workorderSelectionCode: string;

  constructor(
    private loggerHelperService: LogHelperService,
    public gatepassService: GatePassRequestsService
  ) {
    this.handleSave = new EventEmitter<GatePassAccociateInformation>();
    this.handleClose = new EventEmitter<void>();
    this.handlePrevAction = new EventEmitter<void>();

    this.isHideRemainingPerson = true;
    this.createGridOptions();
  }

  isShowStatusColumn: boolean = true;
  public ngOnInit(): void {
    this.gatepassService.getRequestTypeText(this.stepData);
    this.gatepassService.globalSettingCode('').subscribe((res: any) => {
      const workOrderSetting = res.Data.Table.find(item => item.Code === 'MOD52PG195REQWOSTATUS');
      // let statusColVisable = true;
      // if (workOrderSetting && workOrderSetting.Value === '1') {
      // }
      // else if (workOrderSetting && workOrderSetting.Value === '0') {
      //   statusColVisable = false;
      // }
      this.isShowStatusColumn = workOrderSetting.Value == '1' ? true : false;
      // this.gridOptions.columnApi.setColumnVisible('Status', statusColVisable);


      this.checkCondition = this.globalSettings.find(item => item.Code === 'MOD52PG195REQWOCAP').Value;
      // console.log("checkCondition --->",this.checkCondition)
      // if (this.checkCondition === '1' || this.checkCondition === '2') {
      //   this.isHideRemainingPerson = false;
      // } else {
      //   this.isHideRemainingPerson = true;
      // }

      this.createColumnDef();
    })


    // this.remainingZeroMessage = this.alertMessages.find(item => item.Short_Code === browserWindowEnv.ERROR.WORKORDER_REMAINING_ZERO);
    // this.workorderExpiredMessage = this.alertMessages.find(item => item.Short_Code === browserWindowEnv.ERROR.WORKORDER_EXPIRED);


    this.remainingZeroMessage = this.alertMessages.find(item => item.Short_Code === 'MSG195_9');
    this.workorderExpiredMessage = this.alertMessages.find(item => item.Short_Code === 'MSG195_10');
    // else {
    //   // If none of the conditions above are met, you may want to set a default value for isHideRemainingPerson.
    //   this.isHideRemainingPerson = true; // Or any other default value as per your requirements
    // }

    this.createColumnDef();
  }

  public onNextAction(): void {
  }

  public onCloseAction(): void {
    this.handleClose.emit();
  }

  public onPrevAction(): void {
    this.handlePrevAction.emit();
  }


  public onRowClicked(item: any): void {

    if (this.stepData.requestType != 105) {
      if (item.data.Status == 'Active') {
        if (item.data.Remaining <= 0) {
          if (this.checkCondition === '1') {
            this.loggerHelperService.logError({
              message: this.remainingZeroMessage.MSG
            })
          } else if (this.checkCondition === '2') {
            this.loggerHelperService.logError({
              title: 'Warning!',
              message: this.remainingZeroMessage.MSG,
            }, 'warning', 'Go to Next', true).then((res) => {
              if (res && res.value) {
                this.handleSave.emit({ ...item.data });
              }
            })
          } else if (this.checkCondition == '0') {
            this.handleSave.emit({ ...item.data });
          }
        } else {
          this.handleSave.emit({ ...item.data });
        }
      } else {
        this.loggerHelperService.logError({
          message: this.workorderExpiredMessage.MSG
        })
      }
    } else {
      this.handleSave.emit({ ...item.data });
    }


    // if (this.stepData.requestType === 103 && item.data.Status === 'Expired') {
    //   this.loggerHelperService.logError({
    //     message: 'You can not select expired Work Order under New gate pass request. Please select an active Work Order.'
    //   });

    //   return;
    // }
    // if (this.stepData.requestType === 104 && item.data.Status === 'Expired') {
    //   this.loggerHelperService.logError({
    //     message: 'You can not select expired Work Order under Renewal gate pass request. Please select an active Work Order.'
    //   });

    //   return;
    // }

    // if (this.stepData.requestType === 103 && !this.isHideRemainingPerson && item.data.Remaining <=0) {
    //   this.loggerHelperService.logError({
    //     message: 'You cannot request more than Work Order. Please extend capacity of Work Order.'
    //   });

    //   return;
    // }

  }

  private createColumnDef() {
    this.columnDefs = [
      {
        headerName: '#', field: '',
        width: 20, filterParams: { applyButton: true }, cellRendererFramework: AgNumberCountComponent
      },
      {
        headerName: 'Work Order Nbr.', field: 'WorkOrderNo',
        width: 120, filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: true
      },
      {
        headerName: 'Type', field: 'WorkOrderType',
        width: 50, filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: true
      },
      {
        headerName: 'Plant Name', field: 'Plant',
        minWidth: 150, filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: true
      },
      {
        headerName: 'Person', field: 'Person', width: 60, filterParams:
          { applyButton: true, clearButton: true }, filter: true
      },
      {
        headerName: 'Active Person', field: 'ActivePerson', width: 60, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        hide: this.checkCondition === "1" || this.checkCondition === "2" ? true : false
      },
      {
        headerName: 'Remaining', field: 'Remaining', width: 60, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        hide: this.checkCondition === "1" || this.checkCondition === "2" ? false : true
      },
      {
        headerName: 'Issue Dt.', field: 'OrderStartDate', width: 80, filterParams: { applyButton: true, clearButton: true },
        sortable: false, suppressMenu: false, filter: true, cellStyle: { textAlign: 'center' },
        cellRenderer: ({ data }) => {
          return moment(data.OrderStartDate).format('DD/MM/YYYY');
        }
      },
      {
        headerName: 'Validity Dt.', field: 'OrderEndDate', width: 100, filterParams: { applyButton: true, clearButton: true },
        sortable: false, suppressMenu: false, filter: true, cellStyle: { textAlign: 'center' },
        cellRenderer: ({ data }) => {
          return moment(data.OrderEndDate).format('DD/MM/YYYY');
        }
      },
      {
        headerName: 'Owner', field: 'Owner', width: 100, filterParams: { applyButton: true, clearButton: true },
        suppressMenu: false, sortable: true, filter: true
      },
      {
        headerName: 'Status', field: 'Status', width: 60, filterParams: { applyButton: true, clearButton: true },
        sortable: false, suppressMenu: false, filter: false, cellStyle: { textAlign: 'center' },
        cellRenderer: ({ data }) => {
          if (!data.Status) {
            return null;
          }
          if (data.Status === 'Expired') {
            return `<span class="badge badge-danger">${data.Status}</span>`;
          }

          return `<span class="badge badge-success">${data.Status}</span>`;
        },
        hide: !this.isShowStatusColumn
      },
      {
        headerName: 'Action', field: '', width: 60, filterParams: { applyButton: true, clearButton: true },
        sortable: false, suppressMenu: false, cellRendererFramework: AgSelectActionCellRendererComponent,
      }
    ];
  }

  private createGridOptions(): void {
    this.gridOptions = {
      context: {
        componentParent: this
      },
      getRowHeight: (params) => {
        return 37;
      },
      onGridReady: (params) => {
        this.gridAPI = params.api;
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      icons: {
        sortNone: '<i class="fa fa-sort" />',
        sortAscending: '<i class="fa fa-sort-alpha-asc" />',
        sortDescending: '<i class="fa fa-sort-alpha-desc" />'
      },
      pagination: true,
      paginationPageSize: 10,
      headerHeight: 40,
    };
  }

}