import {
  Component, Input, ChangeDetectionStrategy, OnChanges,
  AfterViewInit, ContentChildren, QueryList, ViewChildren, ViewChild, TemplateRef, OnInit, Output, EventEmitter, OnDestroy
} from '@angular/core';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SelectStepContentComponent } from './select-step-content/select-step-content.component';
import {
  BackdropColor,
  BackdropClass,
  ModalConfig,
  ModelWindowColor,
  ModelWindowClass,
  ModelDialogClass,
  DataSource,
} from './select-steps-model/select-steps.model';
import { GetTemplateDirective } from '../../directives/get-template.directive';
import { FormGroup, FormArray } from '@angular/forms';
import { SharedService } from '../../services';
import { Subscription, Subject } from 'rxjs';
import { SelectStepsService } from './select-step.service';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-select-steps',
  template: '',
  styleUrls: ['./select-steps.component.css']
})
export class SelectStepsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  /** Set boolean value */
  public isFirstNextEvent: boolean;
  private subscription: Subscription;
  private subscriptionCurrentSubject: Subscription;
  public isRowSelectedData: boolean;
  private isChangeSteps: boolean;
  public subject: Subject<any>;
  /** Hold the  list of template passed as a cont/ent. */
  @ContentChildren(GetTemplateDirective) public templates: QueryList<GetTemplateDirective<any>>;
  /** This input value is contain form group which pass by user */
  @Input() public stepsForm: FormGroup;
  /** This input value is contain form group which pass by user */
  @Input() public rowSelectedData: any;
  @Input() public selectionLabourCount: number;
  /** This input value is contain form group which pass by user */
  @Input() public rowFilterData: any[];
  /** This input value which is contains tempalte reference which is pass by user */
  @Input() public templateRef: any;
  /** This output event emmiter invoke when next button trigger */
  @Output() public changeOnNext: EventEmitter<any> = new EventEmitter();
  /** This output event emmiter invoke when skip button trigger */
  @Output() public changeOnSkip: EventEmitter<any> = new EventEmitter();
  /** This output event emmiter invoke when previous button trigger */
  @Output() public changeOnPrev: EventEmitter<any> = new EventEmitter();
  /** This output event emmiter invoke when previous button trigger */
  @Output() public changeOnSubmit: EventEmitter<any> = new EventEmitter();
  /** This output event emmiter invoke when previous button trigger */
  @Output() public changeOnCancel: EventEmitter<any> = new EventEmitter();
  /** This output event emmiter invoke when previous button trigger */
  @Output() public changeOnClose: EventEmitter<any> = new EventEmitter();
  /** This output event emmiter invoke when previous button trigger */
  @Output() public emitData: EventEmitter<any> = new EventEmitter();
  /** This output event emmiter invoke when previous button trigger */
  @Output() public getSelectedRowData: EventEmitter<any> = new EventEmitter();
  /** This output event emmiter invoke when select on questions trigger */
  @Output() public changeOnSelectQuestion: EventEmitter<any> = new EventEmitter();
  /** This output event emmiter invoke when select on dropDown trigger */
  @Output() public changeDropDown: EventEmitter<any> = new EventEmitter();
  /** This output event emmiter invoke when select on dropDown trigger */
  @Output() public triggerOnSetTemplate: EventEmitter<any> = new EventEmitter();
  /** This input value is used to setter getter value*/
  @Input() public set stepsData(data: any[]) {
    this.stepsItem = data;
    this.isChangeSteps = true;
  }

  public get stepsData(): any[] {
    return this.stepsItem;
  }

  /** This input decorator hold the value which is pass from parent component */
  @Input() public config: ModalConfig;
  public modalRef: NgbModalRef;
  private stepsItem: any[];
  constructor(
    public sharedService: SharedService,
    private modalService: NgbModal,
    private ngbConfig: NgbModalConfig,
    private service: SelectStepsService
  ) {
    this.subject = new Subject<any>();
    this.isFirstNextEvent = false;
    this.isRowSelectedData = false;
    this.isChangeSteps = false;
  }

  /**
  * This lifecycle run when input value changes.
  */
  public ngOnInit(): void {
    // this.triggerButton();
  }

  /**
   * When invoke this method it is set.
   * @param item This parameter contain e
   */
  private setConfigOnRowSelected(item: object): void {
    if (this.modalRef) {
      if (this.modalRef.componentInstance.isClickedOnceOnRow) {
        this.isRowSelectedData = false;
        this.getSelectedRowData.emit(item);
        this.modalRef.componentInstance.isClickedOnceOnRow = false;
      }
    }
  }

  /**
   * This lifecycle.
   */
  public ngAfterViewInit(): void {
    this.subscriptionCurrentSubject = this.subject.subscribe((item: any) => {
      if (this.modalRef) {
        for (let i = 0; i < item.value; i++) {
          if (item.isTemplateRef) {
            this.modalRef.componentInstance.isTemplateRef = true;
          }

          this.modalRef.componentInstance.rowSelectedData = item.rowData;
          this.modalRef.componentInstance.onNextAction();
        }
      }
    });
    this.subscription = this.sharedService.stepFinalDataSubject.subscribe((item) => {
      if (item.type === 'emitData') {
        this.emitData.emit(item.form);
      }
    });
  }

  /**
   * This lifecycle set the selected row data.
   */
  public ngOnChanges(): void {
    if (this.modalRef && this.modalRef.componentInstance) {
      this.modalRef.componentInstance.selectionLabourCount = this.selectionLabourCount;
    }
    console.log('stepSD', this.stepsData);
    if (this.modalRef && !this.isRowSelectedData && !this.rowFilterData) {
      this.modalRef.componentInstance.rowSelectedData = this.rowSelectedData;

      this.modalRef.componentInstance.onNextAction();
      this.isRowSelectedData = true;
    }
    if (this.stepsData && this.isChangeSteps && this.modalRef && !this.rowFilterData) {
      console.log('stepSD1', this.stepsData);
      this.modalRef.componentInstance.stepsData = this.stepsData;
      this.isChangeSteps = false;
    }
    if (this.modalRef && this.rowFilterData) {
      this.modalRef.componentInstance.rowData = this.rowFilterData;
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptionCurrentSubject.unsubscribe();
  }

  /*
   * When this method invoke it is open popup model.
   * And this method is also set the backdrop `color`.
   */
  public openModel(): void {
    this.modalRef = undefined;
    /** set the class for backgroud color of model pop up */
    const backDrop = this.setModelBackdropColor();
    /** set model window class. */
    const modalWindow = this.setModalwindowClass();
    const configObj: Object = { backDropClass: backDrop, windowClass: modalWindow, size: this.config.size };
    const modalConfig = this.setConfig(configObj);
    /** Open the popup model */
    this.modalRef = this.modalService.open(SelectStepContentComponent, modalConfig);
    this.triggerButton(this.modalRef);
    this.setConfigAndProperty();
    if (this.templateRef && this.modalRef) {
      this.modalRef.componentInstance.isOuterTemplateRef = true;
      this.modalRef.componentInstance.outerTemplateRef = this.templateRef;
    }
  }

  public closeModel(): void {
    if (this.modalRef) {
      this.modalRef.componentInstance.activeModal.dismiss('Cross click');
    }
  }

  /** This function call and it is set and initialize which button shoul be triggered */
  private triggerButton(modalRef: any): void {
    this.subscription = modalRef.componentInstance.subject.subscribe((item: any) => {
      if (item.action === 'next' && this.isFirstNextEvent) {
        const data = { formData: item.form, arrayItem: item.stepData };
        this.changeOnNext.emit(data);
      } else if (item.action === 'prev') {
        this.changeOnPrev.emit(item);
      } else if (item.action === 'cancel') {
        this.changeOnCancel.emit();
      } else if (item.action === 'submit') {
        this.changeOnSubmit.emit(item);
      } else if (item.action === 'close') {
        this.changeOnClose.emit(item.form);
        this.modalRef = undefined;
      } else if (item.action === 'rowData') {
        this.setConfigOnRowSelected(item);
      } else if (item.action === 'selectQuestions') {
        this.changeOnSelectQuestion.emit(item.value);
      } else if (item.action === 'changeDropDown') {
        this.changeDropDown.emit(item.data);
      } else if (item.action === 'skip') {
        this.changeOnSkip.emit(item.steps);
      } else if (item.action === 'isRenderTemplate') {
        this.triggerOnSetTemplate.emit();
      }
      this.isFirstNextEvent = true;
    });
  }

  /** Set config which pass from the user some input property value */
  private setConfigAndProperty(): void {
    this.modalRef.componentInstance.stepsData = this.stepsData;
    this.modalRef.componentInstance.stepsForm = this.stepsForm;
    /** Invoke this method it is set the 'content' 'header' 'footer' and 'template' */
    this.setModalContent(this.modalRef);
  }

  /**
   * Invoke this method and it is set the `backdropClass` of model popup.
   * This class is set based on color name which is pass from the user.
   */
  private setModelBackdropColor(): Object {
    let backDropClass: Object = {};
    switch (this.config.backDropColor) {
      case (BackdropColor.LightBlue):
        return backDropClass = BackdropClass.LightBlueBackdrop;
      case (BackdropColor.DarkGrey):
        return backDropClass = BackdropClass.DarkGrayBackdrop;
    }
  }

  /**
   * This method is set the modal window class.
   */
  private setModalwindowClass(): Object {
    let modalWindowClass: Object = {};
    switch (this.config.modalWindowClass) {
      case (ModelWindowColor.DarkGrey):
        return modalWindowClass = ModelWindowClass.DarkGreyContent;
      case (ModelDialogClass.CustomClass):
        return modalWindowClass = ModelWindowClass.MyCustomModalClass;
    }
  }

  /**
   * This is set the `configuration` which is pass to the modal service.
   */
  private setConfig(configObj: Object): Object {
    const modalConfig: Object = {};
    Object.assign(modalConfig, configObj);
    /** If modal property isCenter is true  */
    this.config.isCenter ? Object.assign(modalConfig, { centered: true }) :
    Object.assign(modalConfig, { centered: false });
    /** If modal is close or not on outside click  */
    this.ngbConfig.backdrop = this.config.closeOnOutSideClick ? undefined : 'static';
    return modalConfig;
  }

  /**
   * Invoke this method it is set the modal template or `header`,`content` and `footer`.
   */
  private setModalContent(modalReference: any): void {
    /** Using the reference of getTemplate directive and fetch the template or element which is pass from the user */
    /** Like `header`, `content` and `footer` context. */
    this.templates.toArray().forEach((template: GetTemplateDirective<any>) => {
      /** Using child instance of the getTemplate directive, which contain templateInput and template ref property */
      switch (template.getTemplate().templateInput) {
        case ('template'):
          modalReference.componentInstance.header = template.getTemplate().templateRef;
          break;
        case ('template'):
          modalReference.componentInstance.content = template.getTemplate().templateRef;
          break;
        case ('template'):
          modalReference.componentInstance.footer = template.getTemplate().templateRef;
          break;
      }
    });
  }
}
