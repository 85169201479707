import { environment } from "../environments/environment";
import { toDate } from "@angular/common/src/i18n/format_date";
export let BASE_URL;
export let BASE_URL_NEW;
export let BASE_URL_LOGIN;
export let PHOTO_URL;
export let DOC_URL;

// Read environment variables from browser window
const browserWindow = window || {};
const browserWindowEnv = browserWindow["__env"] || {};

BASE_URL = browserWindowEnv.BASE_URL;
BASE_URL_LOGIN = browserWindowEnv.BASE_URL_LOGIN;
PHOTO_URL = browserWindowEnv.PHOTO_URL;
DOC_URL = browserWindowEnv.DOC_URL;
export class ReportBuilderParam {
  // tslint:disable-next-line:variable-name
  ParaId: number;
  // tslint:disable-next-line:variable-name
  ApiId: number;
  // tslint:disable-next-line:variable-name
  Name: string;
  // tslint:disable-next-line:variable-name
  ValueData: string;
  // tslint:disable-next-line:variable-name
  Type:
    | "text"
    | "select"
    | "multiselect"
    | "radio"
    | "checkbox"
    | "date"
    | "lookup";
  // tslint:disable-next-line:variable-name
  List?: any[];
  // tslint:disable-next-line:variable-name
  Url: string;
}

export class ReportBuilderAPI {
  // tslint:disable-next-line:variable-name
  ApiId: number;
  // tslint:disable-next-line:variable-name
  Description: string;
  // tslint:disable-next-line:variable-name
  IsFilter: boolean;
  // tslint:disable-next-line:variable-name
  Params: ReportBuilderParam[];
  // tslint:disable-next-line:variable-name
  PrimaryKeyName: string;
  // tslint:disable-next-line:variable-name
  ResponseSchema: string[];
  // tslint:disable-next-line:variable-name
  Url: string;
  // tslint:disable-next-line:variable-name
  Selected?: boolean;
  // tslint:disable-next-line:variable-name
  Method?: "GET" | "POST" = "GET";
}
export abstract class GLOBAL {
  public static get USER_ID(): any {
    return localStorage.getItem("UserID");
  }

  public static APP_VERSION = "1.1.11";
  public static HTTP_GET = "GET";
  public static HTTP_POST = "POST";
  public static HTTP_PUT = "PUT";
  public static BASE_URL_DOC = BASE_URL.slice(0, -5);
  public static PHOTO_URL = PHOTO_URL;
  public static DOC_URL = DOC_URL;
  public static IGNORE_LOADER = "IGNORE_LOADER";
  public static USER = {
    LOGGED_IN_USER: null,
    ROLE_CODE: null,
    EMAIL_ID: null,
  };
  public static APIS = {
    BASE_API_URL: `${BASE_URL_LOGIN}`,
    ASSETS: {
      GET_INDIA_JSON: "assets/data/india.json",
    },
    COMMON_GLOBAL_VAR: {
      PAGE_NPRINT_CONFIG: `${BASE_URL}Common/List/PageNPrintConfig`,
    },
    COMPANY_COMMONS_MASTER: {
      GET_SHIFT_GROUP_LIST: `${BASE_URL}Common/List/SP_Listing_ShiftGroupMaster`,

      // GET_SHIFT_GROUP_LIST: `${BASE_URL}Master/GetShiftGroup`,
      // GET_COMPANY_MASTER_DATA: `${BASE_URL}Common/List/SP_Listing_CompanyMaster_Filter`,
      GET_PAYROLL_PROCESS_UEG_CODE_M2M: `${BASE_URL}Common/List/HRMS_SP_PayrollProcessByUEGCodeN_M2M`,
      GET_COMPANY_MASTER_DATA_SP: `${BASE_URL}Common/List/SP_Listing_CompanyMaster_Filter`,
      GET_PAYROLL_ACTIVE_LABOUR: `${BASE_URL}Common/MultiTableList/CLPMS_PAYROLL_ActiveLabour_Month`,
      GET_ASSOCIATE: `${BASE_URL}Common/List/SP_Listing_Labour_V1`,
      //GET_STATE_DATA_SP: `${BASE_URL}Common/List/SP_COM_GetStateList`,
      GET_STATE_DATA_SP: `${BASE_URL}Common/List/SP_COM_GetStateList_V1`,
      GET_COMPANY_MASTER_DATA: `${BASE_URL}GlobalMaster/GetCompanyMasterData`,
      GET_ALL_UNIT: `${BASE_URL}GlobalMaster/GetAllUnit`,
      GET_PLANT_MASTER_DATA: `${BASE_URL}Common/List/SP_Listing_PlantMaster_Filter`,
      CheckLeaveavAilability: `${BASE_URL}Common/List/CheckLeaveavAilability`,
      //  GET_PLANT_MASTER_DATA: `${BASE_URL}GlobalMaster/GetPlantMasterData`,
      GET_ALL_DEPARTMENT_DATA: `${BASE_URL}GlobalMaster/GetAllDepartmentData`,
      GET_Country_Master_LIST: `${BASE_URL}Common/List/SP_COM_GetAllCountry`,
      // GET_Country_Master_LIST: `${BASE_URL}/GlobalMaster/GetALLCountry`,
      // GET_State_Master_LIST: `${BASE_URL}Common/List/SP_COM_GetAllState`,

      GET_State_Master_LIST: `${BASE_URL}/GlobalMaster/GetStateMasteData`,
      GET_STATE_LIST: `${BASE_URL}Common/StateMaster`,
      GET_ACT: `${BASE_URL}Common/LegislationMaster`,
      GET_District_Master_LIST: `${BASE_URL}Common/List/SP_COM_GetAllDistrict`,

      //      GET_District_Master_LIST: `${BASE_URL}/GlobalMaster/GetDistrictMasterData`,
      GET_City_Master_LIST: `${BASE_URL}Common/List/SP_COM_GetAllCity`,

      // GET_City_Master_LIST: `${BASE_URL}/GlobalMaster/GetCityMasterData`,
      GET_PLANT_LIST: `${BASE_URL}Common/List/SP_Listing_PlantMaster_Filter`,
      // GET_PLANT_LIST: `${BASE_URL}GlobalMaster/GetPlantMasterData`,
      GET_UNIT_LIST: `${BASE_URL}Common/List/SP_Listing_UnitMaster`,
      SAVE_Shift: `${BASE_URL}Shift/AddShift`,
      SAVE_Shift_Group: `${BASE_URL}Shift/InsertShiftGroup`,
      SAVE_Site_Shift_Group: `${BASE_URL}Shift/InsertSiteShiftGroup`,
      Update_Shift: `${BASE_URL}Shift/UpdateShift`,
      GET_ShiftMaster: `${BASE_URL}Common/List/SP_ShiftMaster_Listing`,

      //      GET_ShiftMaster: `${BASE_URL}Shift/GetShiftMaster`,
      // GET_ShiftGroupMaster: `${BASE_URL}Common/List/SP_Listing_ShiftGroupMaster`,
      GET_ShiftGroupMaster: `${BASE_URL}Common/List/SP_ShiftGroupMaster_Page_Listing`,

      //      GET_ShiftGroupMaster: `${BASE_URL}Shift/GetShiftGRoupMasterPageListing`,
      GET_SiteShiftGroupMaster: `${BASE_URL}Master/GetSiteShiftGrouplist`,
      GET_Shift_Group_MASTER_DATA: `${BASE_URL}Common/List/SP_Listing_ShiftGroupMaster`,

      // GET_Shift_Group_MASTER_DATA: `${BASE_URL}Master/GetShiftGroup`,
      GET_Shift_ID: `${BASE_URL}Shift/GetShiftDataForEdit`,
      GET_Shift_GRoup_ID: `${BASE_URL}Shift/GetShiftGroupProfile`,
      GET_LEGISLATION_MASTER_DATA: `${BASE_URL}Common/List/SP_Listing_LegislationMaster`,
      Update_Shift_GRoup: `${BASE_URL}Shift/UpdateShiftGroup`,
      GET_Site_Shift_GRoupDAta_FOR_EDIT: `${BASE_URL}Shift/GetSiteShiftGroupDataForEdit`,
      Update_Site_Shift_GRoup: `${BASE_URL}Shift/UpdateSiteShiftGroup`,
      DELETE_SITE_SHIFT_GROUP: `${BASE_URL}Shift/DeleteSiteShift`,
      GET_SECTION_DATA: `${BASE_URL}Common/List/SP_COM_GetAllSectiondata`,
      //  GET_SECTION_DATA: `${BASE_URL}GlobalMaster/GetALLSectionData`,
      CHECK_SECTION_RELATION_DATA: `${BASE_URL}GlobalMaster/GetCheckSectionRelation`,
      INSERT_SECTION_PLANT_MASTER_DATA: `${BASE_URL}GlobalMaster/InsertSEctionPlantMasterData`,
      // Shift_Group_Type: `${BASE_URL}Master/GetShiftTypeGrouplist`,
      Shift_Group_Type: `${BASE_URL}Common/List/SP_Listing_ShiftTypeGroup`,

      ADD_HOLIDAY_MASTER: `${BASE_URL}Master/AddHolidayData`,
      GET_FINACIAL_YEAR: `${BASE_URL}Common/FinancialYear`,
      GET_FUNCTION_MASTER_DATA_FOR_GRID: `${BASE_URL}Master/GetFunctionBudgetMasterData`,
      ADD_FUNCTION_BUDGET_MASTER: `${BASE_URL}Master/AddFunctionBudgetMaster`,
      ADD_PLANT_MASTER: `${BASE_URL}Master/AddPlantData`,
      ADD_LEGISLATION_MASTER: `${BASE_URL}Common/List/SP_Insert_Update_Legislation_Master`,
      ADD_COMPLIANCE_TASK: `${BASE_URL}Common/List/SP_Insert_CompanyTaskDetail`,
      GET_PLANT_MASTER_DATAFOR_EDIT: `${BASE_URL}Master/GetPlantData`,
      GET_COMPANY_MASTER_DATAFOR_EDIT: `${BASE_URL}Master/GetCompanyData`,
      GET_LEGISLATION_MASTER_DATAFOR_EDIT: `${BASE_URL}Common/List/Get_Legislation_by_Code`,
      UPDATE_PLANT_MASTER: `${BASE_URL}Master/UpdatePlantMaster`,
      CHECK_PLANT_NAME: `${BASE_URL}Master/CheckPlantName`,
      CHECK_PLANT_SHORT_CODE: `${BASE_URL}Master/CheckPlantShortCode`,
      CHECK_PLANT_SHORT_NAME: `${BASE_URL}Master/CheckPlantShortName`,
      CHECK_SITESHIFTNAME_NAME: `${BASE_URL}Master/CheckSiteShiftGroupName`,
      CHECK_SITESHIFTNAME_SHORT_CODE: `${BASE_URL}Master/CheckSiteShiftGroupShortCode`,
      CHECK_SITESHIFTNAME_SHORT_NAME: `${BASE_URL}Master/CheckSiteShiftGroupShortName`,
      CHECK_SHIFGROUPNAME_NAME: `${BASE_URL}Master/CheckShiftGroupName`,
      CHECK_SHIFGROUPNAME_SHORT_CODE: `${BASE_URL}Master/CheckShiftGroupShortCode`,
      CHECK_SHIFGROUPNAME_SHORT_NAME: `${BASE_URL}Master/CheckShiftGroupShortName`,
      CHECK_SHIFT_NAME: `${BASE_URL}Master/CheckShiftName`,
      CHECK_SHIFT_SHORT_CODE: `${BASE_URL}Master/CheckShiftShortCode`,
      CHECK_SHIFT_SHORT_NAME: `${BASE_URL}Master/CheckShiftShortName`,
      GET_MASTER_PARENT_DATA: `${BASE_URL}Common/List/SP_MasterPArentCode_List`,
      GET_SUBDATA_FROM_PARENTCODE: `${BASE_URL}Common/List/SP_Get_ChildCode_From_parentCode`,
      GET_SUBPARENT_DATA: `${BASE_URL}Common/List/SP_SubParentData_List`,
      INSERT_MASTER_ITEM_DATA: `${BASE_URL}Common/InsertUpdateDelete/SP_Insert_MasterItem_Data`,
      INSERT_UPDATE_NATURE_OF_WORK_DATA: `${BASE_URL}Common/InsertUpdateDelete/Insert_Update_NatureOfWork`,
      GET_DATA_BY_NATUREOFWORK_CODE: `${BASE_URL}Common/List/Get_List_By_NatureOfWork_Code`,
      CHECK_NATURE_OF_WORK_NAME: `${BASE_URL}Master/CheckNatureOfWorkName`,
      GET_ALL_GAZZATE_DATA: `${BASE_URL}Common/List/Get_All_GazzeteData`,
      GET_LEGISLATION_DATA: `${BASE_URL}Common/List/SP_Listing_Get_ALL_LegistlationData`,
      GET_RULES_BY_ACT_DATA: `${BASE_URL}Common/List/SP_Listing_Get_RULES_BY_ACT`,
      INSERT_UPDATE_GAZZETE_NOTIFICATION: `${BASE_URL}Common/List/INSERT_UPDATE_GAZZETE_NOTIFICATION`,
      UPLOAD_DOC: `${BASE_URL}Activity/AddLesislationDoc`,
      UPLOAD_SOUND_FILE: `${BASE_URL}Activity/AddLesislationDoc`,
      GET_HOLIDAY_BY_ID: `${BASE_URL}Master/GetHolidayDataForEdit`,
      UPDATE_HOLIDAY_MASTER: `${BASE_URL}Master/UpdateHolidayMaster`,
      DELETE_HOLIDAY_BY_ID: `${BASE_URL}Master/DeleteHolidayMasterByID`,
      GET_EMP: `${BASE_URL}Common/List/SP_List_Labour`,
      GET_STATE_LIST_PLANTWISE: `${BASE_URL}Common/List/SP_COM_GetStateList`,
      GET_COFFDATA_LISTING: `${BASE_URL}Common/List/SP_GetCoffListing_Filter`,
      GET_EMPLOYEE: `${BASE_URL}Common/List/SP_GetSerialNo_By_Plant`, // SP_List_SerialNo
      GET_EMPLOYEEWISE_EXCEPTIONAL_ATTENDANCE: `${BASE_URL}Common/List/SP_Get_Employeewise_ExceptionalAttendance`,
      GET_EMPLOYEEWISE_DETAILS: `${BASE_URL}Common/List/SP_Get_Employeewise_Holiday`,
      GET_CONTRACTORWISE_LABOURS_DETAILS: `${BASE_URL}Common/List/SP_List_Labours`,
      GET_CONTRACTORS_LIST: `${BASE_URL}Common/List/GetAllContractors`,
      GET_LABOUR: `${BASE_URL}Common/List/SP_List_LabourDeviationAllow`,
      UPLOAD_COMPLIANCE_TASK_DOC: `${BASE_URL}ComplianceTaskCalender/AddCalenderDocument`,
      UPLOAD_LEGISLATION_DOC: `${BASE_URL}ComplianceTaskCalender/AddLegislatonDocument`,
      GET_BIO_SERVER: `${BASE_URL}Common/List/SP_Listing_bioserverLabour`,
      SAVE_BULK_BIO_PUNCH: `${BASE_URL}Common/List/AutoGetPunchFromBiosServerTo_CIPLA_Manual`,
      GET_GROUP_WISE_COMPONENT: `${BASE_URL}Common/Nesting/CLPMS_SP_Payroll_GroupwiseComponent`,
      GET_DOCUMENTS_TYPES_PAGE_MODULE_WISE: `${BASE_URL}Common/List/DocumentsTypesPageModuleWise`,
      GET_DOCUMENTS_TYPES_PAGE_MODULE_WISE_V1: `${BASE_URL}Common/List/DocumentsTypesPageModuleWise_V1`,
      // SP_Listing_bioserverLabour

      GETMASTERITEMLISTDATA_V2 : `${BASE_URL}Common/Nesting/SP_GetMasterItemListData_V2`,
    },
    MAN_POWER: {
      GET_MAN_POWER_ALLOCATION: `${BASE_URL}ManPowerAllocation/GetDailyManPowerAssignLoaction`,
      GET_MAN_POWER_DAILY_SHIFT_REQUISTION: `${BASE_URL}ManPowerAllocation/GetDailyManPowerShiftRequistionData`,
      // GET_MAN_POWER_DAILY_SHIFT_REQUISTION: 'assets/data/GetDailyManPowerShiftRequistionData.json',
      // GET_MAN_POWER_ALLOCATION: `assets/data/menpowerallocation.json`,
      GET_MAN_POWER_ALLOCATION_SAVE_POWER_MOVEMENT_DATA: `${BASE_URL}ManPowerAllocation/SaveLabourMovementdata`,
      GET_MAN_POWER_ALLOCATION_ATTENDENCE_APPROVE: `${BASE_URL}ManPowerAllocation/AttendanceApprove`,
      GET_MAN_POWER_ALLOCATION_REQUISITION_REQUESTLIST: `${BASE_URL}ManPowerAllocation/GetManPowerRequisitionRequestList`,
      GET_PLANT_MASTER_DATA: `${BASE_URL}GlobalMaster/GetPlantMasterData`,
      GET_UNIT_MASTER_DATA: `${BASE_URL}GlobalMaster/GetAllUnit`,
      GET_SHIFT_MASTER_DATA: `${BASE_URL}Master/GetShiftMasterlist`,
      GET_SHIFT_LIST_DATA: `${BASE_URL}Master/GetShiftList`,
      GET_DEPARTMENT_MASTER_DATA: `${BASE_URL}GlobalMaster/GetALLDepartmentData`,
      GET_SECTION_MASTER_DATA: `${BASE_URL}GlobalMaster/GetALLSectionData`,
      GET_NATURE_OF_WORK_MASTER_DATA: `${BASE_URL}Common/List/SP_Listing_NatureOfWork_Filter`,

      //      GET_NATURE_OF_WORK_MASTER_DATA: `${BASE_URL}Master/GetALLNatureOfWorkData`,
      GET_SKILLS: `${BASE_URL}Master/GetALLSkillData`,
      GET_SKILLS_BY_NATUREOFWORK: `${BASE_URL}Master/GetSkillByNatureOfWork`,
      // GET_MAN_POWER_ALLDATA: `${BASE_URL}ManPowerAllocation/GetManPowerRequisitionRequestList`,
      GET_MAN_POWER_ALLDATA_OLD: `${BASE_URL}Common/List/SP_ListManPowerRequest_New1`,
      // GET_MAN_POWER_ALLDATA_OLD: `${BASE_URL}ManPowerAllocation/GetManPowerRequisitionRequestListNew`,
      GET_MAN_POWER_ALLDATA: `${BASE_URL}Common/List/SP_List_ManpowerRequisiton`,
      GET_MAN_POWER_DETAIL: `${BASE_URL}ManPowerAllocation/GetManPowerRequisitionRequestListByCode1`,
      GET_MAN_POWER_REDUCE_DETAIL: `${BASE_URL}ManPowerAllocation/GetManPowerRequisitionRequestListByCodeReduce`,
      GET_WEEK_OFF_DATELIST: `${BASE_URL}ManPowerAllocation/GetWeekOffDateList`,
      GET_MAN_POWER_BUDGET_FOR_MONTH: `${BASE_URL}ManPowerAllocation/GetManPowerBudgetForMonth`,
      APPROVE_AND_SAVE_POWER_REQUISITION: `${BASE_URL}ManPowerAllocation/ApproveAndSavePowerRequistion`,
      SAVE_MANPOWER_REQUISITION_HRASSIGN: `${BASE_URL}ManPowerAllocation/SavePowerRequistionForHRAssign`,
      GET_POWER_REQUISITION_FOR_APPROVE: `${BASE_URL}ManPowerAllocation/GetPowerRequistionForApprove`,
      GET_ALL_MPRSHIFT_SECTION_LIST: `${BASE_URL}ManPowerAllocation/GetAllMPRShiftSectionList`,
      // GET_NATURE_OF_WORK_AND_SKILL: `${BASE_URL}Common/List/SP_Listing_NatureOfWorkAndSkill`,

      GET_NATURE_OF_WORK_AND_SKILL: `${BASE_URL}Master/GetNatureOfWorkAndSkill`,
      APPROVE_ATTENDENCE_Manual_Punch: `${BASE_URL}ManPowerAllocation/AttendanceManualPunchForAprroval`,
      APPROVE_ATTENDENCE_BY_SEC_HEAD: `${BASE_URL}ManPowerAllocation/AttendanceApprove`,
      APPROVE_ATTENDENCE_BY_SEC_HEAD2: `${BASE_URL}ManPowerAllocation/AttendanceApproveinfo`,
      CLPMS_SP_Bulk_AttendnaceStatuUpdate: `${BASE_URL}Common/List/CLPMS_SP_Bulk_AttendnaceStatuUpdate`,
      APPROVE_ATTENDENCE_BY_SEC_HEAD_NEW: `${BASE_URL}Common/InsertUpdateDelete/SP_Insert_ApproveInfo`,
      LIST_FOR_APPROVAL: `${BASE_URL}Common/Nesting/SP_List_Approve_Attendance`,
      APPROVE_SAVE_REQUEST: `${BASE_URL}ManPowerAllocation/SubmitRequest`,
      DELETE_MAN_POWER_REQUEST: `${BASE_URL}Common/InsertUpdateDelete/SP_Delete_ManpowerRequisition_By_Code`,
      GET_MONTHLY_REQUITION: `${BASE_URL}Common/Nesting/SP_LIST_GetMonthlyRequition`,
      GET_MONTHLY_REQUITION2: "assets/data/SP_LIST_GetMonthlyRequition.json",
      ATTENDANCE_REPROCESS: `${BASE_URL}ManPowerAllocation/attendancereprocess`,
      SAVE_BIO_PUNCH: `${BASE_URL}ManPowerAllocation/bioattendancereprocess`,
      MANPOWER_REDUCE: `assets/data/manpower_reduce.json`,
      GET_LABOUR_DETAIL_BY_CODE: `${BASE_URL}Common/List/SP_Listing_LabourFormDetails`,
      GET_LABOUR_PFDETAIL_BY_CODE: `${BASE_URL}Common/List/SP_Listing_PFFormDetails`,
      GET_LABOUR_ESICDETAIL_BY_CODE: `${BASE_URL}Common/List/SP_Listing_LabourESICFormDetails`,
      GET_LABOUR_NOMINEE_LIST_FOR_ESIC: `${BASE_URL}Common/List/SP_Listing_NomineeDetailsList`,
      GET_CLPMS_LIST_OT_APPROVAL_MM: `${BASE_URL}Common/List/CLPMS_SP_LIST_OT_Apporval_MM_V1`,
      SP_PLANTTOSECTION: `${BASE_URL}Common/List/SP_PlantToSection`,
      SUBMIT_OT_APPROVAL: `${BASE_URL}ManPowerAllocation/SubmittOTApproval`,
      SP_GET_REQUESTLEVEL_CODE: `${BASE_URL}Common/List/SP_GET_RequestLevelNRole`,
    },
    MASTER_DATA: {
      GET_DATA: `${BASE_URL}Common/`,
      INSERT_DATA: `${BASE_URL}Common/`,
      GET_DATA_BY_SP: `${BASE_URL}Common/List/`,
      // GET_GLOBAL_MASTER: `${BASE_URL}Common/List/SP_GetMasterItemListData`,
      GET_GLOBAL_MASTER: `${BASE_URL}GlobalMaster/GetMasterItemListData?ShortCode=&Search=`,
      // GET_GLOBAL_SETTING: `${BASE_URL}Common/List/SP_GetGlobalSettingData`,
      GET_GLOBAL_SETTING: `${BASE_URL}GlobalMaster/GetGlobalSettingData`,
      COMMAN_GLOBALSETTINGDATA_LISTING: `${BASE_URL}Common/List/COMMAN_GlobalSettingData_Listing`,
      COMMAN_GLOBAL_SETTING_DATA_INSERT_UPDATE: `${BASE_URL}Common/List/COMMAN_GlobalSettingData_InsertUpdate`,
      // GET_MASTERITEMLIST: `${BASE_URL}Common/List/SP_GetMasterItemListData`,
      GET_MASTERITEMLIST: `${BASE_URL}GlobalMaster/GetMasterItemListData`,
      // GET_COMPANY_DATA: `${BASE_URL}Common/List/SP_GetGlobalSettingData`,
      GET_COMPANY_DATA: `${BASE_URL}GlobalMaster/GetCompanyMasterData`,
      GET_ROLEBYDEFAULT: `${BASE_URL}Master/GetByDefaultRole1`,
      GET_SHIFTTYPEGROUP_DATA: `${BASE_URL}Master/GetShiftTypeGrouplist`,
      GET_DATA_BY_Labour: `${BASE_URL}Common/List/SP_Insert_LabourwiseDeviationAllow`,
      GET_DATA_BY_EMPLOYEEWISE_HOLIDAY: `${BASE_URL}Common/List/SP_Insert_Update_Delete_List_EmployeeWiseHolidayRelation`,
      GET_PLANTS: `${BASE_URL}GlobalMaster/GetPlantMasterData`,
      GET_DEPARTMENTS: `${BASE_URL}GlobalMaster/GetALLDepartmentData`,
      GET_DEPARTMENTMASTERS: `${BASE_URL}GlobalMaster/GetDepartmentMaster`,
      GET_DEPARTMENTHOD_LIST: `${BASE_URL}GlobalMaster/GetUserByDepartment`,
      GET_DEPARTMENTS_FTR: `${BASE_URL}Master/GetDepartments`,
      GET_STATE: `${BASE_URL}GlobalMaster/GetStateMasteData`,
      GET_COMMON_SP_USERPLANTACCES: `${BASE_URL}Common/List/COMMON_SP_UserPlantAccess`,
      GET_NATUREOFWORKS: `${BASE_URL}Master/GetALLNatureOfWorkData`,
      GET_SKILLS: `${BASE_URL}Master/GetALLSkillData`,
      GET_PLANTWISE_STATE: `${BASE_URL}GlobalMaster/GetStateMasteDataList`,
      GET_UNITS: `${BASE_URL}GlobalMaster/GetAllUnit`,
      GET_SHIFT: `${BASE_URL}GlobalMaster/GetAllShift`,
      GET_DESIGNATION: `${BASE_URL}GlobalMaster/GetALLDesignation`,
      // GET_ORGANIZATION_LIST: `${BASE_URL}GlobalMaster/GetAllOrganization`,
      // GET_LANGUAGE_DATA: `${BASE_URL}GlobalMaster/GetAllLanguage`,
      // GET_ROLE_DATA: `${BASE_URL}GlobalMaster/GetAllRole`,
      // GET_SECTION: `${BASE_URL}GlobalMaster/GetALLSectionData`,
      GET_SECTION: `${BASE_URL}GlobalMaster/GetALLSectionDataLevelwise`,
      GET_SHIFTTYPES: `${BASE_URL}Master/GetShiftGroup`,
      GET_SHIFTGROUPTYPES: `${BASE_URL}Master/GetShiftTypeGrouplist`,
      GET_SHIFTS: `${BASE_URL}Master/GetShiftMasterlist`,
      GET_DEPARTMENT_PLANT_UNIT_SECTION_TREE: `${BASE_URL}Master/GetTreeViewOfDeptPlantUnitSection`,
      // GET_DEPARTMENT_PLANT_UNIT_SECTION_TREE: `assets/data/section-tree-data.json`,
      GET_HOLIDAY_LIST: `${BASE_URL}Master/GetAllHolidayMasterListData`,
      GET_SECTION_FIRST_LEVEL: `${BASE_URL}Master/GetSectionFirstLevel`,
      // GET_ALL_DATASET: `${BASE_URL}Common/List/SP_DAS_GetAllDatSet`,
      GET_INHEADCOUNT: `${BASE_URL}Common/List/SP_DAS_InHeadCount`,
      GET_OutHEADCOUNT: `${BASE_URL}Common/List/SP_DAS_OutHeadCount`,
      GET_REQUIREMENT_AND_SHORTAGE: `${BASE_URL}Common/List/SP_DAS_CountLiveShortage`,
      GET_ALL_DATASET1: `${BASE_URL}Common/List/SP_DAS_GetAlBudgetDS`,
      // GET_ALL_DATASET: `assets/data/dashboard-data-new.json`, // RR
      GET_ALL_DATASET: `${BASE_URL}Dashboard/GetAllDatScedulesNew`, // RR
      // GET_ALL_DATASET1: `assets/data/GetAlBudgetDS.json`,
      GET_PINCODE_DETAILS: `${BASE_URL}Common/List/SP_COM_GetAllCountry`,
      GET_ORGANIZATION_DETAILS: `${BASE_URL}Common/List/SP_COM_GetAllOrganization`,
      GET_PINCODE_DETAILS_DATA: `${BASE_URL}GlobalMaster/GetALLCountryStateDisCityPO`,
      GET_PLANTS_BY_COMPANY: `${BASE_URL}GlobalMaster/GetPlantDataRoleWise`,
      GET_PLANTS_BY_USERWISE: `${BASE_URL}GlobalMaster/GetPlantDataUserWise`,
      GET_DEPARTMENT_PLANT_UNIT_SECTION_TREE_New: `${BASE_URL}Master/GetDepartmentPlantUnitSectionTree_New`,
      GET_DAS_NON_COMPLIANCE_LABOUR_LIST: `${BASE_URL}Common/List/SP_Das_nonComplianceLabourList`,
      GET_DASHBOARD_REALTIME_HEADCOUNT: `${BASE_URL}Common/List/SP_Dashboard_RealTime_HeadCount`, // Contractor-wise, Skill Wise, Weekly Skill
      GET_DASHBOARD_REALTIME_HEADCOUNT_SUMMARY: `${BASE_URL}Common/List/Dashboard_Realtime_HeadCount_Summary`,
      GET_DASHBOARD_SP_Listing_Contractor_Filter_V1: `${BASE_URL}Common/List/SP_Listing_Contractor_Filter_V1`,

      // GET_DEPARTMENT_PLANT_UNIT_SECTION_TREE_New: `assets/data/ignore-dir/GetDepartmentPlantUnitSectionTree_New.json`,
      // tslint:disable-next-line
      GET_DEPARTMENT_PLANT_UNIT_SECTION_DATA: `${BASE_URL}Common/Nesting/SP_Listing_DepartmentSectionSubSectionPlantUnitRelationMerging`,
      GET_DEPARTMENT_PLANT_UNIT_SECTION_TABLE: `${BASE_URL}Common/List/SP_Listing_DepartmentSectionSubSectionPlantUnitRelationMerging`,
      GET_WORKING_WEEKOFF_DATA: `${BASE_URL}Common/Nesting/SP_WeeklyOff_PresentWith_OT_Report`,
      // tslint:disable-next-line
      // GET_DEPARTMENT_PLANT_UNIT_SECTION_DATA: `assets/data/ignore-dir/SP_Listing_DepartmentSectionSubSectionPlantUnitRelationMerging.json`,
      // GET_FINANCIAL_YEARS: `${BASE_URL}Master/GetFinacialYear`,
      // GET_FINANCIAL_MONTH: `${BASE_URL}Master/GetMonthFromFinacialYear`,
      GET_FINANCIAL_YEARS: `${BASE_URL}Common/FinancialYear`,
      GET_FINANCIAL_MONTH: `${BASE_URL}GlobalMaster/GeMonthData`,
      GET_ECpolicyPolicy_For_Dashboard: `${BASE_URL}Dashboard/GetECPolicyDataForDashboard`,
      GET_Licence_For_Dashboard: `${BASE_URL}Dashboard/GetLicenceDataForDashboard`,
      GET_WorkOrder_For_Dashboard: `${BASE_URL}Dashboard/GetWorkOrderDataForDashboard`,
      GET_DailyRequirement_For_Dashboard: `${BASE_URL}Dashboard/GetContractorDailyAllocationForDashboard`,
      GET_Contractor_Notification_Dashboard: `${BASE_URL}Common/MultiTableList/SP_DashBoardExpiryNotification`,
      GET_HOLIDAY_DATA: `${BASE_URL}Common/List/SP_Listing_HolidayList`,
      GET_DASHBOARD_TOTAL_ACTIVE_GATEPASS: `${BASE_URL}/Common/List/CLPMS_DASHBOARD_Total_ActiveGatePass`,
      GET_DASHBOARD_WEEKLY_SHIFT_DATA: `${BASE_URL}/Common/List/CLPMS_SP_DASHBOARD_WeeklyShiftData`,

      //      GET_HOLIDAY_DATA: `${BASE_URL}Master/GetAllHolidayMasterListData`,
      GET_MASTER_DATA: `${BASE_URL}Master/getMasterReportData`,
      ADD_MASTER_ITEM: `${BASE_URL}/Master/AddMasterItem`,
      GET_MASTER_ITEM: `${BASE_URL}/Master/GetMasterItemListDataForEdit`,
      UPDATE_MASTER_ITEM: `${BASE_URL}/Master/UpdateMasterItemList`,
      DELETE_MASTER_ITEM: `${BASE_URL}/Master/DeleteMasterItem`,
      GET_PAGES: `${BASE_URL}/Master/GetPage`,
      UPDATE_MODULE_MASTER: `${BASE_URL}/Master/upsertModuleMasterListRelations`,
      GET_LEGISLATION_DATA: `${BASE_URL}/Common/LegislationMaster`,
      GET_UNITS_MASTER_DATA: `${BASE_URL}Common/List/SP_COM_GetAllUnit`,
      INSERT_UPDATE_UNITS_MASTER_DATA: `${BASE_URL}Common/List/SP_Insert_Update_List_UnitMaster`,
      INSERT_UPDATE_ASSOCIATE_ACCIDENT_DATA: `${BASE_URL}Common/List/SP_Insert_Update_Delete_List_AccidentDetails`,
      INSERT_UPDATE_DEPARTMENT_MASTER_DATA: `${BASE_URL}Common/List/SP_Insert_Update_Delete_List_DepartmentMaster`,
      INSERT_UPDATE_MAP_DEPARTMENT_MASTER_DATA: `${BASE_URL}Common/List/SP_Insert_Update_List_UnitDepartmentRelation`,
      RPT_CLPMS_AnomalyDataSummary: `${BASE_URL}Common/List/RPT_CLPMS_AnomalyDataSummary`,
      RPT_DateWiseFreezeAttD2D: `${BASE_URL}Common/List/RPT_DateWiseFreezeAttD2D`,
      RPT_MachineLogD2D: `${BASE_URL}Common/List/RPT_MachineLogD2D`,
      GET_DATA_FROM_ZIPCODE:`${BASE_URL}Common/Nesting/SP_COM_FindZipCode`,
    },
    CONTRACTOR_MANAGEMENT: {
      GET_ALL_RB_API: `${BASE_URL}Common/List/SP_Listing_GetReportBuilderAllApi`,

      // GET_ALL_RB_API: `${BASE_URL}Contractor/GetReportBuilderAllApi`,
      GET_CONTRACTORS: `${BASE_URL}Contractor/GetContractorsFilter`,
      GET_CONTRACTORSBY_DEFAULT: `${BASE_URL}Contractor/ByDefaultSelectedContractor`,
      // GET_CONTRACTOR_Grid: `${BASE_URL}Common/List/SP_Listing_Contractor_Filter`,

      GET_CONTRACTOR_Grid: `${BASE_URL}Contractor/GetContractorsGrid`,
      GET_LICENSE: `${BASE_URL}Contractor/GetLicensesFilter`,
      GET_CONTRACTORS_WCPOLICIES_DATA: `${BASE_URL}Contractor/GetWCPoliciesFilter`,
      GET_CONTRACTORS_COMPANYECPOLICIES_DATA: `${BASE_URL}Company/GetCompanyECPolicyFilter`,
      //   CREATE_CONTRACTOR: `${BASE_URL}ContractorManagement/InsertContractorData`,
      CREATE_CONTRACTOR: `${BASE_URL}Contractor/AddContracor`,
      // GET_CONTRACTOR_CODE: `${BASE_URL}ContractorManagement/GetContractorCode`, // Pending
      // GET_COMPLIENCE_INFO_CODE: `${BASE_URL}ContractorManagement/Getcomplienceinfocode`, // Pending
      GET_CONTRACTOR_ROLE_CODE: `${BASE_URL}ContractorManagement/GetContractorRoleCode`, // Pending
      // GET_STATES: `${BASE_URL}ContractorManagement/GetDataForState`,
      GET_STATES: `${BASE_URL}GlobalMaster/GetStateMasteData`,
      GET_EC_POLICY: `${BASE_URL}ContractorManagement/GetDocumentDataForECPolicy`, // Pending
      GET_DOC_TYPES: `${BASE_URL}Contractor/GetDocumentTypes`, // Pending
      // CHECK_EMAIL_AVAIL: `${BASE_URL}ContractorManagement/CheckAvailiblityForEmail`,
      CHECK_EMAIL_AVAIL: `${BASE_URL}Contractor/CheckAvailiblityForEmail`,
      //   CHECK_PANCARD_AVAIL: `${BASE_URL}ContractorManagement/CheckAvailiblityForPancard`,
      CHECK_PANCARD_AVAIL: `${BASE_URL}Contractor/CheckAvailiblityForPancard`,
      GET_WORKORDER_FILTER: `${BASE_URL}Common/List/SP_Listing_WorkOrder_Filter`,
      SP_ImportExcel_Listing_WorkOrder: `${BASE_URL}Common/List/SP_ImportExcel_Listing_WorkOrder`,
      // GET_WORK_ORDER: `${BASE_URL}ContractorManagement/GetWorkOrderData`,
      GET_WORKORDER_CCOMMON_FILTER: `${BASE_URL}Contractor/GetWorkOrdersFilter`,
      GET_WORK_ORDER: `${BASE_URL}Contractor/GetWorkOrdersFilter`,
      //   GET_WORK_ORDER_CODE: `${BASE_URL}ContractorManagement/GetWorkOrderbyContractor`,
      GET_WORK_ORDER_CODE: `${BASE_URL}Contractor/GetWorkOrderbyContractor`,
      //   GET_EC_POLICYS: `${BASE_URL}ContractorManagement/GetECPolicyData`,
      GET_EC_POLICYS: `${BASE_URL}Contractor/GetWCPoliciesFilter`,
      //   GET_LICENCE: `${BASE_URL}ContractorManagement/GetLicenseData`,
      GET_LICENCE: `${BASE_URL}Contractor/GetLicensesFilter`,
      //   SAVE_CONTRACTOR: `${BASE_URL}ContractorManagement/`,
      SAVE_CONTRACTOR: `${BASE_URL}Contractor/AddNewContracor`,
      //   UPLOAD_DOC: `${BASE_URL}ContractorManagement/AddContractorDoc`,
      UPLOAD_DOC: `${BASE_URL}Contractor/AddContractorDoc`,
      UPLOAD_LABOUR_DOC: `${BASE_URL}Labour/AddLabourDoc`,
      UPLOAD_LABOUR_PHOTO: `${BASE_URL}Labour/AddLabourProfile`,
      UPDATE_LABOUR_PHOTO: `${BASE_URL}Labour/UpdateLabourPhoto`,
      //   GET_BY_ID: `${BASE_URL}ContractorManagement/GetContractorDataForEdit`,
      GET_BY_ID: `${BASE_URL}Contractor/GetContractorDataForEdit`,
      // GET_CONTACT_BY_ID: `${BASE_URL}Contractor/GetContractorContactDataForEdit`,
      UPDATE: `${BASE_URL}Contractor/UpdateContractor`,
      Check_Company_Avaibilty: `${BASE_URL}Contractor/CheckCompany`,
      Check_ShortCode_Avaibilty: `${BASE_URL}Contractor/CheckShortCodeAvailabilityContractor`,
      Check_Email_Avaibilty: `${BASE_URL}Contractor/CheckAvailiblityForEmail`,
      Check_MobileNo_Avaibilty: `${BASE_URL}Contractor/CheckAvailiblityForMobileNo`,
      Check_PanCard_Avaibilty: `${BASE_URL}Contractor/CheckAvailiblityForPancard`,
      // GET_CONTRACTOR_VENDOR_DATA: `${BASE_URL}ContractorManagement/GetContractorVendorData`,
      // GET_CONTRACTOR_VENDOR_DATA: `${BASE_URL}Common/List/SP_Listing_Contractor_Filter`,
      GET_CONTRACTOR_VENDOR_DATA: `${BASE_URL}Contractor/GetContractorsFilter`,
      GET_WO_CONTRACTOR_DATA: `${BASE_URL}ContractorManagement/GetWorkOrderByContractorData`,
      GET_LICENCE_BY_WO: `${BASE_URL}ContractorManagement/GetlicenseByWO`,
      GET_EC_POLICY_BY_WO: `${BASE_URL}ContractorManagement/GetEcpolicyByWO`,
      GET_SUBCONT_BY_WO: `${BASE_URL}ContractorManagement/GetSubContractorByWOData`,
      GET_SUB_WORKORDER_BY_SC: `${BASE_URL}ContractorManagement/GetSubWorkOrderBySCData`,
      GET_LICENCE_BY_SUB_WO: `${BASE_URL}ContractorManagement/GetLicenseBySubWO`,
      GET_SUB_EC_POLICY_BY_WO: `${BASE_URL}ContractorManagement/GetSubEcpolicyByWO`,
      GATE_MASTERDATA1: `${BASE_URL}ContractorManagement/GetMasterItemData1`,
      GATE_EC_WORK_ORDER: `${BASE_URL}ContractorManagement/GetUserEICDataFromWorkorder`,
      GET_CONTRACTOR_PLANT: `${BASE_URL}Contractor/GetContractorFromPlant`,
      GET_CONTRACTOR_DETAIL: `${BASE_URL}Common/List/SP_GetContractorDetails`,
      THIRD_PARTY_EMAILID_CHANGE: `${BASE_URL}Common/List/ThirdpartyEmailIdChange`,
      DUPLICATECHECK_CONTRACTOR_LEGISLATIONREGISTERNO: `${BASE_URL}Common/List/DuplicateCheck_Contractor_LegislationRegisterNo`,
      CONTRACTOR_VIEW_DATAILS: `${BASE_URL}Common/MultiTableList/ContractorViewinDetails`,
    },
    POLICY_MANAGEMENT: {
      GET_POLICY_NUMBER: `${BASE_URL}Common/List/SP_AttPolicy_MaxNumber`,
      GET_POLICY_HISTORY: `${BASE_URL}Common/List/SP_List_AttPolicyHistory`,
    },
    // USER_MANAGEMENT: {
    //   LOGIN: `${BASE_URL}UserManagement/CheckLogin`,BASE_URL
    USER_MANAGEMENT: {
      LOGIN: `${BASE_URL_LOGIN}login`,
      FORGOT_PASSWORD: `${BASE_URL_LOGIN}forgot-password`,
      CHECK_LOGIN: `${BASE_URL}user/CheckLogin`,
      //   GET_MASTER_DATA: `${BASE_URL}UserManagement/GetMasterItemData`,
      GET_MASTER_DATA: `${BASE_URL}Common/List/SP_Listing_ShiftMaster`,
      //   GET_MASTER_DATA: `${BASE_URL}GlobalMaster/GetMasterItemListData`,
      //   GET_SHIFT_MASTER_DATA: `${BASE_URL}Master/GetShiftMasterList`,
      GET_SHIFT_MASTER_DATA: `${BASE_URL}Common/List/SP_Listing_ShiftMaster`,
      //  GET_SHIFT_MASTER_DATA: `${BASE_URL}Master/GetShiftMasterList`,
      //   GET_COMPANY_BUDGET_DATA: `${BASE_URL}CompanyBudget/GetCompanyBudget`,
      GET_COMPANY_BUDGET_DATA: `${BASE_URL}Common/List/SP_Listing_CompanyBudget`,

      //  GET_COMPANY_BUDGET_DATA: `${BASE_URL}CompanyBudget/GetCompanyBudget`,
      GET_USER_INFORMATION_DATA: `${BASE_URL}user/GetUserInformations`,
      GET_COMPLIANCE_PLANT: `${BASE_URL}user/GetComplianceWisePlant`,
      //   GET_COUNTRY_LIST: `${BASE_URL}UserManagement/GetCountryData`,
      GET_COUNTRY_LIST: `${BASE_URL}Common/List/SP_COM_GetAllCountry`,
      // GET_COUNTRY_LIST: `${BASE_URL}GlobalMaster/GetALLCountry`,
      // GET_STATES_OF_COUNTRY: `${BASE_URL}UserManagement/GetStateDataBYCountry`,
      GET_STATES_OF_COUNTRY: `${BASE_URL}Common/List/SP_COM_GetAllState`, // kh 30-04-2021

      //      GET_STATES_OF_COUNTRY: `${BASE_URL}GlobalMaster/GetStateMasteData`,
      // GET_DISTRICTS_OF_STATE: `${BASE_URL}UserManagement/GetDistrictDataBYState`,
      GET_DISTRICTS_OF_STATES: `${BASE_URL}Common/List/SP_List_GetAllDistrict`,
      GET_DISTRICTS_OF_STATE: `${BASE_URL}Common/List/SP_COM_GetAllDistrict`, // kh 30-04-2021

      // GET_DISTRICTS_OF_STATE: `${BASE_URL}GlobalMaster/GetDistrictMasterData`,
      //   GET_CITY_OF_DISTRICT: `${BASE_URL}UserManagement/GetCityDataBYDistrict`,

      GET_CITYS_OF_DISTRICT: `${BASE_URL}Common/List/SP_List_GetAllCity`,
      GET_CITY_OF_DISTRICT: `${BASE_URL}Common/List/SP_COM_GetAllCity`, // kh 30-04-2021
      GET_PO_BY_CITY: `${BASE_URL}Common/List/SP_COM_GetAllPostOffice`,

      //      GET_CITY_OF_DISTRICT: `${BASE_URL}GlobalMaster/GetCityMasterData`,
      //   GET_PO_BY_CITY: `${BASE_URL}UserManagement/GetPostOfficeDataBYCity`,
      // GET_PO_BY_CITY: `${BASE_URL}Common/List/SP_COM_GetAllPostOffice`, // kh 30-04-2021

      //      GET_PO_BY_CITY: `${BASE_URL}GlobalMaster/GetALLPostOffice`,

      //   GET_COUNTRY_DATA: `${BASE_URL}UserManagement/GetCountryData`,
      // GET_COUNTRY_DATA: `${BASE_URL}Common/List/SP_COM_GetAllCountry`,
      // GET_COUNTRY_DATA: `${BASE_URL}GlobalMaster/GetALLCountry`,
      GET_MODULE_PAGE: `${BASE_URL}user/GetModuleWisePage`,
      GET_ACTION_PERMISSION: `assets/data/accessPermission.json`,
      GET_USER_CODE: `${BASE_URL}UserManagement/GetUserCode`,
      GET_LANGUAGE_DATA: `${BASE_URL}UserManagement/GetLanguageData`,
      // GET_TIMEZONE_DATA: `${BASE_URL}UserManagement/GetTimeZoneData`,
      GET_TIMEZONE_DATA: `${BASE_URL}Common/List/SP_COM_TimeZoneMaster`,
      GET_ROLE_LIST: `${BASE_URL}UserManagement/GetDataforRole`,
      // GET_ORGANIZATION_LIST: `${BASE_URL}UserManagement/GetOrganizationTree`,
      GET_DESIGNATION_LIST: `${BASE_URL}UserManagement/GetDesignationfordata`,
      GET_PAGE_ACTION: `${BASE_URL}user/GetPageWiseAction`,
      // GET_PAGE_ACTION: `assets/data/GetPageWiseAction.json`,
      GET_MODULE_ROLE: `${BASE_URL}user/GetRoleWiseModule`,
      CREATE_USER: `${BASE_URL}user/insertUserInfo`,
      EDIT_GRIVANCE: `${BASE_URL}UserManagement/GetGrievanceDataForEdit`,
      POST_GRIEVANCE: `${BASE_URL}UserManagement/InsertGrivanceInformation`,
      USER_LIST: `${BASE_URL}user/GetUserInformations`,
      GET_USER_ISLOCKED: `${BASE_URL}user/GetUserIsLocked`,
      GET_USER_ISACTIVE: `${BASE_URL}user/GetUserIsActive`,
      USER_INFORMATION: `${BASE_URL}UserManagement/GetUserDataForEdit`,
      // USER_INFORMATION: `${BASE_URL}UserManagement/GetUserProfile`,
      UPDATE_USER: `${BASE_URL}UserManagement/UpdateUser`,
      UPDATE_USER_ACCESS_DATA: `${BASE_URL}ActionModule/ModulePageAction`,
      // UPDATE_USER_ACCESS_DATA: `http://localhost:59557/api/ActionModule/ModulePageAction`
      GET_USER_LIST: `${BASE_URL}user/GetUserInformations`,
      GET_MENU_LIST: `${BASE_URL}user/GetMenuTree`,
      GET_EIC_USER_LIST: `${BASE_URL}user/GetUserEICData`,
      CHANGE_USER_PASSWORD: `${BASE_URL}user/ChangePassword`,
      FORGOT_USER_PASSWORD: `${BASE_URL}user/ForgotPassword`,
      RESET_USER_PASSWORD: `${BASE_URL}user/resetUserPassword`,
      SEND_MAIL_FOR_RESETPASSWORD: `${BASE_URL}user/SendMailforResetPW`,
      INSERT_DEPARTMENT_PERMISSION: `${BASE_URL}user/InsertDepartmentAccess`,
      GET_LISING_USERROLES: `${BASE_URL}Common/List/SP_Listing_UserRoles`,
      GET_USER_DATA_ACCESS_RELATION: `${BASE_URL}Common/Nesting/SP_Nesting_UserDataAccessRelation`,
      UPDATE_USER_DATA_ACCESS_RELATION: `${BASE_URL}Common/List/SP_Update_UserDataAccessRelation`,
      GET_USER_PAGE_ACTION_RELATION: `${BASE_URL}Common/Nesting/SP_Nesting_PageActionRelationData`,
      UPDATE_USER_PAGE_ACTION_RELATION: `${BASE_URL}Common/List/SP_Update_UserPageActionRelation`,
      WO_TASK_LIST: "assets/data/wo-task-list/wo-task-list.json",
      WO_TASK_DETAILS: `${BASE_URL}Common/MultiTableList/CLPMS_SL_HRC_TaskDeatils`,
      CLPMS_SP_HRC_WOTaskTotal: `${BASE_URL}Common/MultiTableList/CLPMS_SP_HRC_WOTaskTotal`,
      CLPMA_SP_HRCUndertakingCheckList: `${BASE_URL}Common/List/CLPMA_SP_HRCUndertakingCheckList`,
      CLPMS_SL_HRC_WorkorderTaskDeatilData: `${BASE_URL}Common/List/CLPMS_SL_HRC_WorkorderTaskDeatilData`,
      CLPMs_SP_HRCRequestSubmit: `${BASE_URL}Common/List/CLPMs_SP_HRCRequestSubmit`,
      CLPMS_HRC_RequestActionSubmit: `${BASE_URL}Common/List/CLPMS_HRC_RequestActionSubmit`,
      UploadCommonDoc: `${BASE_URL}Common/Documents/UploadCommonDoc`,
      CLPMS_SP_FinancialYear_V1: `${BASE_URL}Common/List/CLPMS_SP_FinancialYear_V1`,
      SP_GetMonthFromFinacialYearCode: `${BASE_URL}Common/List/SP_GetMonthFromFinacialYearCode`,
      SP_Listing_HRC_Filter: `${BASE_URL}Common/List/SP_Listing_HRC_Filter`,
      CLPMS_SP_InsertUpdate_HRCTaskInput: `${BASE_URL}Common/List/CLPMS_SP_InsertUpdate_HRCTaskInput`,
      SP_GetMasterItemListData_V1: `${BASE_URL}Common/List/SP_GetMasterItemListData_V1`,
      Common_Document_ModulePageVSList: `${BASE_URL}Common/List/Common_Document_ModulePageVSList`,
      Common_Document_ReferacePageWiseDocList: `${BASE_URL}Common/List/Common_Document_ReferacePageWiseDocList`,
      CLPMS_SP_HRCUndertakingSave: `${BASE_URL}Common/BulkInsertUpdateDelete/CLPMS_SP_HRCUndertakingSave`,
      CLPMS_HRC_RequestDetails: `${BASE_URL}Common/MultiTableList/CLPMS_HRC_RequestDetails`,
      Payroll_ComponentGroupTypeWise_List: `${BASE_URL}Common/List/Payroll_ComponentGroupTypeWise_List`,
    },
    LABOUR_MANAGEMENT: {
      GET_LABOUR_PROFILE: `${BASE_URL}Labour/GetLabourProfile`,
      // GET_ALL_LABOUR: `${BASE_URL}Labour/getLabourData`,
      // GET_ALL_LABOUR: `${BASE_URL}Common/List/SP_RPT_LabourData_Listing`,
      GET_ALL_LABOUR_DATA: `${BASE_URL}Labour/GetAlllabourData`,
      GET_ALL_LABOUR_DATA_NEW: `${BASE_URL}Common/List/GetLabourRoleWise_New`,
      // GET_ALL_APPLIEDLEAVE: `${BASE_URL}Master/GetAppliedLeave`, old
      GET_ALL_APPLIEDLEAVE: `${BASE_URL}Common/List/SP_Listing_AppliedLeave`, // new
      // GET_LABOUR_DATA: `${BASE_URL}Common/List/SP_RPT_LabourData_Listing`,
      GET_LABOUR_DATA: `${BASE_URL}Labour/GetAlllabourData`,
      GET_LABOUR: `${BASE_URL}Labour/getLabourData1`,
      GET_LABOUR_BY_CODE: `${BASE_URL}Labour/GetLabourByCode`,
      GET_LABOURDETAIL_BY_CODE: `${BASE_URL}Labour/GetLabourDetailByCode`,
      // GET_ALL_CONTRACTORS: `${BASE_URL}ContractorManagement/GetAllContractorData`,
      GET_ALL_CONTRACTORS: `${BASE_URL}Contractor/GetContractorsFilter`,
      // GET_ESICWAGE: `${BASE_URL}Labour/IsESICWageCriteria`,
      GET_PASS_MANAGEMENT: `${BASE_URL}GatePass/GetWorkOrderByContractor`,
      GET_PASS_MANAGEMENT1: `${BASE_URL}GatePass/`,
      GET_PASS_MANAGEMENT2: `${BASE_URL}GatePass/`,
      GET_PASS_MANAGEMENT3: `${BASE_URL}GatePass/`,
      GET_PASS_COMMON_LIST: `${BASE_URL}Common/List/`,
      GET_LABOUR_MANAGEMENT: `${BASE_URL}LabourManagement/`,
      GET_GATEPASS_REQUESTS: `${BASE_URL}Labour/getLabourData`,
      GET_GATEPASS_REQUESTS_1: `${BASE_URL}Labour/getLabourData1`,
      // GET_GATEPASS_REQUESTS: `\assets\data\gatepass-data.json`,
      //   GET_SHIFT_GROUP_DATA: `${BASE_URL}LabourManagement/GetShiftGroupData`,
      CLPMS_SP_PAYROLL_WORKMANSAlARYMASTER: `${BASE_URL}Common/List/CLPMS_SP_PAYROLL_WorkmanSalaryMaster`,
      CLPMS_SP_INSERTUPDATE_PAYROLLHANDENTRY: `${BASE_URL}Common/BulkInsertUpdateDelete/CLPMS_SP_InsertUpdate_PayrollHandentry`,
      CLPMS_SP_LIST_GATEPASS_PAYROLLHANDENTRY: `${BASE_URL}Common/List/CLPMS_SP_List_Gatepass_PayrollHandentry`,
      GET_SHIFT_GROUP_DATA: `${BASE_URL}Master/GetShiftGroup`,
      GET_SITE_SHIFT_GROUP_DATA: `${BASE_URL}Master/GetSiteShiftGrouplist`,
      //   GET_SKILL_TYPES: `${BASE_URL}LabourManagement/GetSkillTypes`,
      GET_SKILL_TYPES: `${BASE_URL}Common/List/SP_COM_GetAllSkillMaster`,
      GET_MAXIMUM_VALIDITY_DATE: `${BASE_URL}Common/List/SP_Check_GatepasslDetails`,
      APPROVE_MULTIPLE_LABOUR: `${BASE_URL}Labour/ApproveMultipleData`,
      BULK_APPROVAL_API: `${BASE_URL}Common/InsertUsingQuery`,
      // GET_SKILL_TYPES: `${BASE_URL}Master/GetALLSkillData`,
      //   GET_SECTION_DATA: `${BASE_URL}UserManagement/GetSectionData`,
      GET_SECTION_DATA: `${BASE_URL}Common/List/SP_COM_GetAllSection`,

      // GET_SECTION_DATA: `${BASE_URL}GlobalMaster/GetALLSectionData`,
      GET_SECTION_DATA1: `${BASE_URL}Common/List/SP_COM_GetAllSection`,
      // GET_SECTION_DATA1: `${BASE_URL}GlobalMaster/GetALLSectionDataLevelwise`,
      //   GET_DEPARTMENT_DATA: `${BASE_URL}UserManagement/GetDepartmentData`,
      GET_DEPARTMENT_DATA: `${BASE_URL}Common/List/SP_COM_GetAllDepartment`,
      // GET_DEPARTMENT_DATA: `${BASE_URL}GlobalMaster/GetALLDepartmentData`,
      GET_STATE_DATA: `${BASE_URL}UserManagement/GetStateData`,
      GET_PINCODE_DATA: `${BASE_URL}UserManagement/GetPincodeData`,
      GET_WORK_CATEGORY: `${BASE_URL}LabourManagement/GetWorkcategories`,
      GET_ALL_LABOUR_DOC: `${BASE_URL}LabourManagement/AddLabourDoc`,
      //   GET_VILLAGE_BY_POSTOFFICE: `${BASE_URL}LabourManagement/GetVillageByPostOffice`, // NEED TO PASS ID
      GET_VILLAGE_BY_POSTOFFICE: `${BASE_URL}GlobalMaster/GetALLVillage`,
      GET_POST_OFFICE_BY_CITY: `${BASE_URL}LabourManagement/GetPostofficeByCity`, // NEED TO PASS ID
      GET_CITY_BY_DISTRICT: `${BASE_URL}LabourManagement/GetCityByDistrict`, // NEED TO PASS ID
      GET_DISTRICT_BY_STATE: `${BASE_URL}LabourManagement/GetDistrictByState`, // NEED TO PASS ID
      GET_STATE_BY_COUNTRY: `${BASE_URL}LabourManagement/GetStateByCountry`, // NEED TO PASS ID
      //   GET_ALL_TRADE: `${BASE_URL}LabourManagement/GetAllTrade`,
      // GET_ALL_TRADE: `${BASE_URL}Common/List/SP_COM_GetAllTradeMaster`,

      //  GET_ALL_TRADE: `${BASE_URL}Master/GetALLTradeData`,
      GET_ALL_SiteShiftGroup: `${BASE_URL}/Common/SiteShiftGroup`,
      GET_PLAN_DATA: `${BASE_URL}UserManagement/GetPlantData`,
      GET_PLANT_BY_COMPANY: `${BASE_URL}Labour/GetPlantByCompany`, // NEED TO PASS ID,
      GET_POST_OFFICE_DATA: `${BASE_URL}UserManagement/GetPostOfficeData`,
      GET_CITY_DATA: `${BASE_URL}UserManagement/GetCityData`,
      GET_DISTRICT_DATA: `${BASE_URL}UserManagement/GetDistrictData`,
      POST_UPDATE_LABOUR_CONTACT_DETAIL: `${BASE_URL}Labour/UpdateLabourContactDetail`,
      POST_UPDATE_LABOUR: `${BASE_URL}Labour/UpdateLabour`,
      POST_INSERT_LABOUR_DATA: `${BASE_URL}Labour/AddLabour`,
      POST_INSERT_LABOUR_FAMILY_MEMBER_DATA: `${BASE_URL}Labour/AddFamilyInfo`,
      POST_UPDATE_LABOUR_FAMILY_MEMBER_DATA: `${BASE_URL}Common/List/SP_Update_LabourFamily`,
      DELETE_LABOUR_FAMILY_MEMBER_DATA: `${BASE_URL}Common/List/SP_Delete_Family`,
      DELETE_LABOUR_NOMINEE_DATA: `${BASE_URL}Common/List/SP_Delete_Nominee`,
      //   GET_PLANTS: `${BASE_URL}ContractorManagement/GetPlants`,
      GET_PLANTS: `${BASE_URL}GlobalMaster/GetPlantMasterData`,
      //   GET_COMPANY_DATA: `${BASE_URL}LabourManagement/GetCompanyData`,
      GET_ADDRESS_CODE: `${BASE_URL}LabourManagement/GetAddressCode`,
      GET_LABOUR_CODE: `${BASE_URL}LabourManagement/GetLabourCode`,
      GET_WORKSITE_DATA: `${BASE_URL}LabourManagement/GetWorksiteData`,
      GET_GATEPASS_REQUEST_CODE: `${BASE_URL}LabourManagement/GetGatePassRequestCode`,
      //   GET_ALL_DEPARTMENTS: `${BASE_URL}LabourManagement/GetALLDepartment`,
      GET_ALL_DEPARTMENTS: `${BASE_URL}GlobalMaster/GetALLDepartmentData`,
      GET_FILTER_DEPARTMENTS: `${BASE_URL}Common/DepartmentMaster`,
      GET_CONTRACTOR: `${BASE_URL}Common/ContractorVendorMaster`,
      // GET_PAYROLLMONTH: `${BASE_URL}Common/PayrollMonth`,
      GET_PAYROLLMONTH: `${BASE_URL}Common/List/SP_Listing_PayrollMonth`,

      //      GET_PAYROLLMONTH: `${BASE_URL}Master/SP_Listing_PayrollMonth`,
      GET_ALL_WS_CATEGORIES: `${BASE_URL}Common/MasterItemList`,
      //   GET_ALL_WS_CATEGORIES: `${BASE_URL_NEW}Labour/GetALLWorkSiteCategory`, // Pending
      //   GET_ALL_PLANTS: `${BASE_URL}Labour/GetALLPlant`,
      GET_ALL_PLANTS: `${BASE_URL}Common/List/SP_Listing_PlantMaster_Filter`,
      // GET_ALL_PLANTS: `${BASE_URL}GlobalMaster/GetPlantMasterData`,
      //   GET_ALL_SKILLS: `${BASE_URL}Labour/GetALLSkill`,
      GET_ALL_SKILLS: `${BASE_URL}Common/List/SP_COM_GetAllSkillMaster`,

      // GET_ALL_SKILLS: `${BASE_URL}Master/GetALLSkillData`,
      GET_GATE_HR_APPROVAL_DATA: `${BASE_URL}Labour/GateHRApprovalData`,
      GET_GATE_APPROVAL_DATA: `${BASE_URL}Labour/GateApprovalData`,
      GET_DOC_FOR_LABOUR: `${BASE_URL}Labour/GetDocumentForLabour`,
      GET_LABOUR_BY_WORK_ORDER: `${BASE_URL}Labour/GetLabourByWorkorder`,
      GET_LABOUR_VERIFY_DATA: `${BASE_URL}Labour/getLabourVerifyData`,
      GET_DOC_VERIFY_DATA: `${BASE_URL}Labour/GetDocumentForLabourVerify`,
      GET_HISOTRY_OF_LABOUR: `${BASE_URL}Labour/GetHistoryData`,
      POST_LABOUR_VERIFICATIONS: `${BASE_URL}Labour/LabourVarifications`,
      POST_ACTION_SAVE_APPROVE: `${BASE_URL}Labour/ActionSaveForMultipleApprove`,
      GET_ALL_SECTION: `${BASE_URL}Common/List/SP_COM_GetAllSection`,

      // GET_ALL_SECTION: `${BASE_URL}GlobalMaster/GetALLSectionData`,
      SEND_MAIL_FOR_APPROVAL: `${BASE_URL}Labour/SendMailForApproval`,
      // POST_IMPORT_LABOUR: `${BASE_URL}LabourImport/ImportData`,
      POST_IMPORT_LABOUR: `${BASE_URL}Labour/ImportData`,
      POST_IMPORT_LABOUR_HR: `${BASE_URL}Labour/ImportWithHRApproval`,
      IMPORT_SUBCONTRACTOR_LABOUR_HR: `${BASE_URL}Labour/ImportWithSubContractorLabourHRApproval`,
      VALIDATE_WORK_ORDER_CONTACTOR: `${BASE_URL}Labour/CheckWorkOrderIsSameContractor`,
      VALIDATE_SubWORK_ORDER_CONTACTOR: `${BASE_URL}Labour/CheckSubWorkOrderIsSameContractor`,
      VALIDATE_SubLicence_CONTACTOR: `${BASE_URL}Labour/CheckSubLicenceIsSameContractor`,
      VALIDATE_SubECpolicy_CONTACTOR: `${BASE_URL}Labour/CheckSubecpolicyIsSameContractor`,
      VALIDATE_LICENCE_CONTACTOR: `${BASE_URL}Labour/CheckLicenseIsSameContractor`,
      VALIDATE_EC_POLICY_CONTACTOR: `${BASE_URL}Labour/CheckECPolicyIsSameContractor`,
      VALIDATE_SUBCONTRACTOR_CONTACTOR: `${BASE_URL}Labour/CheckSubcontractorSameContractor`,
      VALIDATE_ULC: `${BASE_URL}Labour/CheckULCIsExist`,
      VALIDATE_GATE_PASS: `${BASE_URL}Labour/CheckGatepassNoIsExist`,
      VALIDATE_GATE_PASS_ULC: `${BASE_URL}Labour/CheckGatepassNoAndULCIsExist`,
      VALIDATE_GATE_PASS_ULC_Exsit: `${BASE_URL}Labour/CheckULcAgainstgatepassno`,
      VALIDATE_GATE_PASS_ULC_IDENTITY: `${BASE_URL}Labour/CheckULcAgainstgatepassnoAndAddharNo`,
      VALIDATE_GATE_PASS_ULC_NEW: `${BASE_URL}Labour/CheckULcGatePassForNew`,
      VALIDATE_ADHAR_CARD_NUMBER: `${BASE_URL}Common/List/SP_Check_Duplicate_AadharCard`,
      GET_State: `${BASE_URL}Common/List/SP_COM_GetAllState`,

      //      GET_State: `${BASE_URL}GlobalMaster/GetStateMasteData`,
      GET_LeaveType: `${BASE_URL}Common/List/SP_Listing_LeaveTypeList`,

      //  GET_LeaveType: `${BASE_URL}Master/GetLeaveTypeData`,
      GET_AppliedLeaveType: `${BASE_URL}Common/List/SP_COM_AppliedLeaveType`,
      GET_SECTIONHEAD: `${BASE_URL}Common/List/SP_Listing_SectionHead`,
      //      GET_SECTIONHEAD: `${BASE_URL}Master/GetSectionHead`,
      GET_LeaveType_LeaveBalance: `${BASE_URL}Common/List/SP_Listing_LeaveTypeList_LeaveBalanceProcess`,

      // GET_LeaveType_LeaveBalance: `${BASE_URL}Master/GetLeaveTypeDatainLB`,
      // GET_AppliedLeaveType: `${BASE_URL}Master/GetAppliedLeaveType`,
      GET_LeaveTypes: `${BASE_URL}Common/List/SP_COM_GetAllLeaveTypes`,

      // GET_LeaveTypes: `${BASE_URL}Master/GetLeaveTypes`,
      GET_LABOURNAME: `${BASE_URL}Common/List/SP_LabourName_Suggestion`,
      //      GET_LABOURNAME: `${BASE_URL}Master/GetSuggestedLabour`,
      // GET_ASSIGNED_LEAVETYPE: `${BASE_URL}Master/GetLeaveTypeAssignedByCompany`,
      // GET_LEAVEBALANCE: `${BASE_URL}Master/GetLeaveBalanceThroughLeaveType`,
      GET_LEAVEBALANCE: `${BASE_URL}Common/List/SP_Listing_LeaveBalanceByLeaveType_Adv`,
      GET_COFF_DATEWISE_ACTIVITY: `${BASE_URL}Common/List/SP_GetCoff_Datewise_Listing`,
      GET_LISING_LEAVEBALANCE: `${BASE_URL}Common/List/SP_Listing_LeaveBalance`,
      GET_GLOBALDATA: `${BASE_URL}Common/GlobalSettingUserDefine`,
      POST_INSERT_LEAVETYPE_DATA: `${BASE_URL}Contractor/AddLeaveType`,
      POST_INSERT_LABOUR_DATA_FOR_LEAVE: `${BASE_URL}Contractor/ApplyLeave`,
      POST_UPDATE_LABOUR_DATA_FOR_LEAVE: `${BASE_URL}Contractor/UpdateLeave`,
      GET_APPLIEDLEAVE_BY_CODE: `${BASE_URL}Common/List/Get_LeaveApplicable_Data`,
      DELETE_APPLIEDLEAVE_BY_CODE: `${BASE_URL}Contractor/DeleteAppliedLeaveByCode`,
      POST_INSERT_LEAVEBALANCE_DATA: `${BASE_URL}Contractor/AddLeaveBalance`,
      // GET_DEPARTMENT_TRAINING_DATA: `${BASE_URL}Common/List/SP_Listing_DepartmentTraining`,
      GET_DEPARTMENT_TRAINING_DATA: `${BASE_URL}Labour/GetDepartmentTrainingData`,
      SAVE_LABOUR_TRAINING: `${BASE_URL}Common/List/SP_Insert_LabourTainingDataS`,
      GET_CGMPT_TRAINING_DATA: `${BASE_URL}Common/List/SP_Listing_CGMP_Training`,
      //      SAVE_LABOUR_TRAINING: `${BASE_URL}Labour/InsertLabourTrainingDeatil`,
      SAVE_LABOUR_TRAINING_WITH_INDIVIDUAL_DOC: `${BASE_URL}Labour/InsertLabourTrainingDeatilWithIndividualdoc`,
      GET_DEPARTMENT_TRAINING_DATA_FOR_FILTER: `${BASE_URL}Labour/GetDepartmentTrainingDataForFilter`,
      GET_DEPARTMENT_BY_UNIT: `${BASE_URL}Common/List/SP_GetAllDepartment_BY_Unit`,
      // GET_DEPARTMENT_BY_UNIT: `${BASE_URL}GlobalMaster/GetDepartmentByUnit`,
      UPLOAD_DEPARTMENT_DOC: `${BASE_URL}Labour/AddDepartmentTrainingDoc`,
      UPLOAD_DEPARTMENT_SINGLE_DOC: `${BASE_URL}Labour/AddDepartmentTrainingSingleDoc`,
      GET_LEAVE_CRITERIA: `${BASE_URL}Common/List/SP_Listing_LeaveTypeCriteria`,
      GET_LABOUR_ATTENDANCE: `${BASE_URL}Common/List/SP_RPT_CLPMS_MM_TimeCard_PlantWise`,
      GET_LAST_PLANT_PRESENT_STATUS: `${BASE_URL}Common/List/SP_GET_LastPlantPresentStatus`,
      GET_LEAVE_CRITERIA_BY_DATES: `${BASE_URL}Common/List/SP_GET_LEAVEDETAIL_BY_DATE`,
      SUSPEND_LABOUR: `${BASE_URL}Common/List/SP_Add_LabourSuspensionData`,
      GET_SUSPEND_LABOUR: `${BASE_URL}Common/List/SP_GetLabourSuspensionData`,
      CLPMS_SP_Update_GatepassRequestDetails: `${BASE_URL}Common/List/CLPMS_SP_Update_GatepassRequestDetails`,
      CLPMS_SP_ShiftGroup_Insert_Update: `${BASE_URL}Common/List/CLPMS_SP_ShiftGroup_Insert_Update`,
      CLPMS_SP_GatePassDetails: `${BASE_URL}Common/List/CLPMS_SP_GatePassDetails`,
      CLPMS_SP_GetContracotWiseLicenseList: `${BASE_URL}Common/List/CLPMS_SP_GetContracotWiseLicenseList`,
      CLPMS_SP_GetContracotWiseWorkOrderList: `${BASE_URL}Common/List/CLPMS_SP_GetContracotWiseWorkOrderList`,
      CLPMS_SP_GetWorkOrderWisePlantList: `${BASE_URL}Common/List/SP_Listing_WorkOrderVsPlant`,
      CLPMS_SP_GatePassList_4_Request: `${BASE_URL}Common/List/CLPMS_SP_GatePassList_4_Request`,
      CLPMS_SP_LeaveDelete: `${BASE_URL}Common/List/CLPMS_SP_LeaveDelete`,
      CLPMS_SP_GatePassCloseDateUpdate: `${BASE_URL}Common/BulkInsertUpdateDelete/CLPMS_SP_GatePassCloseDateUpdate`,
      ContractorStatusCheck_N_Update: `${BASE_URL}Common/List/ContractorStatusCheck_N_Update`,
      COMMAN_SP_ApplicationAlertMSG: `${BASE_URL}Common/List/COMMAN_SP_ApplicationAlertMSG`,
    },
    ATTENDANCE_MANAGEMENT: {
      // GET_EMPLOYEE_WISE_MANUAL_PUNCHES: `assets/data/manual-punch-list.json`,
      GET_EMPLOYEE_WISE_MANUAL_PUNCHES: `${BASE_URL}Common/List/SP_List_ManualPunchForEditing`,
      GET_STATUS_LIST: `${BASE_URL}Common/List/SP_Get_StatusList`,
      // GET_EMPLOYEE_WISE_MANUAL_PUNCH_DETAILS: `assets/data/punch-ins.json`,
      GET_EMPLOYEE_WISE_MANUAL_PUNCH_DETAILS: `${BASE_URL}Common/List/SP_LIST_LabourPunchDetails`,
      // SAVE_RECORD: `assets/data/punch-ins.json`,
      // GET_All_ACTIVELABOUR_DATA: `${BASE_URL}Common/List/SP_Listing_ActiveLabourForWeekOff`,

      GET_All_ACTIVELABOUR_DATA: `${BASE_URL}Attendance/GetActiveLabourWeekOffForSelection`,
      POST_LABOUR_WEEKOFFDETAIL: `${BASE_URL}Attendance/SaveWeekOffChangeForLabour`,
      GET_All_LABOURWEEKOFF_DATA: `${BASE_URL}Attendance/GetLabourWeekOffChange`,
      // GET_COFF_Filter_Data: `${BASE_URL}Attendance/GetCoffFilterListingData`,
      GET_ALL_EXCEPTIONAL_DATA: `${BASE_URL}Attendance/GetExceptionalAttendance`,
      GET_APPROVE_ATTENDANCE_LIST: `${BASE_URL}Common/List/SP_PRT_Listing_EICApproval`,
      // GET_APPROVE_ATTENDANCE_LIST: `${BASE_URL}ManPowerAllocation/SectionHeadRppovalDataByUserID`,
      IMPORT_MANUAL_ATTENDANCE_LIST: `${BASE_URL}ManPowerAllocation/UplodExcelData`,
      GET_Attendance_Aprroval_Grid: `${BASE_URL}ManualPunch/GetManualPunchForApproval`,
      GET_RoleCodeByUserID: `${BASE_URL}user/GetUserRolesByUSerID`,
      GET_LIST_WRONGPUNCH: `${BASE_URL}Common/List/SP_LIST_Wrongpunch`,
      PUNCH_TRNASFER: `${BASE_URL}Common/InsertUpdateDelete/AutoGetPunchFromBiosServerTo_CIPLA_HRMS_V1`,
      UPDATE_WRONGPUNCH: `${BASE_URL}Common/InsertUpdateDelete/SP_Update_WrongPunch`,
      REVERT_WRONGPUNCH: `${BASE_URL}Common/InsertUpdateDelete/SP_Revert_WrongPunch`,
      UPDATE_EXCEPTIONAL_ATTENDANCE: `${BASE_URL}Common/InsertUpdateDelete/SP_Submit_ExceptionalAttendanceData`,
      IMPORT_MANUAL_ATTENDANCE_AND_APPROVE_LIST: `${BASE_URL}ManPowerAllocation/UplodExcelDataForApproval`,
      VALIDATE_DATE_AND_PINTIME: `${BASE_URL}ManualPunch/validatedateanpintime`,
      VALIDATE_DATE_AND_POUTTIME: `${BASE_URL}ManualPunch/validatedateanpouttime`,
      VALIDATE_DATE_AND_UINTIME: `${BASE_URL}ManualPunch/validatedateanuintime`,
      VALIDATE_DATE_AND_UOUTTIME: `${BASE_URL}ManualPunch/validatedateanuouttime`,
      SUBMITREQUEST_WEEK_OFF_DATA: `${BASE_URL}Attendance/SubmitRequestForChangeWeekOff`,
      APPROVE_WEEK_OFF_CHANGE_REQUEST: `${BASE_URL}Attendance/ApprovedRequestForChangeWeekOff`,
      GET_PRIVIOUSDAY_RECORD: `${BASE_URL}Common/List/SP_List_PriviousDay_record`,
      DELETE_WRONGPUNCH: `${BASE_URL}Common/InsertUpdateDelete/SP_Delete_WrongPunch`,
      DELETE_RECORD: `${BASE_URL}Common/InsertUpdateDelete/Delete_Submit_records`,
      // GET_COFF_LISTING: `${BASE_URL}Common/List/SP_COFF_Listing`,
      APPROVE_RECORD: `${BASE_URL}Common/InsertUpdateDelete/SP_Approved_COFF_Request`,
      APPROVE_COFF_DATA: `${BASE_URL}Attendance/ApproveCoffData`,
      REJECTED_COFF_DATA: `${BASE_URL}Attendance/RejectedCoffData`,
      APPROVE_COFF_DATA_PROCESS: `${BASE_URL}Attendance/ApproveCoffDataProcess`,
      GET_DATA_BY_EXCEPTIONAL_ATTENDANCE: `${BASE_URL}Attendance/InsertExceptionalData`,
      GET_INSERT_EXCEPTIONAL_ATTENDANCE: `${BASE_URL}Common/List/SP_Insert_Exceptional_Attendance`,
      LABOUR_PUNCH_DATA: `${BASE_URL}Common/List/SP_List_Single_Labour_Wrongpunch`,
      ATTENDANCE_PUNCH_DATA: `${BASE_URL}Common/List/SP_List_ManualPunchForEditing`,
      LABOUR_PUNCH_DATA1: `${BASE_URL}Common/List/AutoGetPunchFromBiosServerTo_CLPMS_HRMS_V1`,
      GET_ACTIVELABOUR_DATA: `${BASE_URL}Attendance/GetActiveLabourForSingleContractorSelection`,
      GET_ACTIVELABOUR_DATA_Syc: `${BASE_URL}Attendance/GetActiveLabourForSingleContractorSelectionSyc`,
      GET_ACTIVELABOUR_DATA_SYC_NEW: `${BASE_URL}Attendance/SP_Listing_ActiveLabourForWeekOffSyc`,
      SAVE_SINGLE_PUNCH: `${BASE_URL}Common/List/SP_Insert_SingleManualPunch`,
      GATEPASS_WISE_SHIFT_INFO: `${BASE_URL}Common/List/SP_GatePassWiseShiftInfo `,
      CHANGE_SHIFT: `${BASE_URL}Common/List/SP_ChangeShift `,
      CHECK_WEEK_OFF_DATE_DUPLICATION: `${BASE_URL}Common/List/SP_DuplicateCheck_4_Actual_WO`,
      CHECK_WEEK_OFF_SHIFT_DATE_DUPLICATION: `${BASE_URL}Common/List/SP_DuplicateCheck_4_Change_WO`,
      GET_LISTING_ACTIVE_LABOUR: `${BASE_URL}Common/List/SP_Listing_ActiveLabourD2D`,
      CLPMS_SP_ATT_EXCEL_BulkDeleteEnrollment: `${BASE_URL}Common/List/CLPMS_SP_ATT_EXCEL_BulkDeleteEnrollment`,
      CLPMS_SP_PAYROLL_Labour_Insert_Update_HandEntryOverride: `${BASE_URL}Common/BulkInsertUpdateDelete/CLPMS_SP_PAYROLL_Labour_Insert_Update_HandEntryOverride`,
    },
    GRIVANCE_MANAGMENT: {
      GATE_MASTER_ITEM_GRIVANCE: `${BASE_URL}UserManagement/GetMasterItemDataForGrivance`,
      GATE_DOCUMENT_DATA: `${BASE_URL}ContractorManagement/GetDocumentData`,
      GATE_EMAIL_SEND_VALUE: `${BASE_URL}ContractorManagement/GetEmailSendValue`,
      GATE_ALL_CONTRACTOR_DATA: `${BASE_URL}ContractorManagement/GetAllContractorData`,
      GATE_GRIVANCE_CODE: `${BASE_URL}UserManagement/GetGrivanceCode`,
      GATE_DOCUMENT_TYPES: `${BASE_URL}ContractorManagement/GetDocumentTypes`,
      GATE_GRIVANCE_DATA_BY_FILTER: `${BASE_URL}UserManagement/GetGrievanceData`,
      GATE_EC_PLANT: `${BASE_URL}ContractorManagement/GetECPlant`,
      GET_PLANTS: `${BASE_URL}ContractorManagement/GetPlants`,
      GET_REQUEST_TYPE_CODE_BY_SHORTCUTCODE: `${BASE_URL}GetRequestTypeCodeByShortCode?requesttypecode=RT104`,
      GATE_CONTRACT_VENDOR_DATA: `${BASE_URL}ContractorManagement/GetContractorVendorData`,
      GATE_ALL_DEPARTMENT: `${BASE_URL}Labour/GetALLDepartment`,
      GATE_ALL_WORK_SITE_CATEGORY: `${BASE_URL}Labour/GetALLWorkSiteCategory`,
      GATE_ROLE_BY_SHORTCUT_CODE: `${BASE_URL}GatePassManagement/GetRoleCodeByShortCode`,
    },
    NOTIFICATION_MANAGMENT: {
      GATE_LABOUR_PENDING_DATA: `${BASE_URL}NotificationManagement/getLabourDataForPending`,
    },
    HRC_MANAGEMENT: {
      GET_HRC_LIST: `assets/data/HRCList.json`,
    },
    SHIFT_MANAGEMENT: {
      GET_PLANTS: `${BASE_URL}ContractorManagement/GetPlants`,
      // GET_PLANTS: `${BASE_URL}GlobalMaster/GetPlantMasterData`,
      GET_COMPANY_DATA: `${BASE_URL}Labour/GetCompanyData`,
      GET_All_SHIFT_GROUP_DATA: `${BASE_URL}Common/List/SP_Listing_ShiftGroupMaster`,
      // GET_All_SHIFT_GROUP_DATA: `${BASE_URL}Master/GetShiftGroup`,
      GET_SHIFT_GROUP_CODE: `${BASE_URL}ShiftManagement/GetShiftGroupCode`,
      GET_SHIFTS: `${BASE_URL},Common/List/SP_Listing_ShiftMaster`,
      // GET_SHIFTS: `${BASE_URL}Master/GetShiftMasterlist`,
      GET_SHIFTGROUPBYID: `${BASE_URL}Shift/GetShiftGroupProfile`,
      POST_INSERT_SHIFTGROUP_DATA: `${BASE_URL}Shift/InsertShiftGroupData`,
      POST_UPDATE_SHIFTGROUP_DATA: `${BASE_URL}Shift/UpdateShiftGroup`,
    },
    // WORK_ORDER: {
    //   GET_CONTRACTORS: `${BASE_URL}Contractor/GetContractorsFilter`,
    //   GET_BYDEFAULTCONTRACTORS: `${BASE_URL}Contractor/GetByDefaultContractor`,
    //   GET_WORK_ORDER_BY_CONTRACTOR: `${BASE_URL}Contractor/GetWorkOrderbyContractor`,
    //   GET_WORK_ORDERS: `${BASE_URL}Contractor/GetWorkOrdersFilter`,
    //   GENERATE_WO_NO: `${BASE_URL}Contractor/GenerateWONo`,
    //   ADD_WORKORDER: `${BASE_URL}Contractor/AddWorkOrder`,
    //   UPDATE_WORKORDER: `${BASE_URL}Contractor/UpdateWorkOrder`,
    //   DELETE_WORKORDER: `${BASE_URL}Contractor/DeleteWorkOrder`,
    //   ADD_DOCUMENT: `${BASE_URL}Contractor/AddWorkOrderDocument`,
    //   GET_WORK_ORDERS_BY_WORK_ORDER_CODE: `${BASE_URL}Contractor/GetWorkOrderDataForEdit`,
    //   CHECKWONOAVAILABILITY: `${BASE_URL}Contractor/CheckWONoAvailabilityForMainCon`
    // },
    WORK_ORDER: {
      GET_CONTRACTORS: `${BASE_URL}Contractor/GetContractorsFilter`,
      GET_BYDEFAULTCONTRACTORS: `${BASE_URL}Contractor/GetByDefaultContractor`,
      GET_CONTRACTORSBYDEFAULT: `${BASE_URL}Contractor/GetByDefaultContractor`,
      GET_CONTRACTORSBY_DEFAULT: `${BASE_URL}Contractor/ByDefaultSelectedContractor`,
      GET_WORK_ORDER_BY_CONTRACTOR: `${BASE_URL}Contractor/GetWorkOrderbyContractor`,
      GET_WORK_ORDERS: `${BASE_URL}Contractor/GetWorkOrdersFilter`,
      GENERATE_WO_NO: `${BASE_URL}Contractor/GenerateWONo`,
      ADD_WORKORDER: `${BASE_URL}Contractor/AddWorkOrder`,
      UPDATE_WORKORDER: `${BASE_URL}Contractor/UpdateWorkOrder`,
      DELETE_WORKORDER: `${BASE_URL}Contractor/DeleteWorkOrder`,
      ADD_DOCUMENT: `${BASE_URL}Contractor/AddWorkOrderDocument`,
      GET_WORK_ORDERS_BY_WORK_ORDER_CODE: `${BASE_URL}Contractor/GetWorkOrderDataForEdit`,
      CHECKWONOAVAILABILITY: `${BASE_URL}Contractor/CheckWONoAvailabilityForMainCon`,
      SP_Listing_Contractor_Filter_V1: `${BASE_URL}Common/List/SP_Listing_Contractor_Filter_V1`,
      SP_GET_CONTRACTOR_WISE_WORK_ORDER_LIST: `${BASE_URL}Common/List/CLPMS_SP_GetContracotWiseWorkOrderList`,
      RPT_WorkOrderFORM3: `${BASE_URL}Common/List/RPT_WorkOrderFORM3`,
    },
    ECPOLICY: {
      GET_ECPOLICY: `${BASE_URL}Contractor/GetECPolicyProfile`,
      ADD_ECPOLICY: `${BASE_URL}Contractor/AddECPolicy`,
      UPDATE_ECPOLICY: `${BASE_URL}Contractor/UpdateECPolicy`,
      DELETE_ECPOLICY: `${BASE_URL}Contractor/DeleteECPolicy`,
      CheckECPolicyNoAvailability: `${BASE_URL}Contractor/CheckECPolicyNoAvailability`,
      ADD_DOCUMENT: `${BASE_URL}Contractor/AddECPolicyDoc`,
      GET_DOC_TYPES: `${BASE_URL}Contractor/GetDocumentsTypesForECPolicy`,
      GET_WC_POLICY: `${BASE_URL}Contractor/GetWCPoliciesFilter`,
      GET_EARLY_OUT_POLICY: `${BASE_URL}GlobalMaster/SP_Insert_Update_Delete_List_EarlyOutPolicy`,
      GET_LATE_IN_POLICY: `${BASE_URL}GlobalMaster/SP_Insert_Update_Delete_List_LateInPolicy`,
      GET_LIST_OF_POLICY: `${BASE_URL}GlobalMaster/SP_CHECK`,
      GET_ABSENT_POLICY: `${BASE_URL}GlobalMaster/SP_Insert_Update_Delete_List_AbsentPolicy`,
      GET_HALFDAY_POLICY: `${BASE_URL}GlobalMaster/SP_Insert_Update_Delete_List_HalfDayPolicy`,
      GET_OT_POLICY: `${BASE_URL}GlobalMaster/SP_Insert_Update_Delete_List_OTPolicy`,
      GET_COFF_POLICY: `${BASE_URL}GlobalMaster/SP_Insert_Update_Delete_List_COFFPolicy`,
      GET_LUNCH_POLICY: `${BASE_URL}GlobalMaster/SP_Insert_Update_Delete_List_LunchPolicy`,
      SP_Listing_WCPolicy_Filter: `${BASE_URL}Common/List/SP_Listing_WCPolicy_Filter`,
      SP_ImportExcel_Listing_ECPolicy: `${BASE_URL}Common/List/SP_ImportExcel_Listing_ECPolicy`,
    },
    LICENSE: {
      GET_LICENSE: `${BASE_URL}Contractor/GetLicensesFilter`,
      GET_LICENCE_BY_CODE: `${BASE_URL}Contractor/GetLicenseForProfile`,
      ADD_LICENSE: `${BASE_URL}Contractor/AddLicense`,
      UPDATE_LICENSE: `${BASE_URL}Contractor/UpdateLicense`,
      DELETE_LICENSE: `${BASE_URL}Contractor/DeleteLicense`,
      Check_LicenseNo_Availability: `${BASE_URL}Contractor/CheckLicenseNoAvailiblity`,
      ADD_DOCUMENT: `${BASE_URL}Contractor/AddLicenseDoc`,
      GET_DOCUMENT_TYPE: `${BASE_URL}Contractor/GetDocumentsTypesForLicense`,
      GET_CONTRACTORSBY_DEFAULT: `${BASE_URL}Contractor/ByDefaultSelectedContractor`,
      SP_LISTING_LICENSE_FILTER: `${BASE_URL}Common/List/SP_Listing_License_Filter`,
      SP_ImportExcel_Listing_License: `${BASE_URL}Common/List/SP_ImportExcel_Listing_License`,
    },
    REPORTS: {
      GET_WC_POLICY: `${BASE_URL}Contractor/GetWCPoliciesFilter`,
      GET_DAILY_PRESENT: `${BASE_URL}Report/GetDailyPresentDetails`,
      GET_ATTENDANCE_STATUS: `${BASE_URL}Report/GetAttendanceStatusDetails`,
      GET_ABSENT_PRESENT: `${BASE_URL}Report/GetAbsentPresentDetails`,
      GET_CONTRACTMAN_PRESENT: `${BASE_URL}Report/GetContractmanPresentDetails`,
      GET_CONTRACTMAN_ABSENT: `${BASE_URL}Report/GetContractmanAbsentDetails`,
      GET_CONTINUES_PRESENT: `${BASE_URL}Report/GetContinuesPresentDetails`,
      GET_SHIFTWISE_PRESENT: `${BASE_URL}Report/GetShiftwisePresentDetails`,
      GET_PUNCH_FROM_READER: `${BASE_URL}Common/List/SP_RPT_PunchfromReaderReport`,
      //      GET_PUNCH_FROM_READER: `${BASE_URL}Report/GetPunchFromReaderDetails`,
      GET_UNITWISE_PRESENT: `${BASE_URL}Report/getDailyUnitwisePresentDetails`,
      GET_UNITWISE_PRESENT_CMN: `${BASE_URL}Common/List/SP_RPT_DailyUnitWisePresentDetails`,
      GET_UNITWISE_PRESENT_EARLY: `${BASE_URL}Common/List/SP_RPT_DailyEarlyOutAndLatIn`,
      GET_REGISTER_REPORT_CMN: `${BASE_URL}Common/List/SP_RPT_LabourRegisterReport`,
      GET_UNITWISE_ABSENT_CMN: `${BASE_URL}Common/List/SP_RPT_DailyUnitWiseAbsentDetails`,
      GET_PLANTWISE_PRESENT_CMN: `${BASE_URL}Common/List/SP_RPT_DailyPlantWisePresentDetails`,
      GET_TIMECARD_PLANTWISE_CMN: `${BASE_URL}Common/List/SP_RPT_CLPMS_MM_TimeCard_PlantWise`,
      GET_COFF_LISTING_FILTERWISE: `${BASE_URL}Common/List/SP_GetCoffListing_Filter`,
      GET_PDAILY_PRESENT_PLANTUNITWISE: `${BASE_URL}Report/GetDailyPresentDetailsWithPlantandUnitWise`,
      GET_FORM_D_REPORT: `${BASE_URL}Report/GetMonthlyAttendanceReport`,
      // GET_FORM_D_REPORT: `assets/data/GetMonthlyAttendanceReport.json`,
      GET_MONTHLY_ATT_WITH_OT_REPORT: `${BASE_URL}Report/GetMonthlyAttendanceWithOTReport`,
      // GET_MANPOWERREQVSREQ_SUMMARY_REPORT: `${BASE_URL}Report/GetManPowerRequitionVSRequirementSummary`,
      GET_MANPOWERREQVSREQ_SUMMARY_REPORT: `${BASE_URL}Common/Nesting/SP_RPT_ManPowerRequitionVSRequest`,
      GET_CONTRACTOR_PENALTY_FEE_REPORT: `${BASE_URL}Common/List/CLPMS_SP_RPT_ContractorPenaltyM2M`,
      GET_TIMECARD_DATA: `${BASE_URL}Report/GetTimeCardReportData`,
      GET_TIMECARD_PlantWise_DATA: `${BASE_URL}Report/GetTimeCardPlantWiseReportData`,
      GET_DAILY_ATTENDANCE_REPORT: `${BASE_URL}Report/GetAttendanceDetails`,
      GET_FILTER: `${BASE_URL}Report/GetALLSavedFilterDescripationUserWise`,
      GET_FILTER_PAGEWISE: `${BASE_URL}Report/GetALLSavedFilterBYUserAndPageWise`,
      ADD_FILTER: `${BASE_URL}Report/InsertFilterDescripation`,
      UPDATE_FILTER: `${BASE_URL}Report/UpdateFilterDescripation`,
      DELETE_FILTER: `${BASE_URL}Report/DeleteFilterDescripation`,
      DELETE_FILTER_PAGEWISE: `${BASE_URL}Report/DeleteFilterByPagecode`,
      GET_CONT_REGISTER_REPORT: `${BASE_URL}Report/GetContractorRegisterReport`,
      GET_LABOUR_REGISTER_REPORT: `${BASE_URL}Report/GetLabourRegisterReport`,
      GET_MONTHLY_ATTENDANCE_REPORT: `${BASE_URL}Report/GetMonthlyAttendanceReport`,
      GET_DAILY_REQUIREMENT_REPORT: `${BASE_URL}Report/GetDailyRequirementReport`,
      GET_EXTRA_WORKING_HOURS_REPORT: `${BASE_URL}Report/getExtraWorkingHrDetails`,
      GET_JOINING_AND_RESIGNING_DATA: `${BASE_URL}Report/GetJoiningMedicalPoliceverificationReportData`,
      GET_TOTAL_DAILY_REQUIREMENT_DATA: `${BASE_URL}Common/List/SP_RPT_DD_TotalDailyRequirement`,

      //      GET_TOTAL_DAILY_REQUIREMENT_DATA: `${BASE_URL}Report/GetTotalDailyRequirementData`,
      GET_GATEPASS_MASTER_DATA: `${BASE_URL}Report/GetMasterDataofGatepass`,
      GET_CONT_WCPOLICY_DETA: `${BASE_URL}Report/GetContractorWiseWCPolicyDetails`,
      GET_CONT_LICE_DETAIL: `${BASE_URL}Report/GetContractorWOWiceLicenseDetails`,
      GET_CONT_WISE_WORK: `${BASE_URL}Report/GetContractorWiseWorkOrderDetails`,
      GET_CONT_WISE_SUB_CONT: `${BASE_URL}Report/GetWorkOrderWiseSubContDetails`,
      GET_WORK_ORDER_SUB_CONT_WORKDETAIL: `${BASE_URL}Report/GetWorkOrderWiseSubContWorkOrderDetails`,

      // GET_FORM_D_REPORT: ''
      GET_ASSOCIATE_DUMP: `${BASE_URL}Common/List/SP_RPT_GetAssociateDump`,

      GET_PROFILE_NOT_FOUND_LIST: `${BASE_URL}Common/List/SP_RPT_ProfileNotFound_D2D`,
      PAYROLL_PROCESS: `${BASE_URL}Common/List/HRMS_SP_PayrollProcessByUEGCodeN_M2M`,
      GET_GATEPASS_EXPIRY_LIST: `${BASE_URL}Common/List/SP_RPT_GatePassExpired_D2D`,
      GET_MANPOWER_REQ_STATUS_D2D_LIST: `${BASE_URL}Common/List/SP_RPT_ManpowerRequisitionStatus_D2D`,
      CLPMS_SP_ATT_ConciliatedDownloadPunchReport_D2D: `${BASE_URL}Common/MultiTableList/CLPMS_SP_ATT_ConciliatedDownloadPunchReport_D2D`,
      CLPMS_SP_FinancialYear: `${BASE_URL}Common/List/CLPMS_SP_FinancialYear`,
      CLPMS_SP_PayrollMonth_By_Year: `${BASE_URL}Common/List/CLPMS_SP_PayrollMonth_By_Year`,
      CLPMS_SP_ATT_FreezAttendance_M2M: `${BASE_URL}Common/MultiTableList/CLPMS_SP_ATT_FreezAttendance_M2M`,
      HRMS_SP_ATT_Freeze_N_UnFreezDailyAttendance: `${BASE_URL}Common/List/HRMS_SP_ATT_Freeze_N_UnFreezDailyAttendance`,
      GET_WAGE_REPORT_DATA: `${BASE_URL}Common/List/CLPMS_SP_RPT_WageRegister`,
      GET_WORKMEN_PAYROLL_MM: `${BASE_URL}Common/MultiTableList/CLPMS_SP_RPT_WorkmenPayroll_MM`,
      GET_WORKMEN_PAYROLL_MM_HEADER: `assets/data/dynamic-columns.json`,
      CLPMS_SP_ReportHeaderDetails: `${BASE_URL}Common/List/CLPMS_SP_ReportHeaderDetails`,
      GET_COST_CENTER_CHARTDATA: `${BASE_URL}Common/MultiTableList/RPT_LocationWiseCostCenterExpense`,
    },
    VISITOR_MANAGEMENT: {
      GET_EMPLOYEE_DEATIL: `${BASE_URL}Common/List/Get_EmployeeMaster_List`,
      SEND_REQUEST: `${BASE_URL}Visitor/Submitrequest`,
      GET_VISITOR_DATA: `${BASE_URL}Visitor/GetVisitorList`,
      APPROVEDREJECTREQUEST: `${BASE_URL}Visitor/ApprovedRejectRequest`,
      UPLOAD_DOC: `${BASE_URL}Visitor/AddDoc`,
      GET_VISITORTIMELINE_DASHBOARD: `${BASE_URL}Common/List/TimeLineVisitorDashboard`,
      GET_VISITORPREAUTHORIZED_DASHBOARD: `${BASE_URL}Common/List/PreAuthorizedVisitorDashboard`,
      GET_VISITORACTIVITY_DASHBOARD: `${BASE_URL}Common/List/VisitorActivityDashboard`,
      UPLOAD_VISITOR_PHOTO: `${BASE_URL}Visitor/AddVisitorPhoto`,
      GET_DOC_DATA: `${BASE_URL}Visitor/GetDocumentForLabourVerify`,
      GET_VISITORTOTALCOUNT_DASHBOARD: `${BASE_URL}Common/List/TotalCountOfVisitorsForDashboard`,
      GET_ALL_VISITOR_DATASET: `${BASE_URL}Common/List/VisitorDashboardChartDataset`,
      INSERT_OUTTIME_DASHBOARD: `${BASE_URL}Common/InsertUpdateDelete/UpdateOutTimeOfVisitor`,
    },
    FACE_RECOGNITION_API: {
      GET_ALL_TRAINED_DATA: `${BASE_URL}Common/List/SP_Listing_getAllEmployeeTrainData`,
      ADD_FACEIDENTITY_DETAIL: `${BASE_URL}Attendance/InsertFaceIdentityData`,
      ADD_UPDATE_FACEIDENTITY_DETAIL: `${BASE_URL}Common/List/Insert_Update_FaceIdentity_Detail`,
    },
    YEARLY_BUDGET: {
      SAVE_DATA: `${BASE_URL}YearlyBudget/SaveBudgetData`,
    },
    PAYROLL_MANAGEMENT: {
      GET_LIST_DELETE_PAYROLL_COMPONENT: `${BASE_URL}Common/List/Sp_List_Delete_PayrollComponent`,
      GET_LIST_DELETE_PAYROLL_COMPONENT_MASTER: `${BASE_URL}Common/List/Sp_List_Delete_PayrollComponentMaster`,
      SAVE_PAYROLL_COMPONENT: `${BASE_URL}Common/List/Sp_Insert_Update_PayrollComponent`,
      GET_LIST_PAYROLL_COMPONENTS: `${BASE_URL}Common/List/SP_List_PayrollComponent`,
      CLPMS_SP_CategoryMontylyWokingDays_MM: `${BASE_URL}Common/List/CLPMS_SP_CategoryMontylyWokingDays_MM`,
      CLPMS_SP_CategoryMontylyWokingDays_UPDATE: `${BASE_URL}Common/List/CLPMS_SP_CategoryMontylyWokingDays_UPDATE`,
      Common_SP_ComponentGroupCreate: `${BASE_URL}Common/List/Common_SP_ComponentGroupCreate`,
      SP_List_PayrollComponent: `${BASE_URL}Common/List/SP_List_PayrollComponent`,
      Common_SP_InsertUpdateGroupComponet: `${BASE_URL}Common/BulkInsertUpdateDelete/Common_SP_InsertUpdateGroupComponet`,
      CheckDuplicateComponentGroup: `${BASE_URL}Common/List/CLPMS_SP_CheckDuplicateComponentGroup`,
      GET_ComponentGroupDetails: `${BASE_URL}Common/MultiTableList/ComponentGroupDetails`,
    },
    COMMON: {
      COMMON_SP_UserPlantAccess: `${BASE_URL}Common/List/COMMON_SP_UserPlantAccess`,
      CLPMS_LINK_SAMPLE_EXEL: `${BASE_URL}Common/List/CLPMS_LINK_Sample_Excel`,
      Common_SP_InsertDelete_Doc: `${BASE_URL}Common/List/Common_SP_InsertDelete_Doc`,
      CheckPrviousLevelApproval4HR: `${BASE_URL}Common/List/CheckPrviousLevelApproval4HR`,
      GatePassRequest_History: `${BASE_URL}Common/List/GatePassRequest_History`,
      CLPMS_SP_ReportHeaderDetails_V1: `${BASE_URL}Common/MultiTableList/CLPMS_SP_ReportHeaderDetails_V1`,
      User_Data_Access_Details: `${BASE_URL}Common/List/UserDataAccessDetails`,
      GET_ALL_TRADE: `${BASE_URL}Common/List/SP_COM_GetAllTradeMaster_V1`,
    },
  };

  public static ALLOWED_FILE_SIZE = 5; // MB
  public static ALLOWED_FILE_TYPES = ".jpg,.png,.pdf,.docx.pptx";
  public static SHIFT_LIST = [
    {
      Shift_Code: -2,
      Name: "Absent",
    },
    {
      Shift_Code: -1,
      Name: "WOFF",
    },
    {
      Shift_Code: 2,
      Name: "A1 LB",
    },
    {
      Shift_Code: 3,
      Name: "A2 LB",
    },
    {
      Shift_Code: 4,
      Name: "GD2 LB",
    },
    {
      Shift_Code: 5,
      Name: "B1 LB",
    },
    {
      Shift_Code: 6,
      Name: "B2 LB",
    },
    {
      Shift_Code: 7,
      Name: "B3 LB",
    },
    {
      Shift_Code: 8,
      Name: "C1 LB",
    },
    {
      Shift_Code: 9,
      Name: "C2 LB",
    },
  ];
  public static STATUS_LIST = [
    {
      ID: 104,
      Status: "A",
    },
    {
      ID: 137,
      Status: "WO",
    },
    {
      ID: 100,
      Status: "P",
    },
    {
      ID: 151,
      Status: "OD",
    },
    {
      ID: 128,
      Status: "HP",
    },
    {
      ID: 138,
      Status: "WP",
    },
    {
      ID: 102,
      Status: "½P",
    },
    {
      ID: 103,
      Status: "P½",
    },
    {
      ID: 114,
      Status: "NA",
    },
    {
      ID: 115,
      Status: "½PNA",
    },
    {
      ID: 116,
      Status: "NAP½",
    },
    {
      ID: 125,
      Status: "RH½OS",
    },
    {
      ID: 126,
      Status: "RHOS½",
    },
    {
      ID: 127,
      Status: "H",
    },
    {
      ID: 128,
      Status: "HP",
    },
    {
      ID: 129,
      Status: "H½P",
    },
    {
      ID: 130,
      Status: "HP½",
    },
    {
      ID: 131,
      Status: "HOD",
    },
    {
      ID: 132,
      Status: "H½OD",
    },
    {
      ID: 133,
      Status: "HOD½",
    },
    {
      ID: 134,
      Status: "HOS",
    },
    {
      ID: 135,
      Status: "H½OS",
    },
    {
      ID: 139,
      Status: "W½P",
    },
    {
      ID: 140,
      Status: "WP½",
    },
    {
      ID: 141,
      Status: "W½POD",
    },
    {
      ID: 142,
      Status: "WODP½",
    },
    {
      ID: 143,
      Status: "WOD",
    },
    {
      ID: 144,
      Status: "W½OD",
    },
    {
      ID: 145,
      Status: "WOD½",
    },
    {
      ID: 146,
      Status: "W½POS",
    },
    {
      ID: 147,
      Status: "WOSP½",
    },
    {
      ID: 148,
      Status: "WOS",
    },
  ];

  public static SHIFTCHANGE_LIST = [
    {
      Shift_Code: 111,
      Name: "AA",
    },
    {
      Shift_Code: 112,
      Name: "BB",
    },
    {
      Shift_Code: 113,
      Name: "CC",
    },
    {
      Shift_Code: 114,
      Name: "GN",
    },
  ];

  public static NO_OF_REQUIRED_CP = 1;
  public static SHOW_IN_OUT = true;
  public static TYPE_OF_PUNCHES = [
    {
      Code: 1,
      Name: "FirstIn",
    },
    {
      Code: 2,
      Name: "FirstOut",
    },
    {
      Code: 3,
      Name: "SecondIn",
    },
    {
      Code: 4,
      Name: "SecondOut",
    },
  ];
  public static ROLES = [
    // { Id: 101, Name: 'HR' },
    { Id: 118, Name: "HR" },
    { Id: 102, Name: "OHC" },
    { Id: 103, Name: "EHS" },
    { Id: 104, Name: "Security" },
    { Id: 106, Name: "Section Head" },
  ];

  public static MASTER_ITEM_CODE = {
    MANPOWER_TYPE: "GM855",
    WORKMAN_CATEGORY: "GM858",
    WAGE_CRITERIA: "GM858",
  };

  public static SESSION_TIME_OUT = 1000 * 60 * 20;

  // public static REPORT_BUILDER_APIS: ReportBuilderAPI[] = [
  //   {
  //     description: 'Test',
  //     params: [
  //       {
  //         name: 'UserId',
  //         id: 'UserId',
  //         value: '653',
  //         type: 'lookup',
  //         Url: '/api/User/searchUser?searchKey=amit'
  //       },
  //       {
  //         name: 'drpfilterwork',
  //         id: 'drpfilterwork',
  //         value: '1',
  //         type: 'text'
  //       }
  //     ],
  //     url: 'assets/data/pivot-test.json'
  //   },
  //   {
  //     description: 'Contractors',
  //     primaryKey: 'ContratorId',
  //     params: [
  //       {
  //         name: 'UserId',
  //         id: 'UserId',
  //         value: '653',
  //         type: 'select',
  //         list: [{
  //           id: 1,
  //           name: 'Test 1'
  //         },
  //         {
  //           id: 1,
  //           name: 'Test 2'
  //         }]
  //       },
  //       {
  //         name: 'drpfilterwork',
  //         id: 'drpfilterwork',
  //         value: '1',
  //         type: 'text'
  //       }
  //     ],
  //     url: GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTRACTORS,
  //     responseSchema: ['ContractorId', 'ContractorName', 'DOB', 'AadharCardNo']
  //   }
  // ];
}

for (const key in browserWindowEnv) {
  if (browserWindowEnv.hasOwnProperty(key)) {
    GLOBAL[key] = window["__env"][key];
  }
}

export enum ROLECODE {
  Contractor = 119,
  HR = 118,
  OHC = 109,
  EHS = 110,
  Security = 111,
}
export const PLANT_UNIT_DEP_REQUEST_FIELDS = [
  {
    KeyName: "Plantcode0",
    DisplayName: "Plant0",
  },
  {
    KeyName: "Unitcode0",
    DisplayName: "Unit0",
  },
  {
    KeyName: "UnitDeptID0",
    DisplayName: "Department0",
  },
  {
    KeyName: "Sectioncode0",
    DisplayName: "SectionName0",
  },
  {
    KeyName: "Sectioncode1",
    DisplayName: "SectionName1",
  },
  {
    KeyName: "Sectioncode2",
    DisplayName: "SectionName2",
  },
];

export const WEEKOFF_DATA = {
  Filter: {
    FromDate: [null],
    toDate: [null],
    FinYear: [null],
    Monthcode: [null],
    company_Code: [null],
    plant_Code: [null],
    unit_Code: [null],
    department_Code: [null],
    section_Code: [null],
    contractors: [null],
    workOrder_Code: [null],
    license_Code: [null],
    WCPolicy: [null],
    ESIC: [null],
    subContractors: [null],
    subWorkOrder_Code: [null],
    subLicense_Code: [null],
    subWCPolicy: [null],
    subESIC: [null],
    Gender: [null],
    skill_Code: [null],
    trade_Code: [null],
    Qulification_Code: [null],
    workMenCategory_Code: [null],
    SAge: [null],
    EAge: [null],
    Wage: [null],
  },
  Fields: [
    {
      KeyName: "Contractor_Code",
      DisplayName: "Company",
    },
    {
      KeyName: "Labour_Code",
      DisplayName: "FullName",
    },
  ],
};

export const MANPOWER_REQ_ADDREMOVE_INPUT = {
  Filter: {
    UserId: 118,
    Date: "2021-05-01",
  },
  Fields: [
    {
      KeyName: "Plant_Code",
      DisplayName: "PlantName",
    },
    {
      KeyName: "Unit_Code",
      DisplayName: "UnitName",
    },
    {
      KeyName: "UnitDeptID",
      DisplayName: "DepartmentName",
    },
    {
      KeyName: "SectionRelId",
      DisplayName: "Section3",
    },
    {
      KeyName: "CategoryWorkmenMIL_Code",
      DisplayName: "Category",
    },
    {
      KeyName: "Shift_Code",
      DisplayName: "ShiftName",
    },
    {
      KeyName: "Date",
      DisplayName: "",
      ExtraFields: ["TotalRequirement"],
    },
    {
      KeyName: "RequestedBy_Code",
      DisplayName: "RequestedBy",
      ExtraFields: ["Requirement"],
    },
    {
      KeyName: "Contractor_Code",
      DisplayName: "ContractorName",
      ExtraFields: ["Allot"],
    },
  ],
};
// export const PLANT_UNIT_DEP_REQUEST = {
//   Filter: {
//     UserId: localStorage.getItem('UserID')
//   },
//   Fields: [
//     {
//       KeyName: 'Plantcode0',
//       DisplayName: 'Plant0'
//     },
//     {
//       KeyName: 'Unitcode0',
//       DisplayName: 'Unit0'
//     },
//     {
//       KeyName: 'UnitDeptID0',
//       DisplayName: 'Department0',
//       ExtraFields: [
//         'Departmentcode0'
//       ]
//     },
//     {
//       KeyName: 'LevelID0',
//       DisplayName: 'SectionName0',
//       ExtraFields: [
//         'Sectioncode0'
//       ]
//     },
//     {
//       KeyName: 'LevelID1',
//       DisplayName: 'SectionName1',
//       ExtraFields: [
//         'Sectioncode1'
//       ]
//     },
//     {
//       KeyName: 'LevelID2',
//       DisplayName: 'SectionName2',
//       ExtraFields: [
//         'Sectioncode2'
//       ]
//     }
//   ]
// };

export const PLANT_UNIT_DEP_REQUEST = {
  Filter: {
    UserId: localStorage.getItem("UserID"),
  },
  Fields: [
    {
      KeyName: "Plantcode0",
      DisplayName: "Plant0",
    },
    {
      KeyName: "Unitcode0",
      DisplayName: "Unit0",
    },
    {
      KeyName: "UnitDeptID0",
      DisplayName: "Department0",
      ExtraFields: ["Departmentcode0"],
    },
    {
      KeyName: "LevelID0",
      DisplayName: "SectionName0",
      ExtraFields: ["Sectioncode0"],
    },
    {
      KeyName: "LevelID1",
      DisplayName: "SectionName1",
      ExtraFields: ["Sectioncode1"],
    },
    {
      KeyName: "LevelID2",
      DisplayName: "SectionName2",
      ExtraFields: ["Sectioncode2", "Short_Code"],
    },
  ],
};
// PLANT_UNIT_DEP_REQUEST['Fields'] = PLANT_UNIT_DEP_REQUEST_FIELD
