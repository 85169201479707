import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { AttendanceComponent } from './attendance.component';
import { AttendanceService } from './attendance.service';
import { CoreModule } from '../core/core.module';
import { AttendanceRoutingModule } from './attendance-routing.module';
import {
  ExpandRowComponent,
  AgPunchInCellRendererComponent,
  AgDateCellRendererComponent,
  AgDropDownCellRendererComponent,
} from './ag-helper';
import { WeekoffChangeComponent } from './weekoff-change/weekoff-change.component';
import { WeekoffFilterComponent } from './weekoff-change/weekoff-filter/weekoff-filter.component';
import { AgSaveCellRendererComponent } from './ag-helper/ag-save-cell-renderer/ag-save-cell-renderer.component';
import { LabourWeekoffChangesComponent } from './labour-weekoff-changes/labour-weekoff-changes.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BulkWeekoffChangeComponent } from './labour-weekoff-changes/bulk-weekoff-change/bulk-weekoff-change.component';
import { AttendanceApproveComponent } from './attendance-approve/attendance-approve.component';
import {
  AgApproveStatusCellRendererComponent
} from './ag-helper/ag-approve-status-cell-renderer/ag-approve-status-cell-renderer.component';
import { AgDecimalEditCellRendererComponent }
  from './ag-helper/ag-decimal-edit-cell-renderer/ag-decimal-edit-cell-renderer.component';
import { AttendanceApprovePrsenter }
  from './attendance-approve/attendance-approve-presenter/attendance-approve.presenter';
import { AttendanceApproveAdapter } from './attendance-approve/adapter/attendance-approve.adapter';
import { AttendanceApproveFilterComponent }
  from './attendance-approve/attendance-approve-filter/attendance-approve-filter.component';
import {
  AttendanceViewListGroupRowComponent
} from './attendance-approve/attendance-view-list-group-row/attendance-view-list-group-row.component';
// import { ReportViewListGroupRowComponent }
//   from '../reports/daily-attendance/report-view-list-group-row/report-view-list-group-row.component';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { ReportViewListGroupRowComponent } from './report-view-list-group-row/report-view-list-group-row.component';
import { ApprovedAttendanceComponent } from './approved-attendance/approved-attendance.component';
import { AttendanceManualPunchComponent } from './attendance-manual-punch/attendance-manual-punch.component';
// tslint:disable-next-line: max-line-length
import { AgSelectEditCellRendererComponent } from './attendance-manual-punch/ag-select-edit-cell-renderer/ag-select-edit-cell-renderer.component';
// tslint:disable-next-line: max-line-length
import { AgDatePickerEditCellRendererComponent } from './attendance-manual-punch/ag-datepicker-edit-cell-renderer/ag-datepicker-edit-cell-renderer.component';
// tslint:disable-next-line: max-line-length
import { AgIconActionCellRendererComponent } from './attendance-manual-punch/ag-icon-action-cell-renderer/ag-icon-action-cell-renderer.component';
// tslint:disable-next-line: max-line-length
import { AgTimePickerEditCellRendererComponent } from './attendance-manual-punch/ag-timepicker-edit-cell-renderer/ag-timepicker-edit-cell-renderer.component';
import { AttendanceManualPunchService } from './attendance-manual-punch/attendance-manual-punch.service';
import { ManualPunchComponent } from './attendance-manual-punch/manual-punch/manual-punch.component';
import { AgInputEditCellRendererComponent } from './ag-helper/ag-input-edit-cell-renderer/ag-input-edit-cell-renderer.component';
import { AttendanceApproveBysecComponent } from './attendance-approve-bysec/attendance-approve-bysec.component';
import { AttendanceManualPunchChangeComponent } from './attendance-manual-punch-change/attendance-manual-punch-change.component';
// tslint:disable-next-line:max-line-length
import { AttendanceManualPunchChangeFilterComponent } from './attendance-manual-punch-change/attendance-manual-punch-change-filter/attendance-manual-punch-change-filter.component';
// tslint:disable-next-line:max-line-length
import { LabourPunchEditingComponent } from './labour-punch-editing/labour-punch-editing.component';
// tslint:disable-next-line:max-line-length
import { RptViewListPreviousdayrecordsComponent } from './attendance-manual-punch-change/rpt-view-list-privious-day-records/rpt-view-list-privious-day-records.component';
// tslint:disable-next-line:max-line-length
import { COFFComponent } from './coff/coff.component';
// tslint:disable-next-line:max-line-length
import { AgActionCellDeleteRecordRendererComponent } from '../core/components/grid-wrapper/helper/ag-action-cell-delete-record-renderer/ag-action-cell-delete-record-renderer.component';
// tslint:disable-next-line:max-line-length
import { AgActionCellPreviousDayRendererComponent } from '../core/components/grid-wrapper/helper/ag-action-cell-previousday-renderer/ag-action-cell-previousday-renderer.component';
// tslint:disable-next-line:max-line-length
import { SingleLabourWeekoffChangesComponent } from './sinlge-labour-weekoff-change/sinlge-labour-weekoff-change.component';
// tslint:disable-next-line:max-line-length
import { SingleWeekoffChangeComponent } from './sinlge-labour-weekoff-change/single-weekoff-change/single-weekoff-change.component';
import { AttendanceManualComponent } from './attendance-manual/attendance-manual.component';
import { AttendanceManualSubmitComponent } from './attendance-manual/attendance-manual-submit/attendance-manual-submit.component';
// tslint:disable-next-line:max-line-length
import { AttendanceSinglePunchCorrectionComponent } from './attendance-manual/attendance-single-punch-correction/attendance-single-punch-correction.component';
// tslint:disable-next-line:max-line-length
import { AttendanceReprocessComponent } from './attendance-reprocess/attendance-reprocess.component';
// tslint:disable-next-line:max-line-length
import { LabourAttendanceReprocessComponent } from './attendance-reprocess/labour-attendance-reprocess/labour-attendance-reprocess.component';
import { ExceptionalAttendanceComponent } from './exceptional-attendance/exceptional-attendance.component';
import { WeekOffCheckboxRenderComponent } from './labour-weekoff-changes/week-off-checkbox-render/week-off-checkbox-render.component';
// tslint:disable-next-line: max-line-length
import { ReportViewListExceptionalAttendanceComponent } from './exceptional-attendance/report-view-list-exceptional-attendance/report-view-list-exceptional-attendance.component';
// tslint:disable-next-line:max-line-length
import { SynchronizeUtilityComponent } from './synchronize-utility/synchronize-utility.component';
import { AttendanceSycprocessComponent } from './synchronize-utility/attendance-sycprocess/attendance-sycprocess.component';
// tslint:disable-next-line:max-line-length
import { LabourAttendanceSycreprocessComponent } from './synchronize-utility/attendance-sycprocess/labour-attendance-sycreprocess/labour-attendance-sycreprocess.component';
// tslint:disable-next-line:max-line-length
import { PunchtransferProcessComponent } from './synchronize-utility/punchtransfer-process/punchtransfer-process.component';
import { BulkPunchtransferComponent } from './synchronize-utility/bulk-punchtransfer/bulk-punchtransfer.component';
// tslint:disable-next-line:max-line-length
import { BulkPunchtransferclpmsComponent } from './synchronize-utility/bulk-punchtransfer/bulk-punchtransferclpms/bulk-punchtransferclpms.component';
// tslint:disable-next-line:max-line-length
import { AgExceptionalDropDownCellRenderComponent } from './exceptional-attendance/ag-exceptional-drop-down-cell-render/ag-exceptional-drop-down-cell-render.component';
import { FormsModule } from '@angular/forms';
// tslint:disable-next-line:max-line-length
import { AttendanceManualPunchManagementComponent } from './attendance-manual-punch-management/attendance-manual-punch-management.component';
// tslint:disable-next-line:max-line-length
import { AttendanceSinglePunchManagementComponent } from './attendance-manual-punch-management/attendance-single-punch-management/attendance-single-punch-management.component';
import { ReportViewListCoffComponent } from './coff/report-view-list-coff/report-view-list-coff.component';
import { AttendanceRegularizationComponent } from './attendance-regularization/attendance-regularization.component';
import { AttendanceRegularizationViewListComponent } from './attendance-regularization/attendance-regularization-view-list/attendance-regularization-view-list.component';
import { AttendanceRegularizationCellRendererComponent } from './attendance-regularization/attendance-regularization-cell-renderer/attendance-regularization-cell-renderer.component';
import { UserOTApprovalComponent } from './user-ot-approval/user-ot-approval.component';
import { UserOTApprovalListModalComponent } from './user-ot-approval/approval-list-modal/approval-list-modal.component';
import { SectionHeadOTApprovalListModalComponent } from './user-ot-approval/section-head-approval-list-modal/section-head-approval-list-modal.component';
// tslint:disable-next-line:max-line-length

@NgModule({
  imports: [
    CommonModule,
    CoreModule.forRoot(),
    SweetAlert2Module.forRoot(),
    AttendanceRoutingModule,
    NgbModule
  ],
  entryComponents: [
    AgPunchInCellRendererComponent,
    ExpandRowComponent,
    AgDateCellRendererComponent,
    AgDropDownCellRendererComponent,
    AgSaveCellRendererComponent,
    BulkWeekoffChangeComponent,
    AgApproveStatusCellRendererComponent,
    AgDecimalEditCellRendererComponent,
    AgInputEditCellRendererComponent,
    AttendanceViewListGroupRowComponent,
    ReportViewListGroupRowComponent,
    ApprovedAttendanceComponent,
    AttendanceManualPunchComponent,
    AgSelectEditCellRendererComponent,
    AgDatePickerEditCellRendererComponent,
    AgIconActionCellRendererComponent,
    AgTimePickerEditCellRendererComponent,
    ManualPunchComponent,
    AttendanceApproveBysecComponent,
    AttendanceManualPunchChangeComponent,
    AttendanceManualPunchChangeFilterComponent,
    AgActionCellPreviousDayRendererComponent,
    RptViewListPreviousdayrecordsComponent,
    AgActionCellDeleteRecordRendererComponent,
    COFFComponent,
    SingleLabourWeekoffChangesComponent,
    SingleWeekoffChangeComponent,
    COFFComponent, AttendanceManualSubmitComponent,
    AttendanceSinglePunchCorrectionComponent,
    AttendanceReprocessComponent,
    LabourAttendanceReprocessComponent,
    WeekOffCheckboxRenderComponent,
    ReportViewListExceptionalAttendanceComponent,
    ReportViewListCoffComponent,
    AttendanceSycprocessComponent,
    SynchronizeUtilityComponent,
    PunchtransferProcessComponent,
    BulkPunchtransferComponent,
    BulkPunchtransferclpmsComponent,
    LabourAttendanceSycreprocessComponent,
    AgExceptionalDropDownCellRenderComponent,
    AttendanceManualPunchManagementComponent,
    AttendanceSinglePunchManagementComponent,
    AttendanceRegularizationViewListComponent,
    AttendanceRegularizationCellRendererComponent,
    UserOTApprovalListModalComponent,
    SectionHeadOTApprovalListModalComponent
  ],
  declarations: [
    AttendanceComponent,
    ExpandRowComponent,
    AgPunchInCellRendererComponent,
    AgDateCellRendererComponent,
    WeekoffChangeComponent,
    LabourWeekoffChangesComponent,
    BulkWeekoffChangeComponent,
    WeekoffFilterComponent,
    AgDropDownCellRendererComponent,
    AgSaveCellRendererComponent,
    AttendanceApproveComponent,
    AgApproveStatusCellRendererComponent,
    AgDecimalEditCellRendererComponent,
    AgInputEditCellRendererComponent,
    AttendanceApproveFilterComponent,
    AttendanceViewListGroupRowComponent,
    ReportViewListGroupRowComponent,
    ApprovedAttendanceComponent,
    AttendanceManualPunchComponent,
    AgSelectEditCellRendererComponent,
    AgDatePickerEditCellRendererComponent,
    AgIconActionCellRendererComponent,
    AgTimePickerEditCellRendererComponent,
    ManualPunchComponent,
    AttendanceApproveBysecComponent,
    AttendanceManualPunchChangeComponent,
    AttendanceManualPunchChangeFilterComponent,
    LabourPunchEditingComponent,
    AgActionCellPreviousDayRendererComponent,
    RptViewListPreviousdayrecordsComponent,
    AgActionCellDeleteRecordRendererComponent,
    COFFComponent,
    SingleLabourWeekoffChangesComponent,
    SingleWeekoffChangeComponent,
    AttendanceManualComponent,
    AttendanceManualSubmitComponent,
    AttendanceSinglePunchCorrectionComponent,
    AttendanceReprocessComponent,
    LabourAttendanceReprocessComponent,
    ExceptionalAttendanceComponent,
    WeekOffCheckboxRenderComponent,
    ReportViewListExceptionalAttendanceComponent,
    SynchronizeUtilityComponent,
    AttendanceSycprocessComponent,
    LabourAttendanceSycreprocessComponent,
    PunchtransferProcessComponent,
    BulkPunchtransferComponent,
    BulkPunchtransferclpmsComponent,
    AgExceptionalDropDownCellRenderComponent,
    AttendanceManualPunchManagementComponent,
    AttendanceSinglePunchManagementComponent,
    ReportViewListCoffComponent,
    AttendanceRegularizationComponent,
    AttendanceRegularizationViewListComponent,
    AttendanceRegularizationCellRendererComponent,
    UserOTApprovalComponent,
    UserOTApprovalListModalComponent,
    SectionHeadOTApprovalListModalComponent
  ],
  providers: [
    AttendanceService,
    AttendanceApprovePrsenter,
    AttendanceApproveAdapter,
    DecimalPipe,
  ],
  exports: [
    AttendanceComponent, ReportViewListGroupRowComponent
  ]
})
export class AttendanceModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AttendanceModule,
      providers: [
        AttendanceService,
        AttendanceManualPunchService,
      ]
    };
  }
}
